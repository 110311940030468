import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const [auth, setAuth] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const auth = localStorage.getItem("JWT_TOKEN");
    setAuth(auth);
  }, [localStorage.getItem("JWT_TOKEN")]);
  return (
    <>
      <footer className="site_footer">
        <div className="footer_top">
          <div className="container">
            <div className="row justify-content-lg-between mt-1 mb-2">
              <div className="col-12 col-lg-4 col-md-6 col-sm-6 mt-2">
                <div className="site_logo">
                  <NavLink to={auth ? "/home" : "/"}>
                    <img src="/assets/img/learnovationlogo.png" alt="" />
                  </NavLink>
                </div>
                <p>
                  Learnovation is a team of full time Derivative and
                  Discretionary, Veteran and Young Traders, specializing in
                  Index Momentum, Point and Figure, and Indicators based trading
                  and having an expertise in directional and non-directional
                  option strategies.
                </p>
              </div>

              <div className="col-12 col-lg-2 col-md-6 col-sm-6 mt-3">
                <div className="widget">
                  <h3 className="widget_title">Useful Links</h3>
                  <ul className="page_list unordered_list_block">
                    <li>
                      <i class="fa-solid fa-arrow-right text-light"></i>
                      <NavLink to="/courses" className="ml-2">Courses</NavLink>
                    </li>
                    <li>
                      <i class="fa-solid fa-arrow-right text-light"></i>
                      <NavLink to="/services" className="ml-2">Services</NavLink>
                    </li>
                    <li>
                      <i class="fa-solid fa-arrow-right text-light"></i>
                      <NavLink to="/blog" className="ml-2">Blog</NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 col-lg-2 col-md-6 col-sm-6 mt-3">
                <div className="widget">
                  <h3 className="widget_title footer_margin">About </h3>

                  <ul className="page_list unordered_list_block">
                    <li>
                      <i class="fa-solid fa-arrow-right text-light"></i>
                      <NavLink to="/about-us" className="ml-2">About Us</NavLink>
                    </li>
                    <li>
                      <i class="fa-solid fa-arrow-right text-light"></i>
                      <NavLink to="/contact-us" className="ml-2">Contact Us</NavLink>
                    </li>
                    <li>
                      <i class="fa-solid fa-arrow-right text-light"></i>
                      <NavLink to="/gallery" className="ml-2">Gallery</NavLink>
                    </li>
                    <li>
                      <i class="fa-solid fa-arrow-right text-light"></i>
                      <NavLink to="/privacy-policy" className="ml-2">Privacy & Policy</NavLink>
                    </li>
                    {/* <li>
                    <i class="fa-solid fa-arrow-right text-light"></i>
                      <NavLink to="/sitemap" className="ml-2">Sitemap</NavLink>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-12 col-lg-2 col-md-6 col-sm-6 mt-3">
                <div className="widget">
                  <h3 className="widget_title footer_margin">Social</h3>
                  <ul className="page_list unordered_list_block">
                    <li>
                      <NavLink to="https://twitter.com/home" target="_blank">
                        <i
                          className="fa-brands fa-x-twitter"
                        // style="color: #ffffff;"
                        ></i>
                        <span className="ml-3">Twitter</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="https://www.instagram.com/learnovationindia/" target="_blank">
                        <i className="fab fa-instagram"></i>
                        <span className="ml-3">Instagram</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="https://www.facebook.com/profile.php?id=100090038215009" target="_blank">

                        <i className="fab fa-facebook-f"></i>
                        <span className="ml-3">Facebook</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="https://www.linkedin.com/in/learnovation-india-b93304270/" target="_blank">
                        <i className="fab fa-linkedin-in"></i>
                        <span className="ml-3">Linkdin</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="https://youtube.com/@LearnovationIndia" target="_blank">
                        <i class="fa-brands fa-youtube"></i>
                        <span className="ml-3">Youtube</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="https://www.threads.net/@learnovationindia" target="_blank">
                        <i class="fa-brands fa-threads"></i>
                        <span className="ml-3">Threads</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row mt-1 mb-2">
              <div className="col-12 col-lg-12 col-md-6 col-sm-12 mt-2 text-center">
                <p className="copyright_text mb-0">
                  © Copyrights 2023
                  &nbsp;<NavLink to={auth ? "/home" : "/"}>Learnovation</NavLink>
                  &nbsp;
                  All
                  rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
