import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Button,
} from "react-bootstrap";
import EnquiryForm from "../Coursedetail/EnquiryForm";

const Slider = () => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  // const [secondForm, setSecondForm] = useState(true);
  const [mobileview, setMobileView] = useState(false);
  const [refernceFrom, setRefernceFrom] = useState("");
  const handleChange = (e) => {
    setRefernceFrom(e.target.value);
  };

  const carouselRef1 = useRef(null);
  const carouselRef2 = useRef(null);

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     const items = document.getElementsByClassName("p-2");
  //     let itemLength = items.length;
  //     let initialitem = 0;
  //     let middleitem = itemLength / 2;
  //       items[initialitem].classList.add("imageAnimation");
  //       items[middleitem].classList.add("imageAnimation");
  //       // Saving references to the nodes
  //       let timeout = setTimeout(() => {
  //         let item1 = items[initialitem];
  //         let item2 = items[middleitem];
  //         // Removing the nodes
  //           // item1.remove();
  //           // item2.remove();
  //         // Appending the nodes at the end
  //         item1.classList.remove("imageAnimation");
  //         item2.classList.remove("imageAnimation");
  //         items[middleitem - 2].parentNode.appendChild(item1);
  //         items[itemLength - 2].parentNode.appendChild(item2);
  //       }, 2000);
  //       return () => clearInterval(timeout);
  //   }, 5000);
  //   // return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    const carousel1 = carouselRef1.current;
    const carousel2 = carouselRef2.current;


    const slides1 = Array.from(carousel1.children);
    const slides2 = Array.from(carousel2.children);
    slides1.forEach(slide => carousel1.appendChild(slide.cloneNode(true)));
    slides2.forEach(slide => carousel2.appendChild(slide.cloneNode(true)));


    const scroll = (carousel) => {
      // Calculate the amount to scroll based on the width of each slide
      const slideWidth = carousel.querySelector(".slide").offsetWidth;
      carousel.scrollLeft += 1;

      // Check if the carousel has reached the end of the clones, then reset the scroll
      if (carousel.scrollLeft >= carousel.scrollWidth - carousel.clientWidth) {
        carousel.scrollLeft = 0;
      }
    };

    const autoScroll1 = setInterval(() => scroll(carousel1), 20);
    const autoScroll2 = setInterval(() => scroll(carousel2), 20);

    // Return an empty function to clear the intervals on unmount
    return () => {
      clearInterval(autoScroll1);
      clearInterval(autoScroll2);
    };
  }, [carouselRef1,carouselRef2,mobileview]);

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setMobileView(true);
      setShow(false);
    }
  }, []);

  useEffect(()=>{
    console.log("show",show)
    console.log("show",refernceFrom)
    console.log("show",refernceFrom === "friends")
  },[show,refernceFrom])

  return (
    <>
      <section className="banner_section banner_style_1 decoration_wrap desktopview">
        <div className="container">
          <div className="row">
            {/* <div className="order-lg-last col col-lg-5 col-md-12">
                <div class="childscroll">
                  </div>
              <div class="parentscroll">
                <div className="p-2">
                  <img
                    src="../assets/img/home1.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home8.png"
                    alt="Our Mission"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home9.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home7.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home6.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home10.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home11.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home12.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home3.png"
                    alt="Our Mission"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home5.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home10.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home11.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home12.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home3.png"
                    alt="Our Mission"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home5.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home10.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home11.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home12.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home3.png"
                    alt="Our Mission"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home5.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
              </div>
              <div className="d-flex flex-row moving-image">
                <div className="p-2">
                  <img
                    src="../assets/img/home10.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home11.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home12.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home3.png"
                    alt="Our Mission"
                    className="borderRadius"
                  />
                </div>
                <div className="p-2">
                  <img
                    src="../assets/img/home5.png"
                    alt="Our History"
                    className="borderRadius"
                  />
                </div>
              </div>
            </div> */}

            {!mobileview && <><div className="carousel-container">
              <div className="carousel" ref={carouselRef1}>
                <img
                  src="../assets/img/home4.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home10.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home12.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home2.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home6.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home8.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home5.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home11.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home7.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home3.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home14.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home9.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home10.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home11.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home8.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home12.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home14.png"
                  alt="Image 2"
                  className="slide"
                />
                {/* More images */}
              </div>
              <div className="carousel" ref={carouselRef2}>
                <img
                  src="../assets/img/home14.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home15.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home16.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home17.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home13.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home19.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home18.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home20.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home15.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home16.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home13.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home17.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home19.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home18.png"
                  alt="Image 2"
                  className="slide"
                />
                <img
                  src="../assets/img/home20.png"
                  alt="Image 2"
                  className="slide"
                />
                {/* More images */}
              </div>
            </div></>}

            <div className="col col-lg-7 col-md-12">
              <div className="banner_content">
                <section className="membership_section pt-0">
                  <div className="container heading_text mb-0">
                    <div className="row justify-content-center">
                      <div className="col col-lg-12">
                        <form action="#" className="">
                          <div className="register_form homeform">
                            <h1 className="homeheading white_text">
                              LEARN FROM THE BEST, <br></br>BE YOUR BEST.
                            </h1>
                            <hr className="homehr"></hr>
                            {/* {!show ? (
                              <>
                                <h3 className="homeformtitle white_text">
                                WHAT BRINGS YOU TO LEARNOVATION INDIA TODAY ?
                                </h3>
                                <ul className="checkbox_group unordered_list_block homelist">
                                  <li>
                                    <div className="checkbox_item">
                                      <input
                                        id="checkbox_friends"
                                        type="radio"
                                        name="form_radio"
                                        onChange={handleChange}
                                        checked={refernceFrom === "friends"}
                                        value={"friends"}
                                      />
                                      <label
                                        style={{ width: "100%" }}
                                        for="checkbox_friends"
                                      >
                                        Friends or Colleagues
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="checkbox_item">
                                      <input
                                        id="checkbox_google"
                                        type="radio"
                                        name="form_radio"
                                        onChange={handleChange}
                                        checked={refernceFrom === "google"}
                                        value="google"
                                      />
                                      <label
                                        style={{ width: "100%" }}
                                        for="checkbox_google"
                                      >
                                        Google
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="checkbox_item">
                                      <input
                                        id="checkbox_blog_post"
                                        type="radio"
                                        name="form_radio"
                                        onChange={handleChange}
                                        value="blog post"
                                        checked={refernceFrom === "blog post"}
                                      />
                                      <label
                                        style={{ width: "100%" }}
                                        for="checkbox_blog_post"
                                      >
                                        Blog Post
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="checkbox_item">
                                      <input
                                        id="checkbox_other"
                                        type="radio"
                                        name="form_radio"
                                        value="other"
                                        onChange={handleChange}
                                        checked={refernceFrom === "other"}
                                      />
                                      <label
                                        style={{ width: "100%" }}
                                        for="checkbox_other"
                                      >
                                        Other
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                                <div className="sliderbtn">
                                <NavLink
                                  onClick={refernceFrom ? handleShow : null}
                                  type="submit"
                                  className="btn btn_primary mt-4"
                                >
                                  <span>
                                    <small>Continue</small>
                                    <small>Continue</small>
                                  </span>
                                  <i className="fal fa-paper-plane ms-2"></i>
                                </NavLink>
                                </div>
                               
                              </>
                            ) : (
                              <div>
                                <EnquiryForm
                                  setShow={setShow}
                                  refernceFrom={refernceFrom}
                                  refernceForm={true}
                                />
                              </div>
                            )} */}
                             <NavLink
                                  // onClick={refernceFrom ? handleShow : null}
                                  // type="submit"
                                  to="/courses" 
                                  className="btn btn_primary mt-4"
                                >
                                  <span>
                                    <small>Explore our courses</small>
                                    <small>Explore our courses</small>
                                  </span>
                                  <i className="fal fa-paper-plane ms-2"></i>
                                </NavLink>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* desktopview */}

      <section className="banner_section banner_style_1 decoration_wrap mobileview">
        <div className="container">
          <div className="row align-items-center justify-content-lg-between">
            <div className="order-lg-last col-12 col-lg-6">
              <div className="banner_content rescontent">
                <section className="membership_section pt-0">
                  <div className="container heading_text mb-0">
                    <div className="row justify-content-center">
                      <div className="col col-lg-12">
                        {/* <form action="#" className=""> */}
                        <div className="register_form homeform">
                          <h1 className="homeheading white_text">
                            LEARN FROM THE BEST, BE YOUR BEST.
                          </h1>
                          <hr className="homehr"></hr>
                          {/* {!show ? (
                              <>
                                <h3 className="homeformtitle white_text">
                                  What brings you to Learnovation India today?
                                </h3>
                                <ul className="checkbox_group unordered_list_block homelist">
                                  <li>
                                    <div className="checkbox_item">
                                      <input
                                        id="checkbox_friends"
                                        type="radio"
                                        name="form_radio"
                                        onChange={handleChange}
                                        checked={refernceFrom === "friends"}
                                        value={"friends"}
                                      />
                                      <label
                                        style={{ width: "100%" }}
                                        for="checkbox_friends"
                                      >
                                        Friends or Colleagues
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="checkbox_item">
                                      <input
                                        id="checkbox_google"
                                        type="radio"
                                        name="form_radio"
                                        onChange={handleChange}
                                        checked={refernceFrom === "google"}
                                        value="google"
                                      />
                                      <label
                                        style={{ width: "100%" }}
                                        for="checkbox_google"
                                      >
                                        Google
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="checkbox_item">
                                      <input
                                        id="checkbox_blog_post"
                                        type="radio"
                                        name="form_radio"
                                        onChange={handleChange}
                                        value="blog post"
                                        checked={refernceFrom === "blog post"}
                                      />
                                      <label
                                        style={{ width: "100%" }}
                                        for="checkbox_blog_post"
                                      >
                                        Blog Post
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="checkbox_item">
                                      <input
                                        id="checkbox_other"
                                        type="radio"
                                        name="form_radio"
                                        value="other"
                                        onChange={handleChange}
                                        checked={refernceFrom === "other"}
                                      />
                                      <label
                                        style={{ width: "100%" }}
                                        for="checkbox_other"
                                      >
                                        Other
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                                <NavLink
                                  onClick={refernceFrom ? handleShow : null}
                                  type="submit"
                                  className="btn btn_primary mt-4"
                                >
                                  <span>
                                    <small>Continue</small>
                                    <small>Continue</small>
                                  </span>
                                  <i className="fal fa-paper-plane ms-2"></i>
                                </NavLink>
                              </>
                            ) : (
                              <div>
                                <EnquiryForm
                                  setShow={setShow}
                                  refernceFrom={refernceFrom}
                                  refernceForm={true}
                                />
                              </div>
                            )} */}
                             <NavLink
                                  // onClick={refernceFrom ? handleShow : null}
                                  // type="submit"
                                  to="/courses"
                                  className="btn btn_primary mt-4"
                                >
                                  <span>
                                    <small>Explore our courses</small>
                                    <small>Explore our courses</small>
                                  </span>
                                  <i className="fal fa-paper-plane ms-2"></i>
                                </NavLink>
                        </div>
                        {/* </form> */}
                      </div>
                    </div>
                  </div>
                </section>
                {/* <h1 className="banner_title cd-headline clip is-full-width">
                <span className="d-block">Boost Your Investing Knowledge With Us</span>
                <span className="cd-words-wrapper">
                  <b className="is-visible">Capital Market</b>
                  <b className="is-hidden">Equity Broking</b>
                  <b className="is-hidden">Re-Structuring</b>
                  <b className="is-hidden">Mentoring</b>
                </span>
               
              </h1>
              <p className="text_black">
              Platform that is easy & intuitive. Assistance that is systematic and helpful.
              </p>
              <NavLink className="btn btn_primary mt-3" to="/courses"><span><small>Explore More</small><small>Explore More</small></span><i className="far fa-long-arrow-right ml-2"></i></NavLink> */}
              </div>
            </div>
            <div  className="col-12 col-lg-5  mt-5">
              <div style={{marginTop:'-21rem'}} className="row">
                {mobileview && <div className="carousel-container">
                  <div className="carousel" ref={carouselRef1}>
                    <img
                      src="../assets/img/home4.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home10.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home12.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home2.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home6.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home8.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home5.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home11.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home7.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home3.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home14.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home9.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home10.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home11.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home8.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home12.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home14.png"
                      alt="Image 2"
                      className="slide"
                    />
                    {/* More images */}
                  </div>
                  <div className="carousel" ref={carouselRef2}>
                    <img
                      src="../assets/img/home14.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home15.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home16.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home17.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home13.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home19.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home18.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home20.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home15.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home16.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home13.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home17.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home19.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home18.png"
                      alt="Image 2"
                      className="slide"
                    />
                    <img
                      src="../assets/img/home20.png"
                      alt="Image 2"
                      className="slide"
                    />
                    {/* More images */}
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Slider;