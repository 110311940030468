import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { notification } from "antd";
import { useNavigate } from "react-router";
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const AddMediaImage = () => {
  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const navigate = useNavigate();

  const [Mediacourses, setMediaCourses] = useState([]);
  const [mediaTitle, setMediaTitle] = useState("");
  const [mediaImage, setMediaImage] = useState("");
  const [mediaSelectCourse, setMediaSelectCourse] = useState("");
  // const [mediaDocument, setMediaDocument] = useState();
  // const [mediaVideo, setMediaVideo] = useState(null);

  useEffect(() => {
    getMediaCourses();
  }, []);

  const getMediaCourses = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetMediaCourses`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },

          withCredentials: true, // Send cookies with the request
        }
      );
      const result = response.data;
      setMediaCourses(result);
    } catch (error) {
      console.error("Error fetching Courses:", error);
    }
    finally {
      hideLoader();
    }
  };

  //add media
  const addMedias = async () => {
    // e.preventDefault();

    if (
      !mediaTitle ||
      // !mediaSelectCourse ||
      !mediaImage
    ) {
      // notification.error({
      //   message: "All fields are required",
      // });
      showNotificationWithMessage('All fields are required', 'error');

      return;
    }

    const formData = new FormData();
    formData.append("mediaTitle", mediaTitle);

    formData.append("mediaSelectCourse", mediaSelectCourse);
    formData.append("mediaImage", mediaImage);
    formData.append("documentType", "image");

    try {
      showLoader();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/AddMedia`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      console.warn(response.data);

      if (response.status === 200) {
        // notification.success({
        //   message: "Media added successfully",
        // });
        // navigate("/MediaImage");
        showNotificationWithMessage('Media added successfully', 'success');
        setTimeout(() => {
          navigate('/MediaImage');
        }, 1000);
      }
    } catch (error) {
      console.error("Error adding media:", error);
      // notification.error({
      //   message: "Error adding media",
      // });
      showNotificationWithMessage('All fields are required', 'error');

    }
    finally {
      hideLoader();
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full" style={{ marginTop: 40 }}>
          <section className="content">
            <div className="row">
            <div className="col-lg-12 mb-3">
                      <h5 className="box-title">Add Image</h5>
                      </div>
              {/* <form>/ */}
              <div className="col-lg-12 col-12 mx-auto">
                {/* <!-- box1 --> */}

                <div className="box">
                 
                  <div className="box-body pt-4">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            placeholder="title"
                            value={mediaTitle}
                            onChange={(e) => setMediaTitle(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Media Image</label>
                          <input
                            type="file"
                            name="image"
                            className="form-control"
                            placeholder="Image"
                            onChange={(e) => {
                              setMediaImage(e.target.files[0]);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Select Course</label>
                          <select
                            value={mediaSelectCourse}
                            onChange={(e) =>
                              setMediaSelectCourse(e.target.value)
                            }
                            className="form-control"
                          >
                            <option selected value="">
                              All
                            </option>
                            {Mediacourses.map((media) => (
                              <option key={media.title} value={media.title}>
                                {media.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- box1 -->

                  


                


                  {/* <!-- action --> */}
                <div className="box-footer mb-4">
                  <button
                    type="submit"
                    className="btn btn-rounded  btn_dark btn-outline btncourse"
                    style={{ padding: 10 }}
                    onClick={addMedias}
                  >
                    <i className="ti-save-alt"></i> Save Media
                  </button>
                </div>

                {/* <!-- action --> */}
              </div>
              {/* </form> */}
            </div>
          </section>

          {/* <!-- form --> */}

          {/* 		
		<!-- /.content --> */}
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default AddMediaImage;
