import React, { useEffect } from 'react'

import Loginbanner from '../../components/website/Login/Loginbanner'
import Signupform from '../../components/website/Signup/Signupform'

import { useLocation } from 'react-router-dom'; // Assuming you're using React Router

const Signup = () => {

  const location = useLocation();

  useEffect(() => {
    const section = document.getElementById('mySection');
    if (section) {
      section.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [location]);

  return (
    <>
      <main className="page_content" style={{
            backgroundImage: 'url("../assets/img/blackbg.jpg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}>
        <section id="mySection">

          <Loginbanner heading='Account Sign Up' />
          <Signupform />
        </section>

      </main>
    </>
  )
}

export default Signup