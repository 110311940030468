import React from "react";
import axios from "axios";
import { notification } from "antd";

import { NavLink, useNavigate } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import Localstorage from "../../../utills/storage/Localstorage";
import { routes } from "../../../utills/routes";
import styles from "./style.module.css";
import useNotification from "../../../useNotification"; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import { useEffect } from "react";

const Loginform = () => {
  const navigate = useNavigate();
  const { showNotificationWithMessage } = useNotification();
  const { showLoader, hideLoader } = useGlobalLoader();

  useEffect(() => {
    let userId = Localstorage.USER_ID.get();
    if (userId) {
      navigate("/home");
    }
  }, []);

  const handleKeyPress = (e) => {};

  return (
    <>
      <div className="register_section section_space_lg pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-5">
              <Formik
                initialValues={{ email: "", password: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Please enter valid email address";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  } else if (!values.password) {
                    errors.password = "Please enter password";
                  } else if (values.password.length < 8) {
                    errors.password = "Password must be at least 8 characters";
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    showLoader();
                    // let res = await routes.APIS.studentLogin(values);

                    // if (res.message === "Login Successfully") {
                    //   Localstorage.JWT_TOKEN.set(res.token);
                    //   Localstorage.USER_ID.set(res.userId);
                    //   console.log(res.message);
                    //   showNotificationWithMessage(res.message, 'success');
                    //   setTimeout(() => {
                    //     navigate('/home');
                    //   }, 1000);

                    // } else {
                    //   console.log(res.error)
                    //   showNotificationWithMessage(res.error, 'error');

                    // }
                    let response = await axios.post(
                      `${process.env.REACT_APP_BACKEND_URL}/studentlogin`,
                      values,
                      {
                        "Content-Type": "application/json",
                        token: Localstorage.JWT_TOKEN.get(),
                        userid: Localstorage.USER_ID.get(),
                      }
                    );

                    if (
                      response.status === 200 &&
                      response.data.message === "Login Successfully"
                    ) {
                      Localstorage.JWT_TOKEN.set(response.data.token);
                      Localstorage.USER_ID.set(response.data.userId);

                      // console.log(response.data.message);
                      showNotificationWithMessage(
                        response.data.message,
                        "success"
                      );
                      setTimeout(() => {
                        navigate("/home");
                      }, 1000);
                    } else if (
                      response.status === 400 &&
                      response.data.error === "Invalid Credentials"
                    ) {
                      showNotificationWithMessage(response.data.error, "error");
                    } else {
                      showNotificationWithMessage(
                        "Something Went Wrong",
                        "error"
                      );
                    }
                  } catch (e) {
                    showNotificationWithMessage(
                      e?.response?.data?.error
                        ? e.response.data.error
                        : "An error occurred",
                      "error"
                    );
                  } finally {
                    hideLoader(); // Hide the global loader
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <div className="register_form register_form_login signup_login_form login_form">
                    <div className="item_icon text-center">
                      <img
                        src="../assets/img/user.png"
                        className="infoimg"
                        alt="Icon Email"
                      />
                      <h6 className="login_heading mt-3 mb-4">
                        Let's Get Started
                      </h6>
                    </div>

                    <div className="form_item">
                      <input
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        name="email"
                        placeholder="Email"
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="email"
                        component="div"
                      />
                    </div>
                    <div className="form_item">
                      <input
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        name="password"
                        placeholder="Password"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault(); // prevent default behavior
                            handleSubmit();
                          }
                        }}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="password"
                        component="div"
                      />
                    </div>
                    <div className="remenber_forget row mb-2 align-items-center justify-content-between">
                      <div className="col col-12 text-end">
                        <div className="forget_password text-end">
                          <NavLink
                            to="/forget-password"
                            className="regcolor text-end"
                          >
                            Forgot Password
                          </NavLink>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-12">
                      <div className="d-flex justify-content-center">
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          className="btn btn_primary"
                          disabled={isSubmitting}
                        >
                          <span>
                            <small>Login</small>
                            <small>Login</small>
                          </span>
                          <i className="fal fa-paper-plane ml-2"></i>
                        </button>
                      </div>
                    </div>

                    {/* <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn_primary"
                      disabled={isSubmitting}
                    >
                      <span>
                        <small>Login</small>
                        <small>Login</small>
                      </span>
                      <i className="fal fa-paper-plane ml-2"></i>
                    </button> */}

                    {/* <ErrorMessage className="text-danger" name="password"  component="div" />
                    <ErrorMessage className="text-danger" name="email" component="div" /> */}
                    {/* {error ? (
                      <p className={styles.errorMessage}>{error}</p>
                    ) : (
                      <></>
                    )}
                     */}
                    <p className="mb-0 reg_btn text-center">
                      Don't have an account? &nbsp;
                      <NavLink to="/signup" className="regcolor">
                        Register Here
                      </NavLink>
                    </p>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loginform;
