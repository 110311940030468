import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import axios from "axios";

import Cookies from "js-cookie";
import { notification } from "antd";
import { ErrorMessage, Formik } from "formik";
import ImageCroper from "../../ImageCroper/ImageCroper";

import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const UpdateCourse = () => {

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const [result, setResult] = useState();
  const [title, setTitle] = useState("");
  const [course, setCourse] = useState("");
  const [category, setCategory] = useState("");
  const [short_desc, setShortDesc] = useState("");
  const [duration, setDuration] = useState("");
  const [batches, setBatches] = useState("");
  const [price, setPrice] = useState("");
  const [discount_price, setDiscountPrice] = useState("");
  const [tag, setTag] = useState("");
  // const [date, setDate] = useState('');
  const [meta_title, setMetaTitle] = useState("");
  const [meta_keyword, setMetaKeyword] = useState("");
  const [meta_desc, setMetaDesc] = useState("");
  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [posterURL, setPosterImageURL] = useState("");
  const [posterImage, setPosterImage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [cropingImage, setCropingImage] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(1.9);
  const [cropPosterImage, setPosterImageToCrop] = useState(false);

  const editor = useRef(null);
  const [content, setContent] = useState("");

  const navigate = useNavigate();

  const params = useParams();

  const getCourseDetails = async () => {
    try {
      showLoader();
      console.warn(params);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetEditCourse/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      const result = response.data;
      // console.log(result)
      setResult(result)
      setTitle(result.title);
      setCourse(result.course);
      setCategory(result.category);
      setShortDesc(result.short_desc);
      setImageURL(result.image);
      setPosterImageURL(result.posterImage);
      setDuration(result.duration);
      setBatches(result.batches);
      setPrice(result.price);
      setDiscountPrice(result.discount_price);
      setTag(result.tag);
      setMetaTitle(result.meta_title);
      setMetaKeyword(result.meta_keyword);
      setMetaDesc(result.meta_desc);
      setImage(result.image);
      setPosterImage(result.posterImage);
      setContent(result.course_content);
    } catch (error) {
      // Handle error
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader(); // Hide the global loader
    }
  };

  const imageHandler = (image, posterImage) => {
    setCropingImage(image);
    setPosterImageToCrop(posterImage);
    setShowModal(true);
  };

  const updateCourse = async () => {
    // console.log(image);
    const formData = new FormData();

    formData.append("title", title.toLowerCase());
    formData.append("course", course);
    formData.append("category", category);
    formData.append("short_desc", short_desc);
    formData.append("duration", duration);
    formData.append("batches", batches);
    formData.append("price", price);
    formData.append("discount_price", discount_price);
    formData.append("tag", tag);
    formData.append("meta_title", meta_title);
    formData.append("meta_keyword", meta_keyword);
    formData.append("meta_desc", meta_desc);
    formData.append("course_content", content);
    formData.append("image", image);
    formData.append("posterImage", posterImage);

    try {
      showLoader();
      // console.warn(name, price, category, company);
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateCourse/${params.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      console.warn(response.data);

      showNotificationWithMessage('Course Updated successfully', 'success');
      setTimeout(() => {
        navigate('/CourseList');
      }, 1000);
      // notification.success({
      //   message: "Course Updated successfully",
      // });
      // navigate("/CourseList");
    } catch (error) {
      // Handle error
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader(); // Hide the global loader
  }
  };

  const editorConfig = useMemo(() => {
    return {
      readonly: false,
      placeholder: "Start typings...",
      responsive: true,
    };
  }, []);

  useEffect(() => {
    if (image.size) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(image);
      fileReader.onloadend = () => {
        setImageURL(fileReader.result);
      };
    }
  }, [image])

  useEffect(() => {
    getCourseDetails();
  }, []);

  if (result) {
    return (
      <>
        <div className="content-wrapper">
          <div
            className="container-full"

            style={{ marginTop: 10}}

          >
            <section className="content">
              <div className="row">
              <div className="col-lg-12 mb-3">
                      <h5 className="box-title">Update Course</h5>
                      </div>
                {/* <form onSubmit={handleSubmit}> */}

                <Formik
                  initialValues={{
                    title: result?.title,
                    support: result?.support,
                    category: result?.category,
                    sub_category: result?.sub_category,
                    short_desc: result?.short_desc,
                    duration: result?.duration,
                    batches: result?.batches,
                    price: result?.price,
                    discount_price: result?.discount_price,
                    tag: result?.tag,
                    meta_title: result?.meta_title,
                    meta_keyword: result?.meta_keyword,
                    meta_desc: result?.meta_desc,
                    course_content: result?.course_content,
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.title) {
                      errors.title = "Please enter a title";
                    }
                    if (!values.support) {
                      errors.support = "Please enter a support";
                    }
                    if (!values.category) {
                      errors.category = "Please enter a category";
                    }
                    if (!values.sub_category) {
                      errors.sub_category = "Please enter a sub category";
                    }
                    if (!values.short_desc) {
                      errors.short_desc = "Please enter a short description";
                    }
                    if (!values.duration) {
                      errors.duration = "Please enter a duration";
                    }
                    if (!values.batches) {
                      errors.batches = "Please enter a batches";
                    }
                    if (!values.price) {
                      errors.price = "Please enter a price";
                    }
                    if (!values.discount_price) {
                      errors.discount_price = "Please enter a discount price";
                    }
                    if (!values.tag) {
                      errors.tag = "Please enter a tag";
                    }
                    if (!values.short_desc) {
                      errors.short_desc = "Please enter a tag";
                    }
                    if (!values.meta_title) {
                      errors.meta_title = "Please enter a meta title";
                    }
                    if (!values.meta_keyword) {
                      errors.meta_keyword = "Please enter a meta keyword";
                    }
                    if (!values.meta_desc) {
                      errors.meta_desc = "Please enter a meta description";
                    }
                    if (!content) {
                      errors.course_content = "Please enter a course content";
                    }
                    if (!content) {
                      errors.course_content = "Please enter a course content";
                    }
                    if (image == "" || image == null || image == undefined) {
                      errors.image = "Please add a image";
                    }
                    if (
                      posterImage == "" ||
                      posterImage == null ||
                      posterImage == undefined
                    ) {
                      errors.posterImage = "Please enter a poster image";
                    }
                    return errors;
                  }}
                  onSubmit={async (values) => {
                    const formData = new FormData();

                    formData.append("title", values.title.toLowerCase());
                    formData.append("support", values.support);
                    formData.append("category", values.category);
                    formData.append("sub_category", values.sub_category);
                    formData.append("short_desc", values.short_desc);
                    formData.append("duration", values.duration);
                    formData.append("batches", values.batches);
                    formData.append("price", values.price);
                    formData.append("discount_price", values.discount_price);
                    formData.append("tag", values.tag);
                    formData.append("meta_title", values.meta_title);
                    formData.append("meta_keyword", values.meta_keyword);
                    formData.append("meta_desc", values.meta_desc);
                    formData.append("course_content", content);
                    // formData.append("image", image);
                    formData.append("image", image);
                    formData.append("posterImage", posterImage);

                    try {
                      showLoader();
                      const response = await axios.put(
                        `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateCourse/${params.id}`,
                        formData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                          },
                          withCredentials: true, // Send cookies with the request
                        }
                      );
                      // console.log(response.data);
                      // console.log(short_desc);
                      // alert("Course successfully updated");
                      // navigate("/CourseList");
                      showNotificationWithMessage('Course successfully updated', 'success');
                      setTimeout(() => {
                        navigate('/CourseList');
                      }, 1000);
                    } catch (error) {
                      console.error(error);
                    }
                    finally {
                      hideLoader(); // Hide the global loader
                    }
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                  }) => (
                    <div className="col-lg-12 col-12 mx-auto">
                      {/* <!-- box1 --> */}

                      <div className="box">
                        <div className="box-body pt-5">

                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Title</label>
                                <input
                                  type="text"
                                  name="title"
                                  className="form-control"
                                  placeholder="Title"
                                  value={values.title}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="title"
                                  component="div"
                                />
                              </div>
                              </div>

                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <label>Category</label>
                                  <input
                                    type="text"
                                    name="category"
                                    className="form-control"
                                    placeholder="Category"
                                    value={values.category}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="category"
                                    component="div"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <label>Sub Category</label>
                                  <input
                                    type="text"
                                    name="sub_category"
                                    className="form-control"
                                    placeholder="Sub Category"
                                    value={values.sub_category}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="sub_category"
                                    component="div"
                                  />
                                </div>
                              </div>
                            

                            <div className="col-md-12 mt-2">
                              <div className="form-group">
                                <label>Short Description</label>
                                <textarea
                                  rows="2"
                                  name="short_desc"
                                  className="form-control"
                                  placeholder="Short Description"
                                  value={values.short_desc}
                                  onChange={handleChange}
                                ></textarea>

                                <ErrorMessage
                                  className="text-danger"
                                  name="short_desc"
                                  component="div"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 mt-2">
                              <div className="form-group">
                                <label>Duration</label>
                                <input
                                  type="text"
                                  name="duration"
                                  className="form-control"
                                  placeholder="Duration"
                                  value={values.duration}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="duration"
                                  component="div"
                                />
                              </div>
                            </div>

                            <div className="col-md-4 mt-2">
                              <div className="form-group">
                                <label>Length</label>
                                <input
                                  type="text"
                                  name="duration"
                                  className="form-control"
                                  placeholder="Duration"
                                  value={values.batches}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="batches"
                                  component="div"
                                />
                              </div>
                            </div>

                            <div className="col-md-4 mt-2">
                              <div className="form-group">
                                <label>Support</label>
                                <input
                                  type="text"
                                  name="duration"
                                  className="form-control"
                                  placeholder="Duration"
                                  value={values.support}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  className="text-danger"
                                  name="support"
                                  component="div"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                        {/* <!-- box1 -->
  
  
       <!-- box2 --> */}

                        <div className="box">
                          <div className="box-body">
                            <div className="row">
                              <div className="col-md-4 mt-2">
                                <div className="form-group">
                                  <label> Price</label>
                                  <input
                                    type="text"
                                    name="price"
                                    className="form-control"
                                    placeholder="Price"
                                    value={values.price}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="price"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 mt-2">
                                <div className="form-group">
                                  <label>Discount Price</label>
                                  <input
                                    type="text"
                                    name="discount_price"
                                    className="form-control"
                                    placeholder="Discount Price"
                                    value={values.discount_price}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="discount_price"
                                    component="div"
                                  />
                                </div>
                              </div>

                              <div className="col-md-4 mt-2">
                                <div className="form-group">
                                  <label>Tags</label>
                                  <input
                                    type="text"
                                    name="tag"
                                    className="form-control"
                                    placeholder="Tags"
                                    value={values.tag}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="tag"
                                    component="div"
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <label>Thumb Image</label>
                                  <div className="row">
                                    <div className="col-lg-6 mt-3">
                                    <input
                                    type="file"
                                    name="image"
                                    className="form-control"
                                    accept="image/*"
                                    onChange={(e) => {
                                      // imageHandler(e, false);
                                      setImage(e.target.files[0]);
                                    }}
                                  />
                                    </div>
                                    <div className="col-lg-2">
                                    {imageURL && (<img style={{ maxHeight: '3rem', margin: '1rem 2rem 0 0' }} src={imageURL} />)}
                                  {errors.image && (
                                    <span className="text-danger">
                                      {errors.image}
                                    </span>
                                  )}
                                    </div>
                                  </div>
                                
                                  
                                </div>
                              </div>

                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <label>Poster Image</label>
                                  <div className="row">
                                    <div className="col-lg-6 mt-3">
                                    <input
                                    type="file"
                                    name="posterImage"
                                    className="form-control"
                                    accept="image/*"
                                    onChange={(e) => {
                                      setPosterImage(e.target.files[0]);
                                      // imageHandler(e, true);
                                    }}
                                  />
                                    </div>
                                    <div className="col-lg-2">
                                    {posterURL && (<img style={{ maxHeight: '3rem', margin: '1rem 2rem 0 0' }} src={posterURL} />)}
                                  {errors.posterImage && (
                                    <span className="text-danger">
                                      {errors.posterImage}
                                    </span>
                                  )}
                                    </div>
                                  </div>
                                 
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* box3 */}

                        <div className="box">
                          <div className="box-body">
                            <div className="row">
                              <div className="col-md-12 mt-2">
                                <div className="form-group">
                                  <label>Description</label>
                                  <JoditEditor
                                    ref={editor}
                                    value={content}
                                    config={editorConfig}
                                    onChange={(newContent) =>
                                      setContent(newContent)
                                    }
                                  />
                                  {/* {error && !duration && <span className='invalid-input'> Enter valid duration</span>} */}
                                  <ErrorMessage
                                    className="text-danger"
                                    name="course_content"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* box3 */}

                        <div className="box">
                          <div className="box-body">
                            <div className="row">
                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <label>Meta Title</label>
                                  <input
                                    type="text"
                                    name="meta_title"
                                    className="form-control"
                                    placeholder="Meta Title"
                                    value={values.meta_title}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="meta_title"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 mt-2">
                                <div className="form-group">
                                  <label>Meta Keywords</label>
                                  <input
                                    type="text"
                                    name="meta_keyword"
                                    className="form-control"
                                    placeholder="Keywords"
                                    value={values.meta_keyword}
                                    onChange={handleChange}
                                  />
                                  <ErrorMessage
                                    className="text-danger"
                                    name="meta_keyword"
                                    component="div"
                                  />
                                </div>
                              </div>

                              <div className="col-md-12 mt-2">
                                <div className="form-group">
                                  <label>Meta Description</label>
                                  <textarea
                                    rows="2"
                                    name="meta_desc"
                                    className="form-control"
                                    placeholder="Meta Description"
                                    value={values.meta_desc}
                                    onChange={handleChange}
                                  ></textarea>
                                  <ErrorMessage
                                    className="text-danger"
                                    name="meta_desc"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- box4 --> */}

                        {/* <!-- action --> */}
                        <div className="box-footer mb-4">
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            className="btn btn-rounded btn_dark btn-outline btncourse"
                            style={{ padding: 10 }}
                          >
                            <i className="ti-save-alt"></i> Save
                          </button>
                        </div>

                        {/* <!-- action --> */}
                      </div>
                 
                  )}
                </Formik>
                {/* </form> */}
              </div>
            </section>

            {/* <!-- form --> */}

            {/* 		
    <!-- /.content --> */}
          </div>

          {/* <ImageCroper
            aspectRatio={aspectRatio}
            imagetoCrop={cropingImage}
            showModal={showModal}
            setImage={cropPosterImage ? setPosterImage : setImage}
            setShowModal={setShowModal}
          /> */}
        </div>
        {/* <!-- /.content-wrapper --> */}
      </>
    );

  }
};

export default UpdateCourse;