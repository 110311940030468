import React, { useEffect } from 'react'

import Servicedemo from '../../components/website/Service/Servicedemo'
import Servicetype from '../../components/website/Service/Servicetype'

import { useLocation } from 'react-router-dom'; // Assuming you're using React Router

const Services = () => {

  const location = useLocation();

  useEffect(() => {
    const section = document.getElementById('mySection');
    if (section) {
      section.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [location]);

  return (
    <>
     
        <main className="page_content">
        <section id="mySection">
          <Servicedemo />
          <Servicetype />
          </section>
        </main>
      
    </>
  )
}

export default Services