import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Input, Space, Table, notification } from "antd";
import Highlighter from "react-highlight-words";

import useNotification from "../../../useNotification"; // Import the custom hook
import Swal from "sweetalert2";
import { SearchOutlined } from "@ant-design/icons";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const CourseList = () => {

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const [courses, setCourses] = useState([]);

  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  function titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }

  //---------------------------delete course-------------------------------

  const deleteCourse = async (id) => {
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this record?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (!confirmed.isConfirmed) {
        return; // If user cancels, do nothing
      }
      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteCourses/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtoken')}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        getCourses();
        showNotificationWithMessage('Record is deleted', 'success');
      }
    } catch (error) {
      showNotificationWithMessage('Something went wrong', 'error');
    }
    finally {
      hideLoader(); // Hide the global loader
    }
  };

  const searchInput = useRef(null);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // const [ setMediaType] = useState();

  const [columns, setColumns] = useState([
    // "S No.",
    // "Name",
    // "Email",
    // "Date",
    // "Actions",
    { title: "Sr no", dataIndex: "index", key: "index" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Category", dataIndex: "category", key: "category", sorted: true, sorter: (a, b) => a.category.localeCompare(b.category)

    },
    { title: "Duration", dataIndex: "duration", key: "duration" },
    // { title: "Price", dataIndex: "price", key: "price" },
    { title: "Length", dataIndex: "batches", key: "batches" },
    { title: "Image", dataIndex: "image", key: "image", render: (text) => <img src={text} width={80} alt="image" /> },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      //   render: () => <a>Delete</a>,
    },
  ]);
  const [data, setData] = useState([]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetCourses`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },

          withCredentials: true, // Send cookies with the request
        }
      );

      // const result = response.data;
      // setCourses(result);
      const data = await response.data;
      setCourses(data);
      let newData = [];
      data.map((item, index) => {
        newData.push({
          key: item._id,
          index: index + 1,
          name: item.title,
          category: item.category,
          duration: item.duration,
          // price: item.price ? item.price : "Null",
          batches: item.batches,
          image: item.image,
          action: (
            <> <NavLink
              to={"/UpdateCourse/" + item._id}
              className="btn btn-circle btn-info btn-xs mr-10"
              data-toggle="tooltip"
              data-original-title="Edit"
            >
              <i class="ti-marker-alt"></i>
            </NavLink>
              <NavLink
                to="javascript:void(0)"
                className="btn btn-circle btn-danger btn-xs"
                title=""
                data-toggle="tooltip"
                data-original-title="Delete"
                onClick={() => {
                  deleteCourse(item._id);
                }}
              >
                <i class="ti-trash"></i>
              </NavLink>
            </>
          )
        });
      });
      setData(newData);

      if (!response.status === 200) {
        const error = new Error(response.error);
        throw error;
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
      navigate("/learnovation_admin");
    }
    finally {
      hideLoader(); // Hide the global loader
    }
  };


  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          {/* <!-- Content Header (Page header) --> */}
          <div className="content-header" style={{ marginTop: 30 }}>
            <div className="d-flex align-items-center">
              <div className="mr-auto">
                <h3 className="page-title">Course List</h3>
              </div>
              <div className="mr-end">
                <NavLink
                  className="waves-effect waves-light btn-block btn btn_dark addcourse"
                  to="/AddCourse"
                >
                  <i className="fa fa-plus mr-15"></i>Create New Course
                </NavLink>
              </div>
            </div>
          </div>

          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              <div className="col-12">
                <div className="box" style={{ marginTop: 50 }}>
                  <div className="box-body">
                    <div className="table-responsive">
                      <Table
                        columns={columns}
                        dataSource={data}
                        pagination={{ pageSize: 10 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  );
};

export default CourseList;
