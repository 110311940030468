import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { notification } from "antd";

import useNotification from '../../../useNotification'; // Import the custom hook

import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const Form = () => {
  const { showLoader, hideLoader } = useGlobalLoader();
  // const [name, setName] = useState("");
	const { showNotificationWithMessage } = useNotification();

  const params = useParams();


  const [studentName, setStudentName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");
  const [message, setMessage] = useState("");
  const [path , setPath] = useState("")


  const navigate = useNavigate();
  const [error, setError] = useState();


  const submitForm = async (e) => {
    e.preventDefault();


    if (!validateForm()) {
      return;
    }


    try {
      showLoader();
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/web/SendContactEnquiry`, {
        studentName,
        email,
        phone,
        age,
        city,
        message,
        pageName:path
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );

      // console.log(response);
			showNotificationWithMessage('We will contact you soon', 'success');
      setTimeout(() => {
			  navigate('/Contact');
			}, 1000);
      // notification.success({
      //   message: "We will contact you soon",
      // });
      // navigate("/Contact");
      setCity("");
      setAge("");
      setPhone("");
      setEmail("");
      setStudentName("");
      setMessage("");
//       navigate("/Contact");
    } catch (error) {
      console.error(error);
			showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader();
    }
  };
  const validateForm = () => {


    if (!studentName || !email || !phone || !age || !city || !message) {

			showNotificationWithMessage('All Fields Are Required', 'error');

      // notification.error({
      //   message: "Please fill in all required fields.",
      // })
      
      return false;
    }

    if (!isValidEmail(email)) {

      // notification.error({
      //   message: "Please enter a valid email address.",
      // })
			showNotificationWithMessage('Please enter a valid email address', 'error');

      return false;
    }

    if (!isValidPhone(phone)) {
			showNotificationWithMessage('Please enter a valid phone number', 'error');

      // notification.error({
      //   message: "Please enter a valid phone number.",
      // })
      return false;
    }

    if (!isValidName(studentName)) {
			showNotificationWithMessage('Please enter a valid name', 'error');

      // notification.error({
      //   message: "Please enter a valid name.",
      // })
      return false;
    }



    if (!isValidAge(age)) {
			showNotificationWithMessage('Please enter a valid age', 'error');

      // notification.error({
      //   message: "Please enter a valid age.",
      // })
      return false;
    }


    setError("");
    return true;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const isValidName = (studentName) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    return nameRegex.test(studentName);
  };


  const isValidAge = (age) => {
    const ageRegex = /^[0-9]{2}$/;
    return ageRegex.test(age);
  };

  useEffect(()=>{
    let path = window.location.pathname
    setPath(path)
  },[params])


  return (
    <>

        <section class="contact_section section_space_lg_contact  pt-0">
          <div class="container">
            <div class="section_space_lg_contact">
              <div class="row justify-content-center">
                   <div className="col-lg-6">
                   {/* <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d15131.107857144649!2d73.8991428!3d18.5389784!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1688474121006!5m2!1sen!2sin" width="600" height="650" style={{border: '0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                   <img src="../assets/img/contactpage2.png"></img>
                   </div>
                   <div className="col-lg-6">
                   <div class="contact_form_wrapper contactmargin">
                  <h2 class="contact_title text-center white_text">Have a Questions?</h2>
                  <form >
              <div className="row">
                <div className="col-12 col-md-6 mt-4">
                  <div className="form_item m-0">
                    <input
                      type="text"
                      name="fullname"
                      placeholder="Full Name"
                      value={studentName}
                      onChange={(e) => setStudentName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 mt-4">
                  <div className="form_item m-0">
                    <input
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>


                <div className="col-12 col-md-6 mt-4">
                  <div className="form_item m-0">
                    <input
                      type="number" 
                      name="age"
                      placeholder="Age"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 mt-4">
                  <div className="form_item m-0">
                    <input
                      type="text"
                      name="city"
                      placeholder="City"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-12 mt-4">
                  <div className="form_item m-0">
                    <input type="email" name="email" placeholder="Email" value={email}
                      onChange={(e) => setEmail(e.target.value)} />
                  </div>
                </div>

                <div className="col-12 mt-4">
                  <div className="form_item">
                    <textarea
                      name="massage"
                      placeholder="Write A Massage"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn_primary" onClick={submitForm}  >
                      <span>
                        <small>Send Message</small>
                        <small>Send Message</small>
                      </span>
                      <i className="fal fa-paper-plane ml-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
                </div>
                   </div>
              </div>
            </div>

            
          </div>
        </section> 


  
        
    

    
    </>
  );
};

export default Form;
