import React, { useEffect, useState } from "react";
// import React, { useState, useState useRef, useMemo } from "react";
import { useParams, useNavigate, NavLink, Link } from "react-router-dom";
import DOMPurify from "isomorphic-dompurify";
import axios from "axios";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Button,
} from "react-bootstrap";
import EnquiryForm from "./EnquiryForm";
import Content from "./Content";
import { notification } from "antd";

import useNotification from "../../../useNotification"; // Import the custom hook
import PayementForm from "./PaymentForm";
import PaymentComponent from "./PaymentForm";
import Localstorage from "../../../utills/storage/Localstorage";
import { routes } from "../../../utills/routes";
import { Helmet } from "react-helmet";

const Allcourses = ({ onHide }) => {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const auth = localStorage.getItem("JWT_TOKEN");
    setAuth(auth);
  }, [localStorage.getItem("JWT_TOKEN")]);

  const { showNotificationWithMessage } = useNotification();

  // popup
  const [show, setShow] = useState(false);
  // handleClose = () => {this.setState({show:false})};
  // handleShow = () =>{this.setState({show:true})};
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  // modal
  //////////////////////////////////////////////////////////////////

  const [display_name, setDisplayName] = useState("");
  const [course_id, setCourseID] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [short_desc, setShortDesc] = useState("");
  const [duration, setDuration] = useState("");
  const [batches, setBatches] = useState("");
  const [support, setSupport] = useState("");
  const [price, setPrice] = useState("");
  const [discount_price, setDiscountPrice] = useState("");

  const [image, setImage] = useState("");
  // const editor = useRef(null);
  const [course_content, setContent] = useState("");
  const [userCourses, setUserCourses] = useState([]);
  const [currentSubscribedCourse, setCurrentSubScribedCourse] = useState();
  const [userSubStartDate, setUserSubStartDate] = useState(null);

  const [tag, setTag] = useState("");
  const [meta_title, setMetaTitle] = useState("");
  const [meta_keyword, setMetaKeyword] = useState("");
  const [meta_desc, setMetaDesc] = useState("");

  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    getCourseDetails();
  }, []);
  function titleCase(str) {
    str = str.toLowerCase().split(" ");
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  }

  const handlepayment = async () => {
    if (!Localstorage.USER_ID.get()) {
      // showNotificationWithMessage(
      //   "You need to be logged in to make a payment. Please login or sign up."
      // );
      // setTimeout(() => { navigate('/login') }, 1000)
      navigate('/login')
      return;
    }
    let studentDetail = await routes.APIS.getStudentInfo(
      Localstorage.USER_ID.get()
    );
    let email = studentDetail.studentInfo.email;
    // let data = {
    //   userId: Localstorage.USER_ID.get(),
    //   price: price,
    //   courseName: display_name,
    //   course_id: course_id,
    //   email: email,
    // };

    let data = {
      amount: price,
      email: email,
      phone: studentDetail.studentInfo.phoneNumber,
      name: studentDetail.studentInfo.name,
      productinfo: display_name,
      userId: Localstorage.USER_ID.get(),
      course_id: course_id,
      surl: "http://localhost:3000/thankyou",
      furl: "http://localhost:3000/failed",
    };
    // axios
    //   .post(`http://localhost:5000/create-checkout-session`, {

    //   })
    //   .then((res) => {
    //     if (res.data.url) {
    //       window.location.href = res.data.url;
    //     }
    //   });

    let res = await routes.APIS.createCheckoutSession(data);
    // console.log("DFGHJKL", res);
    if (res.url) {
      window.location.href = res.url;
    }
    // console.log(res);
  };

  const getCourseDetails = async () => {
    try {
      console.warn(params);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/web/GetCoursesByTitle/${params.courseTitle}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = response.data;
      setDisplayName(result.Name);
      setCourseID(result._id);
      // setTitle(titleCase(result.title));
      setCategory(result.category);
      setShortDesc(result.short_desc);
      setDuration(result.duration);
      setBatches(result.batches);
      setSupport(result.support);
      setPrice(result.price);
      setDiscountPrice(result.discount_price);
      setTag(result.tag);
      setMetaTitle(result.meta_title);
      setMetaKeyword(result.meta_keyword);
      setMetaDesc(result.meta_desc);
      setImage(result.posterImage);
      setContent(result.course_content);

      setTag(result.tag);
      setMetaTitle(result.meta_title);
      setMetaKeyword(result.meta_keyword);
      setMetaDesc(result.meta_desc);
    } catch (error) {
      // Handle error
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage("Something went wrong", "error");
    }
  };

  //Banner
  const [bannerImage, setBannerImage] = useState([]);
  useEffect(() => {
    getBannerImage();
  }, []);

  const getBannerImage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/CourseDetail`
      );

      const result = response.data[0];
      setBannerImage(result);
      // console.log(result);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchUserCourses = async () => {
    try {
      if (Localstorage.USER_ID.get()) {
        const response = await routes.APIS.getStudentSubScription(
          Localstorage.USER_ID.get()
        );
        setUserCourses(
          response.map((sub) => {
            // if(sub)
            // console.log(">>>>>>>>>", params.courseTitle, sub.course.url, sub);
            if (params.courseTitle === sub.course.url) {
              setCurrentSubScribedCourse(sub);
            }
            return sub.course._id;
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchUserCourses();
  }, []);
  const strippedUserSubStartDate = new Date(userSubStartDate);
  strippedUserSubStartDate.setHours(0, 0, 0, 0);
  const currentStrippedDate = new Date();
  currentStrippedDate.setHours(0, 0, 0, 0);
  return (
    <>
      <div>
        <Helmet>
          <title>{meta_title}</title>
          <meta name="description" content={meta_desc} />
          <meta name="keywords" content={meta_keyword} />
          <meta name="tag" content={tag} />
        </Helmet>
      </div>

      {/*   <!-- Page Banner - Start
        ================================================== --> */}
      <section className="banner_section banner_style_4 herosection">
        <div
          className="decoration_wrap"
          style={{
            backgroundImage: `url(${bannerImage.bannerURL})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col col-lg-7">
                <div className="banner_content course_detail_banner">
                  <h1
                    className="banner_title herosec wow fadeInUp"
                    data-wow-delay=".1s"
                  >
                    {display_name}
                  </h1>
                  {/* <h6
                    className="text-white wow fadeInUp mt-1"
                    data-wow-delay=".1s"
                  >
                    Home <i className="far fa-angle-right"></i> {title}
                  </h6> */}
                  <h6 className="text-white wow fadeInUp" data-wow-delay=".1s">
                    <NavLink className="banneranchor" to={auth ? "/home" : "/"}>
                      Home &nbsp;
                    </NavLink>
                    <i className="far fa-angle-right"></i> {display_name}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Page Banner - End
        ================================================== --> */}
      {/* content */}
      <section className="details_section course_details_section">
        <div className="details_content decoration_wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <p className="details_info_title  white_text">{short_desc}</p>
              </div>
            </div>
            <div className="row align-items-center justify-content-lg-between">
              {/* image */}
              <div className="col-12 col-lg-8">
                <div className="course_details_video">
                  <img
                    src={image}
                    alt="Course Details Video Poster"
                    style={{
                      borderRadius: "12px",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}
                  />
                </div>
              </div>
              {/* image */}

              {/* cart */}
              <div className="col-12 col-lg-4">
                <div className="widget course_cart_info">
                  <div className="d-flex align-items-center justify-content-between">
                    {/* <div className="item_price">
                      <span className="sale_price">{price}</span>
                    </div> */}
                    {/* <div className="badge badge_warning">{discount_price}</div> */}
                  </div>

                  <ul className="cart_info_list unordered_list_block">
                    <li>
                      <span>Length</span>
                      <span className="badge badge_warning badgeclass">
                        {batches} Hrs
                      </span>
                    </li>
                    <li>
                      <span>Duration</span>
                      <span className="badge badge_warning badgeclass">
                        {duration} Months
                      </span>
                    </li>

                    <li>
                      <span>
                        Extended Duration <br></br>
                        {/* <span className="fontsupport">Support Period:</span> */}
                      </span>
                      <span className="badge badge_warning badgeclass">
                        {support} Months
                      </span>
                    </li>
                  </ul>

                  {!userCourses.includes(course_id) && !price ? (
                    <>
                      <button className="btn btn_warning" onClick={handleShow}>
                        <span>
                          <small>Enquire Now</small>
                          <small>Enquire Now</small>
                        </span>
                      </button>
                    </>
                  ) : !userCourses.includes(course_id) && price ? (
                    <>
                      <button
                        className="btn btn_warning"
                        onClick={handlepayment}
                      >
                        <span>
                          <small>Buy now</small>
                          <small>Buy now</small>
                        </span>
                      </button>
                      <button className="btn btn_warning" onClick={handleShow}>
                        <span>
                          <small>Enquire Now</small>
                          <small>Enquire Now</small>
                        </span>
                      </button>
                    </>
                  ) : strippedUserSubStartDate <= currentStrippedDate ? (
                    <Link className="btn btn_warning" to="/MyCourses">
                      <span>
                        <small>My Courses</small>
                        <small>My Courses</small>
                      </span>
                    </Link>
                  ) : (
                    <Link className="btn btn_warning" to="">
                      <span>
                        <small>
                          You can access this course from{" "}
                          {new Date(userSubStartDate).getDate()}
                        </small>
                      </span>
                    </Link>
                  )}
                </div>
              </div>
              {/* cart */}
            </div>
          </div>

          {/* <div
            className="deco_item deco_img_1"
            data-parallax='{"y" : -200, "smoothness": 6}'
          >
            <img
              src="../assets/images/shapes/line_shape_1.png"
              alt="Line Shape Image"
            />
          </div> */}
        </div>

        <div className="details_info_tab section_space_lg padding_bottom30 pt-0">
          <div className="container">
            <div className="row justify-content-lg-between">
              {/* <Description/> */}
              <div className="col col-lg-12 mt-5 grey_text fs22">
                {/* <p>{course_content}</p> */}
                <Content
                  HTML={DOMPurify.sanitize(course_content)}
                  className="courses_content"
                />
              </div>
              {/* Description */}

              {/* courses */}

              {/* Most Popular Courses */}

              {/* <div className="col-12 col-lg-3">
                <div className="row">
                  <h4 className="heading_text mb-3">
                    Most Popular
                    <span className="course_focus_text ml-2">Courses</span>
                  </h4>
                  <div
                    className="col-12 col-lg-12 col-md-6 col-sm-6 wow fadeInUp"
                    data-wow-delay=".1s"
                  >
                    <div className="course_item">
                      <div className="item_image">
                        <ul className="badge_group unordered_list">
                          <li>
                            <a className="badge badge_warning" href="#!">
                              New
                            </a>
                          </li>
                        </ul>
                        <a className="image_wrap" href="/Coursedetail">
                          <img
                            src="../assets/images/course/2.jpg"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="item_content">
                        <a className="course_instructor btn_unfill" href="#!">
                          Alen Mask
                        </a>
                        <h3 className="item_title">
                          <a href="/Coursedetail">Noiseless</a>
                        </h3>
                        <ul className="course_meta unordered_list">
                          <li>
                            <a href="#!">
                              <i className="far fa-bars mr-2"></i>16 hrs
                            </a>
                          </li>
                          <li>
                            <a href="#!">
                              <i className="fal fa-clone mr-2"></i> 2 Months
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-12 col-lg-12 col-md-6 col-sm-6 wow fadeInUp"
                    data-wow-delay=".1s"
                  >
                    <div className="course_item">
                      <div className="item_image">
                        <ul className="badge_group unordered_list">
                          <li>
                            <a className="badge badge_primary" href="#!">
                              New
                            </a>
                          </li>
                        </ul>
                        <a className="image_wrap" href="/Coursedetail">
                          <img
                            src="../assets/images/course/4.jpg"
                            alt="image"
                          />
                        </a>
                      </div>
                      <div className="item_content">
                        <a className="course_instructor btn_unfill" href="#!">
                          Alen Mask
                        </a>
                        <h3 className="item_title">
                          <a href="/Coursedetail">The Japanese way</a>
                        </h3>
                        <ul className="course_meta unordered_list">
                          <li>
                            <a href="#!">
                              <i className="far fa-bars mr-2"></i> Beginner
                            </a>
                          </li>
                          <li>
                            <a href="#!">
                              <i className="fal fa-clone mr-2"></i> 3 Lessons
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-12 col-lg-12 col-md-6 col-sm-6 wow fadeInUp"
                    data-wow-delay=".1s"
                  >
                    <div className="course_item">
                      <div className="item_image">
                        <ul className="badge_group unordered_list">
                          <li>
                            <a className="badge badge_danger" href="#!">
                              New
                            </a>
                          </li>
                        </ul>
                        <a className="image_wrap" href="/Coursedetail">
                          <img
                            src="../assets/images/course/6.jpg"
                            alt="Image"
                          />
                        </a>
                      </div>
                      <div className="item_content">
                        <a className="course_instructor btn_unfill" href="#!">
                          Alen Mask
                        </a>
                        <h3 className="item_title">
                          <a href="/Coursedetail">PAT</a>
                        </h3>
                        <ul className="course_meta unordered_list">
                          <li>
                            <a href="#!">
                              <i className="far fa-bars mr-2"></i> Beginner
                            </a>
                          </li>
                          <li>
                            <a href="#!">
                              <i className="fal fa-clone mr-2"></i> 3 Lessons
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* courses */}
            </div>
          </div>
        </div>
      </section>

      {/* form */}

      <Modal size="lg" show={show}>
        <ModalHeader
          closeButton={true}
          onClick={handleClose}
          style={{
            backgroundColor: "#000000",
            justifyContent: "center",
            color: "white",
          }}
        >
          <ModalTitle className="mt-2">
            <h4 style={{ color: "white" }}>Enquiry Form </h4>
          </ModalTitle>
          <hr></hr>
          <i
            className="fa fa-times fa-2x closeicon"
            aria-hidden="true"
            onClick={handleShow}
          ></i>
        </ModalHeader>

        <ModalBody
          style={{
            backgroundImage: 'url("../assets/img/model4.jpg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Row className="popupmargin">
            <Col md={6}>
              <img
                src="../assets/img/model1.png"
                alt="d1"
                className="modal-img"
                style={{ height: "100%" }}
              />
            </Col>
            <Col md={6}>
              <EnquiryForm title={display_name} setShow={setShow} />
            </Col>
          </Row>
        </ModalBody>
        {/* <Modal.Footer>
          <Button variant="secondary" >
            Close Modal
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Allcourses;
