import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import Profile from "./Profile";
import { useRef } from "react";

const Navbar = () => {
  const [auth, setAuth] = useState(null);

  const navigate = useNavigate();
  const navbarRef = useRef(null);
  useEffect(() => {
    const auth = localStorage.getItem("JWT_TOKEN");
    setAuth(auth);
  }, [localStorage.getItem("JWT_TOKEN")]);

  return (
    <>
      {/*   <!-- Header Section - Start
      ================================================== --> */}
      <header className="site_header header_style_1">
        <div className="header_top text-center grey_bg">
          <p className="m-0 notice">
            Learn more with exclusive courses, quizzes, and extra practice
            content
          </p>
          <NavLink className="headertoptext" to="/courses">
            <span>Learn more</span>
            <i className="far fa-long-arrow-right ml-2"></i>
          </NavLink>
        </div>
        <div className="header_bottom white_bg">
          <div className="container-fluid px-4">
            <div className="row align-items-center">
              <div className="col col-lg-2 col-5">
                <div className="site_logo">
                  <NavLink
                    className="site_link"
                    to={auth ? "/home" : "/"}
                  >
                    <img src="/assets/img/learnovationlogo.png" alt="" />
                  </NavLink>
                </div>
              </div>
              <div className="col col-lg-6 col-2">
                <nav className="main_menu navbar navbar-expand-lg">
                  <div
                    className="main_menu_inner collapse navbar-collapse justify-content-center"
                    id="main_menu_dropdown"
                    ref={navbarRef}
                  >
                    <ul className="main_menu_list unordered_list_center">
                      <li>
                        <NavLink
                          onClick={() => {
                            if (navbarRef.current.classList.contains("show"))
                              navbarRef.current.classList.remove("show");
                          }}
                          to={auth ? "/home" : "/"}
                        >
                          Home
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => {
                            if (navbarRef.current.classList.contains("show"))
                              navbarRef.current.classList.remove("show");
                          }}
                          to="/services"
                          className="nav-link"
                        >
                          Services
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => {
                            if (navbarRef.current.classList.contains("show"))
                              navbarRef.current.classList.remove("show");
                          }}
                          to="/courses"
                          className="nav-link"
                        >
                          Courses
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => {
                            if (navbarRef.current.classList.contains("show"))
                              navbarRef.current.classList.remove("show");
                          }}
                          to="/about-us"
                          className="nav-link"
                        >
                          About Us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => {
                            if (navbarRef.current.classList.contains("show"))
                              navbarRef.current.classList.remove("show");
                          }}
                          to="/blog"
                          className="nav-link"
                        >
                          Blog
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => {
                            if (navbarRef.current.classList.contains("show"))
                              navbarRef.current.classList.remove("show");
                          }}
                          to="/gallery"
                          className="nav-link"
                        >
                          Gallery
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() => {
                            if (navbarRef.current.classList.contains("show"))
                              navbarRef.current.classList.remove("show");
                          }}
                          to="/contact-us"
                          className="nav-link"
                        >
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="col col-lg-4 col-5">
                <ul className="header_btns_group unordered_list_end">
                  <li>
                    <button
                      className="mobile_menu_btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#main_menu_dropdown"
                      aria-controls="main_menu_dropdown"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="far fa-bars"></i>
                    </button>
                  </li>

                  {auth ? (
                    <Profile />
                  ) : (
                    <>
                      <li>
                        <NavLink to="/login" className="login_btn">
                          <i className="far fa-user"></i>
                          <span>login/Sign Up</span>
                        </NavLink>
                      </li>

                      <li>
                        {/* <NavLink to="/signup" className="btn btn_primary">
                          <i className="far fa-user-plus mr-1"></i>
                          <span>
                            <small>Sign Up</small>
                            <small>Sign Up</small>
                          </span>
                        </NavLink> */}
                        <a target="_blank" href="https://learnovation.in" className="btn btn_primary">
                          <i class="fa-solid fa-handshake-simple mr-1"></i>
                          <span>
                            <small>Partner With Us</small>
                            <small>Partner With Us</small>
                          </span>
                        </a>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/*   <!-- Header Section - End
      ================================================== --> */}
    </>
  );
};

export default Navbar;
