import React, { useEffect, useState } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { notification } from "antd";
import { useLocation } from "react-router-dom"; // Assuming you're using React Router
import { routes } from "../../../utills/routes";
import useNotification from "../../../useNotification"; // Import the custom hook
import { Helmet } from 'react-helmet';
import { Col, Modal, ModalBody, ModalHeader, ModalTitle, Row } from "react-bootstrap";
import PhysicalAssesmentform from "./PhysicalAssesmentform";

const Course = () => {
  const [courses, setCourses] = useState([]);
  const [category, setCategory] = useState("");
  const { showNotificationWithMessage } = useNotification();
  const [topCategories, setTopCategories] = useState([])
  const [categoryList, setCategoryList] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  const fetchCourses = async (cat) => {
    try {
      if (cat) {
        const response = await routes.APIS.getCoursesByCategories(cat)
        setCourses(response);
      } else { }
    } catch (error) {
      console.error("Error fetching Courses", error);
      showNotificationWithMessage("Error fetching Courses", "error");
    }
  };

  const handleCategoryChange = (e) => {
    fetchCourses(e);
  };

  const searchHandle = async (event) => {
    try {
      // let key = event.target.value;
      const inputValue = document.querySelector('input[name="search"]').value;
      // console.log(inputValue != "");
      if (inputValue != "") {
        const response = await routes.APIS.getCourses(`search=${inputValue}`);
        if (response) {
          setCourses(response);
          scrollToPopularCourses(); // Scroll to popular courses section
        }
      } else {
        getCourses();
        scrollToPopularCourses(); // Scroll to popular courses section
      }
    } catch (error) {
      showNotificationWithMessage("something went wrong", "error");
      console.error(error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // prevent default behavior
      searchHandle();
      scrollToPopularCourses();
    }
  };

  const scrollToPopularCourses = () => {
    const section = document.getElementById("popularCourses");
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const location = useLocation();

  useEffect(() => {
    scrollToPopularCourses();
  }, [location]);

  //Banner
  const [bannerImage, setBannerImage] = useState([]);
  useEffect(() => {
    getBannerImage();
  }, []);

  const getBannerImage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/Course`
      );
      const result = response.data[0];
      setBannerImage(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getCourses = async (e) => {
    try {
      const response = await routes.APIS.getCourses(e)
      setCourses(response)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCategory();
    getCourses();
  }, []);

  const fetchCategory = async () => {
    try {
      const response = await routes.APIS.getCategories();
      // console.log("fetchCategory", response)
      if (response) {
        const firstCategoryId = response[0]._id;
        if (firstCategoryId) {
          setSelectedCategory(firstCategoryId);
          fetchCourses(firstCategoryId);
        }
        setTopCategories(response.splice(0, 5))
        setCategoryList(response);
      }
    } catch (error) {
      console.error("Error fetching Categories", error);
      showNotificationWithMessage("Error fetching Categories", "error");
    }
  };

  useEffect(() => {
    const selectedCatInList = categoryList.find(cat => cat._id === selectedCategory);
    const selectedCatInTop = topCategories.find(cat => cat._id === selectedCategory);

    if (selectedCatInList) {
      setSelectedCategoryName(selectedCatInList.category);
    } else if (selectedCatInTop) {
      setSelectedCategoryName(selectedCatInTop.category);
    }
  }, [selectedCategory, categoryList, topCategories]);

  // console.log("selectedCategoryName", selectedCategoryName);


  return (
    <>
      {/* banner */}
      <section className="banner_section banner_style_4 mouse_move">
        <div
          className="decoration_wrap aboutbanner"
          style={{
            backgroundImage: `url(${bannerImage.bannerURL})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col col-lg-7">
                <div className="banner_content text-center">
                  <div
                    className="form_item m-0 wow fadeInUp"
                    data-wow-delay=".3s"
                  >
                    {/* <form> */}
                    <input
                      type="search"
                      name="search"
                      placeholder="Search course here"
                      // onChange={e=>getCourses(`search=${e.target.value}`)}
                      onKeyPress={handleKeyPress}
                    />
                    <button
                      type="submit"
                      className="submit_icon"
                      onClick={searchHandle}
                    >
                      <img src="../assets/img/search.png" alt="Search Icon" />
                    </button>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner */}
      <section
        className="course_section section_space_sm course_padding"
        id="popularCourses"
      >
        <div className="container">
          <div className="section_heading">
            <div className="row align-items-center">
              <div className="col col-lg-12 col-sm-12 text-center">
                <h2 className="heading_text mb-0 white_text">
                  The <span className="golden_text">"MAP"</span> towards your{" "}
                  <span className="golden_text">"SUCCESS"</span>
                </h2>
              </div>
              <div className="col-12 col-lg-12 col-sm-12 text-center tabcourse course_res">
                <select className="res_course rescoursetab"
                  onChange={e => handleCategoryChange(e.target.value)}>
                  <option selected disabled="true">
                    Select Course
                  </option>

                  {topCategories.map((cat) => {
                    return <option key={cat._id} value={cat._id}>{cat.category} </option>;
                  })}
                  {categoryList.map((cat) => {
                    return <option key={cat._id} value={cat._id}>{cat.category} </option>;
                  })}
                </select>
              </div>

              <div className="col-lg-12 mt-2 text-center desk_res">
                <ul className="tabs_nav nav navcourse" role="tablist" value>
                  <li role="presentation">
                    <button
                      // className="active white_text"
                      className={`white_text ${selectedCategory === "all" ? "active" : ""}`}
                      data-bs-toggle="tab"
                      data-bs-target="#all_courses"
                      type="button"
                      role="tab"
                      // aria-selected="true"
                      aria-selected={selectedCategory === "all" ? "true" : "false"}
                      data-magnetic
                      value=""
                      onClick={(e) => {
                        getCourses();
                        setSelectedCategory("all");
                      }}
                    >
                      All
                    </button>
                  </li>

                  {topCategories.map((cat) => {
                    return (
                      <li role="presentation" key={cat._id}>
                        <button
                          // className="white_text"
                          className={`white_text ${selectedCategory === cat._id ? "active" : ""}`}
                          style={{ maxWidth: '15rem' }}
                          data-bs-toggle="tab"
                          data-bs-target="#tab_seo"
                          type="button"
                          role="tab"
                          // aria-selected="false"
                          aria-selected={selectedCategory === cat._id ? "true" : "false"}
                          data-magnetic
                          // value="Learno Maestro"
                          onClick={(e) => {
                            handleCategoryChange(cat._id);
                            // setSelectedCategory('all');
                            setSelectedCategory(cat._id);
                          }}
                        >
                          {cat.category}
                        </button>
                      </li>
                    );
                  })}

                  {categoryList.length > 0 &&
                    <li>
                      <select
                        data-bs-toggle="tab"
                        data-bs-target="#tab_seo"
                        type="button"
                        role="tab"
                        aria-selected="false"
                        data-magnetic
                        // style={{maxWidth:'2rem'}}
                        className="res_course rescoursetab white_text"
                        onChange={(e) => {
                          handleCategoryChange(e.target.value);
                          setSelectedCategory(e.target.value);
                        }}
                        value={selectedCategory}>
                        <option selected value={"all"} disabled="true">
                          Select Course
                        </option>
                        {categoryList.map((cat) => {
                          return <option value={cat._id}>{cat.category} </option>;
                        })}
                      </select>
                    </li>}
                </ul>
              </div>

              {/* cat */}

            </div>
          </div>
          {/* <section id="popularCourses"> */}
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="all_courses"
              role="tabpanel"
            >
              <div className="row">
                {/* {console.log(courses)} */}
                {courses.length > 0 ? (
                  courses.map((item) => (
                    <div
                      className="col-12 col-lg-3 mt-5"
                      data-aos="fade-up"
                      key={item._id}
                    >
                      <NavLink to={"/course/" + item.url}>
                        <div className="course_item">
                          <div className="item_image">
                            {/* <ul className="badge_group unordered_list">
                              <li>New</li>
                            </ul> */}

                            <img src={item.image} alt="" />
                          </div>
                          <div className="item_content">
                            {/* <h3 className="item_title">{titleCase(item.title)}</h3> */}
                            <h3 className="item_title  textTruncateText">{item.Name}</h3>

                            <ul className="course_meta unordered_list">
                              <li>
                                <i className="far fa-bars mr-2"></i>
                                {item.duration} Months
                              </li>
                              <li>
                                <i className="fal fa-clock-o mr-1"></i>
                                {item.batches} hrs
                              </li>
                            </ul>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  ))
                ) : (
                  <div className="col-lg-12 text-center">
                    {/* <h1 className="mt-5 golden_text text-center noresultfound">
                      No Result Found
                    </h1>
                    <button className="btn btn_warning" onClick={handleShow} >
                      <span>
                        <small>Book your Physical Assessment</small>
                        <small>Book your Physical Assessment</small>
                      </span>
                    </button> */}
                    {(selectedCategoryName === "Learno Maestro" || selectedCategoryName === "Learno Maestro Pro") ? (
                      <button className="btn btn_warning mt-4" onClick={handleShow}>
                        <span>
                          <small>Book your Physical Assessment</small>
                          <small>Book your Physical Assessment</small>
                        </span>
                      </button>
                    ) : (
                      <h1 className="mt-5 golden_text text-center noresultfound">
                        No Result Found
                      </h1>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* </section> */}
        </div>
      </section>

      <Modal size="lg" show={show}>
        <ModalHeader
          closeButton={true}
          onClick={handleClose}
          style={{
            backgroundColor: "#000000",
            justifyContent: "center",
            color: "white",
          }}
        >
          <ModalTitle className="mt-2">
            <h4 style={{ color: "white" }}>Book your Physical Assessment</h4>
          </ModalTitle>
          <hr></hr>
          <i
            className="fa fa-times fa-2x closeicon"
            aria-hidden="true"
            onClick={handleShow}
          ></i>
        </ModalHeader>

        <ModalBody
          style={{
            backgroundImage: 'url("../assets/img/model4.jpg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Row className="popupmargin">
            <Col md={6}>
              <img
                src="../assets/img/model1.png"
                alt="d1"
                className="modal-img"
                style={{ height: "100%" }}
              />
            </Col>
            <Col md={6}>
              <PhysicalAssesmentform title={selectedCategoryName} setShow={setShow} />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Course;
