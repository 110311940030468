import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import HomeWeb from "./pages/website/HomeWeb";
import Services from "./pages/website/Services";
import Servicedetail from "./pages/website/Servicedetail";
import Courses from "./pages/website/Courses";
// import Course from "./pages/website/Course";
import Coursedetail from "./pages/website/Coursedetail";
import About from "./pages/website/About";
import Blog from "./pages/website/Blog";
import Blogdetail from "./pages/website/Blogdetail";
import Contact from "./pages/website/Contact";
import Login from "./pages/website/Login";
import EnquiryForm from "./components/website/Coursedetail/EnquiryForm";
import Signup from "./pages/website/Signup";
import Main from "./components/website/Main";
import Thankyou from "./components/website/Thankyou/Thankyou";

// student dashboard

import MyMedia from "./pages/website/MyMedia";
// import Mediademo from "./components/Mymedia/Media";
import MyCourses from "./components/website/StudentDashboard/MyCourses";
import Home from "./components/website/StudentDashboard/Dashboard/Home";

import MyProfile from "./pages/website/MyProfile";

// ---------------aos animation

import Aos from "aos";
import "aos/dist/aos.css";

//-------------- admin panel--------------------

import AdminMain from "./components/admin/AdminMain";
import DashboardHome from "./components/admin/Dashboard/DashboardHome";
import AddCourse from "./components/admin/Courses/AddCourse";
import CourseList from "./components/admin/Courses/CourseList";
import UpdateCourse from "./components/admin/Courses/UpdateCourse";
import Enquiries from "./components/admin/Enquiry/Enquiries";
import Students from "./components/admin/students/Students";
import AddMedia from "./components/admin/Media/AddMediaImage";
import MediaDocs from "./components/admin/Media/MediaDocs";
import AddMediaDocs from "./components/admin/Media/AddMediaDocs";
import MediaVideo from "./components/admin/Media/MediaVideo";
import AddMediaVideo from "./components/admin/Media/AddMediaVideo";
import MediaImage from "./components/admin/Media/MediaImage";
import AddMediaImage from "./components/admin/Media/AddMediaImage";
import UpdateMedia from "./components/admin/Media/UpdateMedia";
import AddBanner from "./components/admin/UiManagement/AddBanner";
import ViewBanner from "./components/admin/UiManagement/ViewBanner";

// login form
import LoginMain from "./components/adminLogin/LoginMain";
import AdminAuthLogin from "./components/adminLogin/AdminAuthLogin";
import PrivateComponent from "./components/admin/PrivateComponents";
import HomePage from "./components/website/Home/HomePage";
import Servicedemo from "./components/website/Service/Servicedemo";
import EditStudent from "./pages/admin/editStudent";
import UpdateBanner from "./components/admin/UiManagement/UpdateBanner";
import AddBlog from "./components/admin/Blogs/AddBlog";
import ViewBlogList from "./components/admin/Blogs/ViewBlogList";
import UpdateBlog from "./components/admin/Blogs/UpdateBlog";

import Mediademo from "./components/Mymedia/Mediademo";
import ImageCroper from "./components/ImageCroper/ImageCroper";
import AddServiceImg from "./components/admin/UiManagement/AddServiceImg";
import ViewServiceImg from "./components/admin/UiManagement/ViewServiceImg";
import UpdateServiceImg from "./components/admin/UiManagement/UpdateServiceImg";
import ForgetPassword from "./components/website/passwordReset/ForgetPassword";
import PasswordReset from "./components/website/passwordReset/PasswordReset";
import ViewServices from "./components/admin/Services/ViewServices";
import AddService from "./components/admin/Services/AddServices";

import AddTestimonial from "./components/admin/Testimonial/AddTestimonial";
import ViewTestimonial from "./components/admin/Testimonial/ViewTestimonial";
import UpdateTestimonial from "./components/admin/Testimonial/UpdateTestimonial";
import ContactEnquires from "./components/admin/Enquiry/ContactEnquires";
import EditServices from "./components/admin/Services/EditService";
import ServeyEnquires from "./components/admin/Enquiry/serveyEnquiry";
import AdminProfile from "./components/admin/AdminProfile/AdminProfile";
import PaymentSuccess from "./components/website/Payment/paymentSuccess";
import Failed from "./components/website/Thankyou/Failed";
import PageNotFound from "./pages/website/PageNotFound";
import Sitemap from "./pages/sitemap";
import Gallery from "./pages/website/Gallery";
import PrivacyPolicy from "./pages/website/PrivacyPolicy";

function App() {
  useEffect(() => {
    Aos.init({
      duration: "1000",
    });
    // console.log(process.env.REACT_APP_BACKEND_URL)
  }, []);

  // const navigate = useNavigate();

  // const redirectToNotFound = () => {
  //   navigate('/404');
  // };

  return (
    <div className="App">
      {/* website */}
      <Routes>
        <Route path="/" exact element={<Main />}>
          <Route path="" element={<HomeWeb />} />
          <Route path="services" element={<Services />} />
          <Route path="courses" element={<Courses />} />
          <Route path="blog" element={<Blog />} />
          <Route path="about-us" element={<About />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="/blog/:id" element={<Blogdetail />} />
          <Route path="/course/:courseTitle" element={<Coursedetail />} />
          <Route path="EnquiryForm" element={<EnquiryForm />} />
          <Route path="service/:id" element={<Servicedetail />} />
          <Route path="mymedia" element={<MyMedia />} />
          <Route path="/MyMedia/:courseId" element={<MyMedia />} />
          <Route path="mycourses" element={<MyCourses />} />
          <Route path="home" element={<Home />} />
          <Route path="myprofile" element={<MyProfile />} />
          {/* <Route path="HomePage" element={<HomePage />} /> */}
          <Route path="Servicedemo" element={<Servicedemo />} />
          <Route path="Mediademo" element={<Mediademo />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="thankyou" element={<Thankyou />} />
          <Route path="failed" element={<Failed />} />
          <Route path="sitemap" element={<Sitemap />} />

          <Route path="gallery" element={<Gallery />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />

          {/* PasswordReset */}

          < Route path="forget-password" element={<ForgetPassword />} />
          <Route path="PasswordReset/:userId/:resetToken" element={<PasswordReset />} />
        </Route>

        {/* 404 */}
        <Route path="*" element={<PageNotFound />} />


        {/* website end*/}

        {/* adminroute */}

        {/* <Route element={<PrivateComponent />}> */}

        <Route path="" exact element={<AdminMain />}>
          <Route path="/DashboardHome" element={<DashboardHome />} />
          <Route path="/AddCourse" element={<AddCourse />} />
          <Route path="/CourseList" element={<CourseList />} />
          <Route path="/UpdateCourse/:id" element={<UpdateCourse />} />
          <Route path="Enquiries" element={<Enquiries />} />
          <Route path="ContactEnquires" element={<ContactEnquires />} />
          <Route path="serveyEnquires" element={<ServeyEnquires />} />
          <Route path="Students" element={<Students />} />

          <Route path="/admin/editstudent/:id" element={<EditStudent />} />
          {/* <Route path="/AddMedia/:mediaType" element={<AddMedia />} /> */}
          <Route path="MediaDocs" element={<MediaDocs />} />
          <Route path="AddMediaDocs" element={<AddMediaDocs />} />
          <Route path="MediaVideo" element={<MediaVideo />} />
          <Route path="AddMediaVideo" element={<AddMediaVideo />} />
          <Route path="MediaImage" element={<MediaImage />} />
          <Route path="AddMediaImage" element={<AddMediaImage />} />
          <Route path="UpdateMedia" element={<UpdateMedia />} />
          <Route path="/UpdateMedia/:id" element={<UpdateMedia />} />

          <Route path="AddBanner" element={<AddBanner />} />
          <Route path="ViewBanner" element={<ViewBanner />} />
          <Route path="/UpdateBanner/:id" element={<UpdateBanner />} />

          <Route path="AddBlog" element={<AddBlog />} />
          <Route path="ViewBlogList" element={<ViewBlogList />} />
          <Route path="/UpdateBlog/:id" element={<UpdateBlog />} />
          <Route path="AddService" element={<AddService />} />
          <Route path="ViewServicesList" element={<ViewServices />} />
          <Route path="/UpdateService/:id" element={<EditServices />} />
          <Route path="/UpdateBlog/:id" element={<UpdateBlog />} />


          <Route path="AddBanner" element={<AddBanner />} />
          <Route path="ViewBanner" element={<ViewBanner />} />
          <Route path="/UpdateBanner/:id" element={<UpdateBanner />} />

          <Route path="ViewServiceImg" element={<ViewServiceImg />} />
          <Route path="AddServiceImg" element={<AddServiceImg />} />
          <Route path="/UpdateServiceImg/:id" element={<UpdateServiceImg />} />

          <Route path="AddTestimonial" element={<AddTestimonial />} />
          <Route path="ViewTestimonial" element={<ViewTestimonial />} />
          <Route path="/UpdateTestimonial/:id" element={<UpdateTestimonial />} />


          {/* admin profile */}
          <Route path="AdminProfile" element={<AdminProfile />} />

        </Route>
        <Route path="/learnovation_admin" exact element={<LoginMain />}>
          <Route path="" element={<AdminAuthLogin />} />
        </Route>




        {/* adminroute end */}
        {/* adminroute end */}



      </Routes>
    </div >
  );
}

export default App;
