import React from 'react'

const ServiceContent = ({title,content,DetailsImage
}) => {
  return (
    <>
     <section className="details_section event_details_section pt-5">
          <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12">
                <p className="page_heading serviceheading white_text">
                {title}
                </p>                  
              </div>
              <div className="col-12 col-lg-12 mt-5">
              <img
                    src={DetailsImage
                    }
                    alt="Course Details Video Poster"
                    style={{
                      borderRadius: "12px",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}
                  />            
              </div>
                <div className="col-12 col-lg-12">
                  <div dangerouslySetInnerHTML={{__html:content}} className="details_content">
                  </div>
                {/* <div className="details_content">
                  <p>
                  With power-packed smart features and no downtime, our innovative trading platforms fit your
experience level and needs. You can place orders, analyze charts, and check your positions across all
of Learnovation’s platforms (mobile, web, and desktop). Stay current with the markets and manage your
investments wherever you are.
                  </p>
                  <p>
                   Our advanced trading tools ensure you have all
available options at your fingertips even when
you are on the go.Access real-time stocks, research and analysis tools with our fast, easy-to-use we platform packed with comprehensive range of trading and investing features
                  </p>
               
                  <h3 className="details_info_title pt-3">
                    Contribute to environmental open-source projects
                  </h3>
                  <p className="mb-5">
                    From browsing the internet with a tree-planting search engine to lending your skills to environmental open-source projects, here's a list of ways you can reduce your carbon footprint and become a more sustainable programmer.
                  </p>
                 
                </div> */}
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default ServiceContent