import React, { useEffect, useState } from 'react'

import Banner from '../../components/website/Banner'
import Info from '../../components/website/Contact/Info'
import Form from '../../components/website/Contact/Form'

import { useLocation } from 'react-router-dom'; // Assuming you're using React Router
import axios from 'axios';

const Contact = () => {

  const location = useLocation();

  useEffect(() => {
    const section = document.getElementById('mySection');
    if (section) {
      section.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [location]);

  const [bannerImage, setBannerImage] = useState([]);
  useEffect(() => {
    getBannerImage();
  }, []);

  const getBannerImage = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/Contact`);

      const result = response.data[0];
      setBannerImage(result);
      // console.log(result);

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <main className="page_content">
        <section id="mySection">
          <Banner heading='Contact Us' img={bannerImage.bannerURL} />
          <section className="contact_section section_space_sm">
            <div className="container">
              <Info />
              <Form />
            </div>
          </section>
        </section>
        <div className='map_margin'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3782.772795582759!2d73.8963086110761!3d18.539166682485284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDMyJzIxLjAiTiA3M8KwNTMnNTYuMCJF!5e0!3m2!1sen!2sin!4v1690980106741!5m2!1sen!2sin" width="600" height="350" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        </div>
      </main>

    </>
  )
}

export default Contact