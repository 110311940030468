import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie';
import axios from 'axios';
// import { notification } from 'antd';

import useNotification from '../../../useNotification'; // Import the custom hook

import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";


const Header = () => {

	const { showLoader, hideLoader } = useGlobalLoader();
	const navigate = useNavigate();
	const { showNotificationWithMessage } = useNotification();

	const handleLogout = async () => {
		try {

			showLoader();

			const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/logoutAdmin`,
				{
					headers: {
						Authorization: `Bearer ${Cookies.get("jwtoken")}`,
					},
					withCredentials: true, // Send cookies with the request
				}
			);
			// console.log(response.data);

			if (response.data) {

				hideLoader();

				showNotificationWithMessage('Logout Successfully', 'success');
				setTimeout(() => {
					navigate('/learnovation_admin');
				}, 1000);

			} else {
				showNotificationWithMessage('Something went wrong', 'error');
			}

		} catch (error) {
			showNotificationWithMessage('Something went wrong', 'error');
		}
	};

	return (
		<>

			<header className="main-header">
				<div className="d-flex align-items-center logo-box justify-content-start">					

					{/* <!-- Logo --> */}
					<NavLink to="" className="logo" >
						<div className="logo-lg">
							<span className="light-logo"><img src="../../assets/img/logo.png" alt="logo" /></span>
							<span className="dark-logo"><img src="../../assets/img/logo.png" alt="logo" /></span>

						</div>
					</NavLink>
				</div>
				{/* <!-- Header Navbar --> */}
				<nav className="navbar navbar-static-top" style={{ backgroundColor: "#dee2e6" }}>
				<h5 className="mt-4 dfont">Dashboard</h5>
					<div className="navbar-custom-menu  r-side">
						<ul className="nav navbar-nav">					
							<li className="btn-group nav-item d-lg-inline-flex d-none">
								<NavLink to="#" data-provide="fullscreen" className="waves-effect waves-light nav-link full-screen" title="Full Screen">
									<i className="icon-Expand-arrows"><span className="path1"></span><span className="path2"></span></i>
								</NavLink>
							</li>

						

       <li className="dropdown user user-menu ">
            <NavLink to="#" className="waves-effect waves-light dropdown-toggle" data-toggle="dropdown" title="User">
				<i className="icon-User"><span className="path1"></span><span className="path2"></span></i>
            </NavLink>
            <ul className="dropdown-menu animated flipInX dropdownmargin">
              <li className="user-body">
				 <NavLink className="dropdown-item admindropdown" to="/AdminProfile"><i className="ti-user text-muted mr-2"></i> Profile</NavLink>
				
				 <div className="dropdown-divider"></div>
				 <NavLink className="dropdown-item admindropdown" to="#"><i className="ti-lock text-muted mr-2"></i> Logout</NavLink>
              </li>
            </ul>
          </li>	




						</ul>
					</div>
				</nav>

			</header>

		</>

	)
}

export default Header