import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import styles from "./styles.module.css";
import { Button, Input, Space, Table, Checkbox, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import useNotification from "../../../useNotification"; // Import the custom hook
import Swal from "sweetalert2";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";



const ContactEnquires = () => {

  const { showLoader, hideLoader } = useGlobalLoader();
	const { showNotificationWithMessage } = useNotification();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [checkedList, setCheckedList] = useState([]);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const searchInput = useRef(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    // "S No.",
    // "Name",
    // "Email",
    // "Date",
    // "Actions",
    { title: "Sr no", dataIndex: "index", key: "index" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "City", dataIndex: "city", key: "city" },
    // { title: "Page name", dataIndex: "page", key: "page" },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };


  

  const getEnquiries = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getContactEnquires`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const result = response.data;
      // console.log("result",result);
      let newData = [];
      let index = 0;
      result.forEach((enquiry) => {
        if(enquiry.isServeyEnquiry!=true){
          newData.push({
            index: index + 1,
            name: enquiry.studentName,
            courseName: enquiry.title,
            email: enquiry.email,
            phone: enquiry.phone?enquiry.phone:'Null',
            city: enquiry.city?enquiry.city:'Null',
            key: enquiry._id,
            message: enquiry.message?enquiry.message:'Null',
            page:enquiry.pageName.split('/')[1],
            action: (
              <Space size="middle">
                <Button type="primary" className="btn-circle btn-danger btn-xs" onClick={() => deleteEnquiry(enquiry._id)}>
                <i className="fa fa-trash"></i>
                </Button>
              </Space>
            ),
          })
          index++;
        }
      });
      // console.log("hereeeee>>>>",newData);
      setData(newData);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
    finally{
      hideLoader();
    }
  };

  //delete enquiry

  const deleteEnquiry = async (id) => {
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this record?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
  
      if (!confirmed.isConfirmed) {
        return; // If user cancels, do nothing
      }
  
      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteContactEnquiry/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtoken')}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
  
      if (response.data) {
        getEnquiries();
        showNotificationWithMessage('Record is deleted', 'success');
      }
    } catch (error) {
      showNotificationWithMessage('Something went wrong', 'error');
    }
    finally{
      hideLoader();
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedList(selectedRowKeys)
    }
  };
  
  
  const handleDeleteSelected = async () => {
    if(checkedList.length === 0){
      showNotificationWithMessage('Please select atleast one record', 'error');
      return;
    }
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this record?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
  
      if (!confirmed.isConfirmed) {
        return; // If user cancels, do nothing
      }
  
      showLoader();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/deleteMultipleContactEnquiry`,
        checkedList,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('jwtoken')}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
  
      if (response.data) {
        getEnquiries();
        setCheckedList([]);
        showNotificationWithMessage('Record is deleted', 'success');
      }
    } catch (error) {
      showNotificationWithMessage('Something went wrong', 'error');
    }
    finally{
      hideLoader();
    }
  };
  useEffect(() => {
    getEnquiries();
  }, []);
 

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              <div className="col-6">
              <h3 className="page-title pt-4">Contact Enquiry</h3>
              </div>
              <div className="col-12">
                <div onClick={handleDeleteSelected} className={styles.deleteButton}>
                  <i class="ti-trash" style={{ marginRight: "10px" }}></i>{" "}
                  Delete Selected
                </div>
                <div className="box" style={{ marginTop: 50 }}>
                <Table
                  rowSelection={{type:"checkbox",...rowSelection}}
                    columns={columns}
                    expandable={{
                      expandedRowRender: (record) => {console.log("here",record);return<p style={{ margin: 0 }}>{record.message}</p>},
                      rowExpandable: (record) => record.message != 'Null',
                    }}
                    dataSource={data}
                    pagination={{ pageSize: 7 }}
                  />
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  );
};

export default ContactEnquires;
