import React from 'react'
import Navbar from '../../pages/website/Navbar'
import { Outlet } from 'react-router-dom'
import Footer from '../../pages/website/Footer'
import "../../website-assets/assets/css/style.css";
import "../../website-assets/demo_assets/css/indexstyle.css";
import "../../website-assets/assets/css/cursor.css";
import "../../website-assets/assets/css/fontawesome.css";
import "../../website-assets/assets/css/magnific-popup.css";
import "../../website-assets/assets/css/slick.css";




const Main = () => {
  return (
    <>
    <body className='websitecolor'>
    <Navbar />
    <Outlet />
    <Footer />
    </body>
    
    </>

  )
}

export default Main