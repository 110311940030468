import React from 'react'
import { NavLink } from 'react-router-dom'

const Membership = () => {

  return (
    <>
      <section className="membership_section section_space_md" style={{ backgroundImage: `url("../assets/img/coinbg2.png")`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
        <div className="container">
          <div className="membership_content_box decoration_wrap text-center borderradius" data-aos="zoom-in" >
            <div className="row justify-content-center">
              <div className="col col-lg-7" >
                <h2 className="title_text">
                  Ready to kick-start your career?
                </h2>
                <NavLink className="btn btn_primary" to="/courses">
                  <span>
                    <small>Explore All Courses</small>
                    <small>Explore All Courses</small>
                  </span>
                  <i className="far fa-long-arrow-right ml-2"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>



    </>
  )
}

export default Membership