import { notification } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useNotification from '../../../useNotification'; // Import the custom hook


const AddServiceImg = () => {

    const { showNotificationWithMessage } = useNotification();

    const navigate = useNavigate();
    const [serviceImage, setServiceImage] = useState(null);
    const [short_desc, setShort_desc] = useState('');

    const addServiceImg = async () => {
        if (!serviceImage) {
            // notification.error({
            //     message: "All fields are required",
            // });
            showNotificationWithMessage('All fields are required', 'error');

            return;
        }
        const formData = new FormData();
        formData.append("serviceImage", serviceImage);
        formData.append("short_desc", short_desc);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/admin/AddServiceImage`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            if (response.status === 200) {
                // notification.success({
                //     message: "Service Image added successfully",
                // });
                //   navigate("/ViewServiceImg");

                showNotificationWithMessage('Service Image added successfully', 'success');
                setTimeout(() => {
                    navigate('/ViewServiceImg');
                }, 1000);
            }

        } catch (error) {
            // notification.error({
            //     message: "Error adding Service Image",
            // });

            showNotificationWithMessage('Error adding Service Image', 'error');

        }
    }


    return (
        <>
            <div className="content-wrapper">
                <div className="container-full" style={{ marginTop: 20 }}>
                    <section className="content">
                        <div className="row">
                        <div className="col-lg-12 mb-3">
                      <h5 className="box-title">Add Service Page Image & Content</h5>
                      </div>
                            {/* <form>/ */}
                            <div className="col-lg-12 col-12 mx-auto">
                                {/* <!-- box1 --> */}

                                <div className="box">
                                   
                                    <div className="box-body">
                                        <div className="row">

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>
                                                        short Description
                                                    </label>
                                                      <textarea
                                                        rows="2"
                                                        name="short_desc"
                                                        className="form-control"
                                                        placeholder="short description"
                                                        value={short_desc}
                                                        onChange={(e) => {
                                                            setShort_desc(e.target.value);
                                                        }}
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>
                                                        Service Page Image
                                                        <span className="text-danger ml-1">
                                                            {/* (Size - 1920 x 400)* */}
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name="serviceImage"
                                                        onChange={(e) => {
                                                            setServiceImage(e.target.files[0]);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- box1 -->
                
                  {/* <!-- action --> */}
                                <div className="box-footer mb-4">
                                    <button
                                        type="submit"
                                        className="btn btn-rounded btn_dark btn-outline btncourse"
                                        onClick={addServiceImg}
                                        style={{ padding: 10 }}
                                    >
                                        <i className="ti-save-alt"></i> Save
                                    </button>
                                </div>

                                {/* <!-- action --> */}
                            </div>
                            {/* </form> */}
                        </div>
                    </section>

                    {/* <!-- form --> */}

                    {/* 		
		<!-- /.content --> */}
                </div>
            </div>
            {/* <!-- /.content-wrapper --> */}
        </>
    )
}

export default AddServiceImg