import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ServiceCard from "./ServiceCard";
import axios from "axios";
import Cookies from "js-cookie";

const Servicetype = () => {
  const [serviceDataOdd, setServiceDataOdd] = useState([]);
  const [serviceDataEven, setServiceDataEven] = useState([]);

  const getServices = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetServices`);
      const data = await response.data;
      setServiceDataOdd(data.filter((item, index) => index % 2 === 0));
      setServiceDataEven(data.filter((item, index) => index % 2 !== 0));
    } catch (error) {
      console.error("Error fetching service:", error);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <>
      <div className="container mt-5 pb-2">
        {serviceDataOdd.length > 0 &&
          serviceDataOdd.map((item, index) => (
            <div
              className={
                index == serviceDataOdd.length - 1
                  ? "row rowmargin"
                  : "row rowone"
              }
            >
              <div
                className="col-lg-6 col-md-12"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <ServiceCard
                  id={item._id}
                  title={item.title}
                  backgrondColor={item.backgroundColor}
                  img={item.image}
                  shortdescription={item.shortDescrioption}
                  textStyles={
                    item?.textStyles ? { ...JSON.parse(item?.textStyles) } : ""
                  }
                  imageStyles={
                    item?.imageStyles
                      ? { ...JSON.parse(item?.imageStyles) }
                      : ""
                  }
                  url={item.url}
                />
              </div>
              {serviceDataEven[index] && (
                <div
                  className="col-lg-6 col-md-12 cardthree"
                  data-aos="fade-up"
                  data-aos-duration="1000"  
                >
                  <ServiceCard
                    id={serviceDataEven[index]._id}
                    title={serviceDataEven[index].title}
                    img={serviceDataEven[index].image}
                    shortdescription={serviceDataEven[index].shortDescrioption}
                    backgrondColor={serviceDataEven[index].backgroundColor}
                    textStyles={
                      serviceDataEven[index]?.textStyles
                        ? { ...JSON.parse(serviceDataEven[index]?.textStyles) }
                        : ""
                    }
                    imageStyles={
                      serviceDataEven[index]?.imageStyles
                        ? { ...JSON.parse(serviceDataEven[index]?.imageStyles) }
                        : ""
                    }
                    url={serviceDataEven[index].url}
                  />
                </div>
              )}
            </div>
          ))}


      </div>
    </>
  );
};

export default Servicetype;
