import { NavLink, useNavigate } from "react-router-dom";

const CoursesCardComponent = ({ category, title, duration, img, id ,url, batches}) => {
  function titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }
  const navigate = useNavigate();

  return <div className="col-12 col-lg-3 col-md-6 col-sm-6 mt-3" data-aos="fade-up">
    <NavLink to={"/mymedia?course=" + url}
    >
      <div className="course_item">
        <div className="item_image">
          {/* <ul className="badge_group unordered_list">
         <li><a className="badge badge_warning" href="">New</a></li>
       </ul> */}
          <NavLink className="image_wrap" to={"/mymedia?course=" + url}>
            <img src={img} alt="" />
          </NavLink>
        </div>
        <div className="item_content">
          <div className="course_instructor">{category?.category}</div>
          <h3 className="item_title textTruncateText">
              {title}
          </h3>
          <ul style={{ display: "flex", width: '100%' }} className="course_meta unordered_list">
            <li><i className="far fa-bars me-1"></i>&nbsp;&nbsp;{batches} Months (Max.)</li>
            <li ><i className="fal fal fa-clock-o me-1"></i>&nbsp;{duration} hrs</li>
          </ul>
          <div onClick={(e) => navigate(`"/mymedia?course="${url}`)} className="headertoptext learnmore mt-2">Learn More  <i className="far fa-long-arrow-right ml-2"></i></div>
        </div>
      </div>
    </NavLink>
  </div>

}

export default CoursesCardComponent;