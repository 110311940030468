import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import axios from "axios";

import Cookies from "js-cookie";
import { notification } from "antd";

import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const UpdateBlog = () => {
  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const editor = useRef(null);

  const [blogTitle, setBlogTitle] = useState("");
  // const [shortInfo, setShortInfo] = useState("");
  const [blogAuthor, setBlogAuthor] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [blogThumbImage, setBlogThumbImage] = useState("");
  const [blogDetailImage, setBlogDetailImage] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getBlog();
  }, []);

  const getBlog = async () => {
    try {
      showLoader();
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/GetEditBlog/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        });
      const result = response.data;
      setBlogTitle(result.blogTitle);
      // setShortInfo(result.shortInfo);
      setBlogAuthor(result.blogAuthor);
      setBlogContent(result.blogContent);
      setBlogThumbImage(result.blogThumbImage);
      setBlogDetailImage(result.blogDetailImage);


    } catch (error) {
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
       hideLoader(); 
      }

  };

  //update blog
  const updateBlogs = async () => {
    const formData = new FormData();
    formData.append("blogTitle", blogTitle);
    // formData.append("shortInfo", shortInfo);
    formData.append("blogAuthor", blogAuthor);
    formData.append("blogContent", blogContent);
    formData.append("blogThumbImage", blogThumbImage);
    formData.append("blogDetailImage", blogDetailImage);

    try {
      showLoader();
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/admin/UpdateBlog/${params.id}`, formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      if (response.data) {
        // notification.success({
        //   message: "Blog updated successfully",
        // });
        // navigate("/ViewBlogList");

        showNotificationWithMessage('Blog updated successfully', 'success');
        setTimeout(() => {
          navigate('/ViewBlogList');
        }, 1000);
      }


    } catch (error) {
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    }finally {
       hideLoader(); 
      }

  }

  // const editorConfig = {
  //   responsive: true,
  //   // buttons: buttons,
  //   // uploader: {
  //   //     insertImageAsBase64URI: true,
  //   // },
  // };
  const editorConfig = useMemo(() => {
    return {
      readonly: false,
      placeholder: "Start typings...",
      responsive: true,
      uploader: {
        insertImageAsBase64URI: true,
      },
    };
  }, []);

  return (
    <>
      {/* <!-- Content Wrapper. Contains page content --> */}
      <div className="content-wrapper">
        <div className="container-full" style={{ marginTop: 20 }}>
          {/* <!-- Content Header (Page header) --> */}

          {/* <!-- form --> */}
          <section className="content">
            <div className="row">
            <div className="col-lg-12 mb-3">
                      <h5 className="box-title">Update Blog</h5>
                      </div>
              <div className="col-lg-12 col-12 mx-auto">
                {/* <!-- box1 --> */}
                {/* <form > */}
                <div className="box">
               
                  <div className="box-body pt-4">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Blog Title</label>
                          <input
                            type="text"
                            name="blogTitle"
                            className="form-control"
                            placeholder="Blog Title"
                            value={blogTitle}
                            onChange={(e) => {
                              setBlogTitle(e.target.value);
                            }}
                          />

                        </div>
                      </div>

                      {/* <div className="col-md-12 mt-2">
                        <div className="form-group">
                          <label>Short Info</label>
                          <textarea
                            rows="2"
                            name="shortInfo"
                            className="form-control"
                            placeholder="short Info"
                            value={shortInfo}
                            onChange={(e) => {
                              setShortInfo(e.target.value);
                            }}
                          ></textarea>

                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* <!-- box1 -->

                  {/* // <!-- box2 -->  */}

                <div className="box">
                  <div className="box-body">
                    <div className="row">
                      <div className="col-md-12 mt-2">
                        <div className="form-group">
                          <label> Blog Description</label>
                          {/* {useMemo(
                            () => ( */}
                          <JoditEditor
                            ref={editor}
                            config={editorConfig}
                            value={blogContent}
                            onChange={(newContent) =>
                              setBlogContent(newContent)
                            }
                          />
                          {/* ),
                            []
                          )} */}

                        </div>

                        {/* <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <label>Blog Description</label>
                            <JoditEditor
                              ref={editor}
                              config={editorConfig}
                              value={blogContent}
                              onChange={(e) => {
                                setBlogContent(e);
                              }}
                            />
                            
                          </div>
                        </div> */}

                      </div>
                    </div>
                  </div>
                </div>

                {/* box3 */}

                <div className="box">
                  <div className="box-body">
                    <div className="row">
                      <div className="col-md-6 mt-2">
                        <div className="form-group">
                          <label>Blog Author Name</label>
                          <input
                            type="text"
                            name="blogAuthor"
                            className="form-control"
                            placeholder="Blog Author"
                            value={blogAuthor}
                            onChange={(e) => {
                              setBlogAuthor(e.target.value);
                            }}
                          />

                        </div>
                      </div>


                    </div>
                  </div>
                </div>
                {/* <!-- box4 --> */}


                <div className="box">
                  <div className="box-body">
                    <div className="row">
                      <div className="col-md-6 mt-2">
                        <div className="form-group">
                          <label>Blog Thumbnail Image</label>
                          <input
                            type="file"
                            name="blogThumbImage"
                            className="form-control"
                            onChange={(e) => {
                              setBlogThumbImage(e.target.files[0]);
                            }}
                          />


                        </div>
                      </div>

                      <div className="col-md-6 mt-2">
                        <div className="form-group">
                          <label>Blog Detail Page Image</label>
                          <input
                            type="file"
                            name="blogThumbImage"
                            className="form-control"
                            onChange={(e) => {
                              setBlogDetailImage(e.target.files[0]);
                            }}
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>






                {/* <!-- action --> */}
                <div className="box-footer mb-4">

                  <button
                    type="submit"
                    className="btn btn-rounded  btn_dark btn-outline btncourse"
                    style={{ padding: 10 }}
                    onClick={updateBlogs}
                  >
                    <i className="ti-save-alt"></i> Update
                  </button>
                </div>

                {/* <!-- action --> */}

                {/* </form> */}
              </div>
            </div>
          </section>

          {/* <!-- form --> */}

          {/* 		
		<!-- /.content --> */}
        </div>
      </div>
    </>
  );
}

export default UpdateBlog