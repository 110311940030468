import { useEffect, useState } from "react";
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  BigPlayButton,
} from "video-react";

const Videoplayer = (props) => {
  const [videoURL, setVideoUrl] = useState(props.videoURL);
  const [honeyPOT, setHoneyPOT] = useState(null);
  // ControlBar.propTypes = {
  //   // Hide the control bar automatically after the player is inactive
  //   // default: true
  //   autoHide: PropTypes.bool,
  //   // The waiting time for auto hide after player is inactive (in milliseconds)
  //   // default: 3000
  //   autoHideTime: PropType.number,
  //   // Do not render default controls, only use custom ones provided as children of <ControlBar>
  //   // default: false
  //   disableDefaultControls: PropTypes.bool,
  //   // Do not render the control bar if set it to true
  //   // default: false
  //   disableCompletely: PropTypes.bool,
  // }
  useEffect(() => {
    console.log("props.videoURL", props.videoURL)
    setVideoUrl(props.videoURL);
    setHoneyPOT(Math.random());
  }, [props.videoURL]);

  if (videoURL)
    return (
      <div>
        {/* {honeyPOT && <div className="honeyPot" style={{display:"none"}}>{honeyPOT}</div>} */}
        <Player key={videoURL} fluid={false} src={videoURL} width={'100%'} height={550}>
          <BigPlayButton position="center" />
          <ControlBar>
            <ReplayControl seconds={10} order={1.1} />
            <ForwardControl seconds={30} order={1.2} />
            <CurrentTimeDisplay order={4.1} />
            <TimeDivider order={4.2} />
            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
            <VolumeMenuButton disabled />
          </ControlBar>
        </Player>
      </div>
    );
};

export default Videoplayer;
