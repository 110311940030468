import React, { useState } from "react";
import { notification } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const AddTestimonial = () => {
    const { showLoader, hideLoader } = useGlobalLoader();
	const { showNotificationWithMessage } = useNotification();

    const [testiTitle, setTestiTitle] = useState("");
    const [testiInfo, setTestiInfo] = useState("");
    const [testiName, setTestiName] = useState("");
    const [testiProfession, setTestiProfession] = useState("");
    const [testiImage, setTestiImage] = useState("");

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            showLoader();
            if (!testiTitle || !testiInfo || !testiName || !testiProfession || !testiImage) {
                // notification.error({
                //     message: "Please fill all field.",
                // });
			showNotificationWithMessage('Please fill all field', 'error');

                return;
            }
            const formData = new FormData();
            formData.append("testiTitle", testiTitle);
            formData.append("testiInfo", testiInfo);
            formData.append("testiName", testiName);
            formData.append("testiProfession", testiProfession);
            formData.append("testiImage", testiImage);

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/AddTestimonial`, formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true,
                }
            );
            if (response.status === 200) {
                // notification.success({
                //     message: "Testimonial Added Successfully",
                // });
                // navigate("/ViewTestimonial");

                showNotificationWithMessage('Testimonial Added Successfully', 'success');
			setTimeout(() => {
			  navigate('/ViewTestimonial');
			}, 1000);
            }

        } catch (error) {
            console.error("Error adding Testimonial:", error);
            // notification.error({
            //     message: "Error adding Testimonial ",
            // });
			showNotificationWithMessage('Error adding Testimonial', 'error');

        }
        finally {
            hideLoader();
        }

    };

    return (
        <>
            <div className="content-wrapper">
                <div
                    className="container-full"
                    style={{ marginTop: 20}}
                >
                    <section className="content">
                        <div className="row">
                        <div className="col-lg-12 mb-3">
                      <h5 className="box-title">Add Testimonial</h5>
                      </div>
                            {/* <form
                                onSubmit={handleSubmit}
                            > */}
                                <div className="col-lg-12 col-12 mx-auto">
                                    {/* <!-- box1 --> */}

                                    <div className="box">
                                      
                                        <div className="box-body pt-4">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Testimonial Title</label>
                                                        <input
                                                            type="text"
                                                            name="testiTitle"
                                                            className="form-control"
                                                            placeholder="Testimonial Title"
                                                            value={testiTitle}
                                                            onChange={(e) => {
                                                                setTestiTitle(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 mt-2">
                                                    <div className="form-group">
                                                        <label>Short Info</label>
                                                        <textarea
                                                            rows="2"
                                                            name="testiInfo"
                                                            className="form-control"
                                                            placeholder="short Info"
                                                            value={testiInfo}
                                                            onChange={(e) => {
                                                                setTestiInfo(e.target.value);
                                                            }}
                                                        ></textarea>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- box1 --> */}


                                    {/* // <!-- box2 -->  */}

                                    <div className="box">
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-6 mt-2">
                                                    <div className="form-group">
                                                        <label>Testimonial Author Name</label>
                                                        <input
                                                            type="text"
                                                            name="testiName"
                                                            className="form-control"
                                                            placeholder="Testimonial Author"
                                                            value={testiName}
                                                            onChange={(e) => {
                                                                setTestiName(e.target.value);
                                                            }}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-2">
                                                    <div className="form-group">
                                                        <label>Testimonial Author Profession</label>
                                                        <input
                                                            type="text"
                                                            name="testiProfession"
                                                            className="form-control"
                                                            placeholder="Testimonial Author Profession"
                                                            value={testiProfession}
                                                            onChange={(e) => {
                                                                setTestiProfession(e.target.value);
                                                            }}
                                                        />

                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- box4 --> */}


                                    <div className="box">
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-6 mt-2">
                                                    <div className="form-group">
                                                        <label>Testimonial Image</label>
                                                        <input
                                                            type="file"
                                                            // name="testiImage"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                setTestiImage(e.target.files[0]);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                               
                                            </div>
                                        </div>
                                    </div>


                                    {/* <!-- action --> */}
                                    <div className="box-footer mb-4">
                                        <button
                                            type="submit"
                                            className="btn btn-rounded btn_dark btn-outline btncourse"
                                            style={{ padding: 10 }}
                                            onClick={handleSubmit}
                                        >
                                            <i className="ti-save-alt"></i> Save
                                        </button>
                                    </div>

                                    {/* <!-- action --> */}
                                </div>
                            {/* </form> */}
                        </div>
                    </section>
                </div>

                {/* <!-- form --> */}

                {/* 		
      <!-- /.content --> */}
            </div >


        </>

    )
};
export default AddTestimonial;
