import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppstoreOutlined, CalendarOutlined, LinkOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Divider, Menu } from 'antd';

import styles from './UIManagement.module.css'

const { SubMenu } = Menu;

function getItem(label, key, icon, onClick, children) {
    return {
        key,
        icon,
        onClick,
        children,
        label,
    };
}

const items = [
    getItem(<div ><i className="fa fa-globe icolor iconpl" aria-hidden="true"></i><span className='pl-3 fontcolor'>UI Management</span></div>, 'header', null, null, [
        getItem('Banner', 'ViewBanner', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/ViewBanner'),
        getItem('Blog', 'ViewBlogList', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/ViewBlogList'),
        getItem('Testimonial', 'ViewTestimonial', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/ViewTestimonial'),
        getItem('Services', 'ViewServicesList', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/ViewServicesList'),
        getItem('Service Image', 'ViewServiceImg', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/ViewServiceImg'),
    ]),
];

const UIManagement = () => {
    const navigate = useNavigate();

    return (
        <>
            <Menu
                style={{ width: "100%" ,padding:0}}
                defaultSelectedKeys={['header']}
                defaultOpenKeys={['uiManagement']}
                mode="inline"
                // theme="light"
                className={styles.header}
            >
                {items.map((item) => {
                    if (item.children) {
                        return (
                            <SubMenu key={item.key} icon={item.icon} style={{padding:0}} title={item.label} className={styles.submenu}>
                                {item.children.map((child) => (
                                    <Menu.Item key={child.key} icon={child.icon} onClick={() => navigate(child.onClick)}>
                                        {child.label}
                                    </Menu.Item>
                                ))}
                            </SubMenu>
                        );
                    } else {
                        return (
                            <Menu.Item key={item.key} icon={item.icon} onClick={() => navigate(item.onClick)}>
                                {item.label}
                            </Menu.Item>
                        );
                    }
                })}
            </Menu>
        </>
    );
};

export default UIManagement;
