import React from 'react'
import CountUp from 'react-countup';
const Count =[
  {
    id:"1",
    num:"500",
    title:"Learners"
  },
  {
    id:"2",
    num:"4",
    title:"Services"
  },
  {
    id:"3",
    num:"30",
    title:"Courses"
  }
]
const Counter = () => {
  return (
   <>
   <section className="counter_section decoration_wrap">
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">
              <div className="col col-lg-5 col-12">
                <h2 className="area_title" data-aos="fade-right">
                  <span className="d-lg-block golden_text">"Jump on board for something significant! Be a part of our momentous endeavor"</span>
                </h2>
              </div>
              {
                Count.map((countup)=>(
                  <div className="col col-lg-2 col-md-4 col-sm-4" key={countup.id}>
                  <div className="counter_item text-center">
                    <h3 className="counter_value  white_text">
                      <CountUp start={0} end={countup.num} suffix="+" duration={2.75} className="value_text"></CountUp>
                    </h3>
                    <h4 className="counter_title  mb-0  white_text">{countup.title}</h4>
                  </div>
                </div>
                ))
              }

            </div>
          </div>
          {/* <div className="deco_item shape_1" data-parallax='{"y" : 80, "smoothness": 6}'>
            <img src="../assets/images/shapes/shape_3.png" alt="Shape"/>
          </div>
          <div className="deco_item shape_2" data-parallax='{"y" : -80, "smoothness": 6}'>
            <img src="../assets/images/shapes/line_shape_3.png" alt="Shape"/>
          </div>
          <div className="deco_item shape_3" data-parallax='{"y" : 100, "smoothness": 6}'>
            <img src="../assets/images/shapes/dot_shape_4.png" alt="Shape"/>
          </div> */}
         
        </section>
   </>
  )
}

export default Counter