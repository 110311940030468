import React from "react";
import Banner from "../website/Banner";
import { useEffect, useState } from "react";
import { AppstoreOutlined, DownOutlined } from "@ant-design/icons";
import { Menu, Select } from "antd";
import { Dropdown, Space } from "antd";
import { useLocation, useParams } from "react-router";
import { routes } from "../../utills/routes";
import axios from "axios";
import styles from "./styles.module.css";
import Videoplayer from "../website/StudentDashboard/Media/videoPlayer";
import { Viewer } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";
import ReactDOM from "react-dom";
import { Pagination } from "antd";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import "@react-pdf-viewer/core/lib/styles/index.css"; // styles for the viewer


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Mediademo = () => {
  const params = useParams();
  const [mediaURL, setMediaURL] = useState("");
  const [mediaType, setMediaType] = useState("");
  const location = useLocation();
  const [Image, setImages] = useState([]);
  const [Video, setVideos] = useState([]);
  const [Document, setDocuments] = useState([]);
  const [bannerImage, setBannerImage] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedKeys, setSelectedKeys] = useState({
    type: "image",
    index: 0,
  });
  const [currentVideo, setCurrentVideo] = useState("");
  const [isDocumentModalVisible, setIsDocumentModalVisible] = useState(false);
  const [currentDocument, setCurrentDocument] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchParams] = useSearchParams();
  let query = useQuery();
  const openModalDocumentModal = (url) => {
    setCurrentDocument(url);
    setIsDocumentModalVisible(true);
  };
  const closeDocumentModal = () => {
    setIsDocumentModalVisible(false);
  };

  const openModalVideoModal = (videoURL, thumbnailURL) => {
    setCurrentImage(thumbnailURL); // Set the thumbnail as the current image
    setCurrentVideo(videoURL); // Set the video URL for modal playback
    setIsModalVisible(true);
  };

  const openModal = (imageURL) => {
    setCurrentImage(imageURL);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  const getMedia = async (courseId) => {
    try {
      setImages([]);
      setVideos([]);
      setDocuments([]);
      setCurrentPage(1)
      let videoIndex = 0;
      let documentIndex = 0;

      const res = courseId
        ? await routes.APIS.getMediaByCourseId(courseId)
        : await routes.APIS.getStudentMedia();

      let imageIndex = 1;
      if (res.data && res.data.length > 0) {
        res.data.map((item, index) => {
          if (item.mediaType === "image") {
            setImages((prev) => [...prev, item]);
          } else if (item.mediaType === "video") {
            setVideos((prev) => [...prev, item]);
          } else if (item.mediaType === "document") {
            setDocuments((prev) => [...prev, item]);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const searchMedia = async (data) => {
    try {
      setImages([]);
      setVideos([]);
      setDocuments([]);
      setCurrentPage(1)
      let videoIndex = 0;
      let documentIndex = 0;

      const res = await routes.APIS.searchMedia(data);
      // console.log(res);
      if (res.success == true) {
        let imageIndex = 1;
        if (res.data && res.data.length > 0) {
          res.data.map((item, index) => {
            if (item.mediaType === "image") {
              setImages((prev) => [...prev, item]);
            } else if (item.mediaType === "video") {
              setVideos((prev) => [...prev, item]);
            } else if (item.mediaType === "document") {
              setDocuments((prev) => [...prev, item]);
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const section = document.getElementById("mySection");
    if (section) {
      section.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, [location]);

  useEffect(() => {
    const courseValue = query.get("course");
    getMedia(courseValue);
    // console.log("Image", courseValue);
  }, []);

  useEffect(() => {
    getBannerImage();
  }, []);
  useEffect(() => {
    // console.log("selectedKeys", selectedKeys);
  }, [selectedKeys]);

  const getBannerImage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/MyMedia`
      );

      const result = response.data[0];
      setBannerImage(result);
    } catch (error) {
      console.error(error);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // prevent default behavior
      searchMedia(searchValue);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // console.log("doc",Document)
  return (
    <>
      <main className="page_content">
        <section className="details_section blog_details_section mediasection">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-12 pt-5 mt-2 text-center">
                <div
                  className="row"
                  style={{ justifyContent: "center", alignItems: "center", gap: "30px" }}
                >
                  <div className="col-lg-4">
                    <Select
                      defaultValue="image"
                      className="mediaSelect"
                      onChange={(e) => { setSelectedKeys({ type: e, index: 0 }); getMedia(); setSearchValue(''); setCurrentPage(1) }}
                      options={[
                        { value: "image", label: "Image" },
                        { value: "video", label: "Video" },
                        { value: "document", label: "Document" },
                      ]}
                    />
                  </div>
                  <div className="col-lg-4">
                    <div
                      className="form_media m-0 wow fadeInUp"
                      data-wow-delay=".3s"
                    >
                      {/* <form> */}
                      <input
                        type="search"
                        name="search"
                        placeholder="Search media here"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyPress={handleKeyPress}
                      />
                      <button
                        type="submit"
                        className="submit_icon"
                        onClick={(e) => {
                          e.preventDefault();
                          searchMedia(searchValue);
                        }}
                      >
                        <img
                          src="../assets/img/searchicon1.png"
                          alt="Search Icon"
                        />
                      </button>
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-12 mt-5">
                <div className="details_image mediasection">
                  <div className="row">
                    <div className="col-lg-12">
                      <div
                        className={`row justify-content-center ${styles.content}`}
                        onContextMenu={(e) => e.preventDefault()}
                      >
                        {selectedKeys.type == "image" && Image.length > 0 ? (
                          <>
                            {/* <div className={styles.mediaContainer}> */}
                            {Image.slice(
                              (currentPage - 1) * 9,
                              currentPage * 9
                            ).map((val) => (
                              <div key={val.id} className="col-md-3 col-sm-12">
                                <img
                                  src={val.mediaURL}
                                  alt="Media Item"
                                  onClick={() => openModal(val.mediaURL)}
                                  style={{
                                    height: "250px",
                                    objectFit: "cover",
                                  }} // adjust as required
                                />
                              </div>
                            ))}
                            {/* </div> */}

                            <Modal
                              visible={isModalVisible}
                              onCancel={closeModal}
                              footer={null}
                              width={"60vw"}
                            >
                              <div>
                                <img
                                  src={currentImage}
                                  alt="Modal View"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </Modal>
                          </>
                        ) : selectedKeys.type == "video" && Video.length > 0 ? (
                          <>
                            {/* <div className={styles.mediaContainer}> */}
                            {Video?.slice(
                              (currentPage - 1) * 9,
                              currentPage * 9
                            ).map((val) => (
                              <div
                                className={`col-md-3 col-sm-12 ${styles.videoThumbnailContainer}`}
                                onClick={() =>
                                  openModalVideoModal(val.mediaURL)
                                }
                              >
                                <video
                                  width="100%"
                                  height="250px"
                                  preload="metadata"
                                  style={{
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                  }}
                                >
                                  <source
                                    src={val.mediaURL}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                                <div className={styles.playButton}>
                                  <FontAwesomeIcon
                                    color="#000"
                                    icon={faPlay}
                                  />{" "}
                                </div>
                              </div>
                            ))}
                            {/* </div> */}
                            <Modal
                              visible={isModalVisible}
                              onCancel={closeModal}
                              footer={null}
                              width={"60vw"}
                            >
                              <Videoplayer videoURL={currentVideo} />
                            </Modal>
                          </>
                        ) : selectedKeys.type == "document" &&
                          Document.length > 0 ? (
                          <>
                            {/* <div className={styles.mediaContainer}> */}
                            {Document?.slice(
                              (currentPage - 1) * 9,
                              currentPage * 9
                            ).map((val) => (
                              <div
                                className={`col-md-3 col-sm-12 ${styles.documentThumbnailContainer}`}
                                onClick={() =>
                                  openModalDocumentModal(val.mediaURL)
                                }
                              >
                                <div className={styles.pdfPlaceholder}>
                                  📄
                                </div>
                                <div className={styles.documentName}>
                                  {val.mediaTitle}
                                </div>
                              </div>
                            ))}
                            {/* </div> */}
                            {/* <Modal
                              visible={isDocumentModalVisible}
                              onCancel={closeDocumentModal}
                              footer={null}
                              width={"60vw"}
                            >
                              <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.9.179/pdf.worker.min.js">
                                <Viewer fileUrl={currentDocument} />
                              </Worker>
                            </Modal> */}
                            <Modal
                              visible={isDocumentModalVisible}
                              onCancel={closeDocumentModal}
                              footer={null}
                              width={"80vw"} // Adjust width as needed
                              bodyStyle={{ height: "80vh", overflow: "auto" }} // Ensure modal content is scrollable
                              centered // Center the modal vertically and horizontally
                            >
                              <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                                  <Viewer
                                    fileUrl={currentDocument}
                                    defaultScale={1.5} // Set the default scale (zoom level)
                                    minScale={0.5} // Minimum scale allowed (zoom out limit)
                                    maxScale={3} // Maximum scale allowed (zoom in limit)
                                    style={{ height: '100%', width: '100%' }} // Ensure viewer takes full modal space
                                  />
                                </Worker>
                              </div>
                            </Modal>
                          </>
                        ) : (
                          <div className={styles.noDataPlaceholder}>
                            No media content available. Please check back later.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-lg-12 text-center mediasection">
                      {selectedKeys.type == "image" && Image.length > 9 ? (
                        <Pagination
                          className="darkPagination"
                          defaultCurrent={1}
                          total={Math.ceil(Image.length / 9) * 10}
                          onChange={(page) => { setCurrentPage(page); scrollToTop() }}
                        />
                      ) : selectedKeys.type == "video" && Video.length > 9 ? (
                        <Pagination
                          className="darkPagination"
                          defaultCurrent={1}
                          total={Math.ceil(Video.length / 9) * 10}
                          onChange={(page) => { setCurrentPage(page); scrollToTop() }}
                        />
                      ) : selectedKeys.type == "document" && Document.length > 9 ? (
                        <Pagination
                          className="darkPagination"
                          defaultCurrent={1}
                          total={Math.ceil(Document.length / 9) * 10}
                          onChange={(page) => { setCurrentPage(page); scrollToTop() }}
                        />
                      ) : null}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Mediademo;
