import React from "react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { routes } from "../../utills/routes";
import { useState } from "react";
import Localstorage from "../../utills/storage/Localstorage";
import useNotification from "../../useNotification"; // Import the custom hook
import { useGlobalLoader } from "../../Loader/GlobalLoaderContext"

const Profile = () => {
  const navigate = useNavigate();
  const { showNotificationWithMessage } = useNotification();
  const { showLoader, hideLoader } = useGlobalLoader();
  const [data, setData] = useState("");

  const getStudentInfoFunc = async () => {
    try {
      showLoader();
      let res = await routes.APIS.getStudentInfo(Localstorage.USER_ID.get());
      setData(res.studentInfo);
    } catch (err) {
      console.log(err);
    }
    finally {
      hideLoader(); // Hide the global loader
    }
  };

  const logout = () => {
    try {
      showLoader();
      Localstorage.clear();
      showNotificationWithMessage('Logout Successfully', 'success');
      console.log("logout");


      setTimeout(() => {
        navigate('/login');
      }, 1000);


    } catch (error) {
      showNotificationWithMessage('Something went wrong', 'error');
    }
    finally {
      hideLoader(); // Hide the global loader
    }
  };

  useEffect(() => {
    if (Localstorage.USER_ID.get()) {
      getStudentInfoFunc();
    }
  }, [Localstorage.USER_ID.get()]);

  return (
    <>
      <div className="dropdown select_language">
        <button
          type="button"
          className="nav-link"
          id="language_submenu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="far fa fa-user"></i>
          <span className="ml-2 font16">
            {data.name}
            <i className="far fa fa-angle-down ml-1"></i>
          </span>
        </button>
        <ul className="dropdown-menu" aria-labelledby="language_submenu">
          <li className="mt-2  px-3">
            <span className="ml-2  mb-2 font16"> {data.email}</span>
          </li>
          <hr />
          <li className="mt-3 px-3">
            <NavLink to="/mymedia">
              {/* <i className="far fa fa-file-image-o iconright"></i> */}
              <span className="font16">My Media</span>
            </NavLink>
          </li>
          <li className="mt-2 px-3">
            <NavLink to="/mycourses">
              {/* <i className="far far fa-book iconright"></i>{" "} */}
              <span className="font16">My Courses</span>
            </NavLink>
          </li>
          <hr />
          <li className="mt-2 px-3">
            <NavLink to="/myprofile">
              {/* <i className="far far fa-user iconright"></i>{" "} */}
              <span className="font16">My Profile</span>
            </NavLink>
          </li>
          <hr />
          <li className="mt-2 px-3 mb-2">
            <NavLink onClick={logout} to="/signup">
              {/* <i className="far fa fa-sign-out iconright"></i>{" "} */}
              <span className="font16">Logout</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Profile;
