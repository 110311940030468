import React from 'react'

const Loginbanner = (props) => {
  return (
    <>
     <section className="page_banner decoration_wrap">
          {/* <div className="container text-center">
            <h1 className="page_heading mb-2 white_text">{props.heading}</h1>
            
          </div> */}
          {/* <div className="deco_item deco_img_1" data-parallax='{"y" : -200, "smoothness": 6}'>
            <img src="../assets/images/shapes/line_shape.png" alt="Line Shape"/>
          </div>
          <div className="deco_item deco_img_2" data-parallax='{"y" : 200, "smoothness": 6}'>
            <img src="../assets/images/shapes/dot_shape2.png" alt="Line Shape"/>
          </div> */}
        </section>
    </>
  )
}

export default Loginbanner