import React, { useEffect } from 'react'
import Banner from '../../components/website/Banner'
import Images from '../../components/website/StudentDashboard/Media/Images'
import Videos from '../../components/website/StudentDashboard/Media/Videos'
import DocumentComponent from '../../components/website/StudentDashboard/Media/Document'
import { useLocation, useParams } from 'react-router-dom'; // Assuming you're using React Router
import { routes } from '../../utills/routes'
import { useState } from 'react'
import axios from 'axios'
import MediaBanner from '../../components/website/StudentDashboard/Media/MediaBanner'
import MyMediaComponent from '../../components/Mymedia/Mymedia'


const MyMedia = (props) => {

  const params = useParams();

  const location = useLocation();
  const [Image , setImages] = useState([])
  const [Video , setVideos] = useState([])
  const [Document , setDocuments] = useState([])

  const getMedia = async () => {  
    try {
      // console.log("MEDIA is calling")
      setImages([])
      setVideos([])
      setDocuments([])
      const res = await routes.APIS.getStudentMedia();
      if(res && res.length>0){
        res.map((item)=>{
          if(item.mediaType === "image"){
            setImages(prev =>[...prev,item])
          }
          if(item.mediaType === "video"){
            setVideos(prev =>[...prev,item])
          }
          if(item.mediaType === "document"){
            setDocuments(prev =>[...prev,item])
          }
        })
      }
    } catch (error) {
      console.log(error);

    }
  }

  const getMediaByCourseId = async(id) =>{
    console.log(id)
      const res =await routes.APIS.searchMedia(id);
      let images=[],videos=[],documents=[]
      if(res.data && res.data.length>0){
        res.data.map((item)=>{
          if(item.mediaType === "image"){
            // setImages(prev =>[...prev,item])
            images.push(item)
          }
          if(item.mediaType === "video"){
            // setVideos(prev =>[...prev,item])
            videos.push(item)
          }
          if(item.mediaType === "document"){
            // setDocuments(prev =>[...prev,item])
            documents.push(item)
          }
        })
        console.log(images)
        setImages(images)
        setVideos(videos)
        setDocuments(documents)
      }

  }

  useEffect(() => {
    const section = document.getElementById('mySection');
    if (section) {
      section.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [location]);

  useEffect(()=>{
    if(params && params.courseId){
      getMediaByCourseId(params.courseId);
    }
    else{
      getMedia();
    }
  },[params])

  const [bannerImage, setBannerImage] = useState([]);
  useEffect(() => {
    getBannerImage();
  }, []);

  const getBannerImage = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/MyMedia`);

      const result = response.data[0];
      setBannerImage(result);
      // console.log(result);

    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <main class="page_content">
        <section id="mySection">
        <Banner heading="My Media" img={bannerImage.bannerURL} />
          {/* {Image.length>0 ? <Images Image={Image}/> :<></>}
          {Video.length>0 ? <Videos Video={Video}/> :<></>}
          {Document.length>0 ? <DocumentComponent Document={Document}/>:<></>} */}
          <MyMediaComponent  Image={Image} Video={Video} Document={Document}/>
        </section>

      </main>
    </>
  )
}

export default MyMedia