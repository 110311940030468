import React from 'react'
import {NavLink} from "react-router-dom"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Hero = () => {
  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    autoplay:true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
  <>
      {/*  <!-- About Section - Start
        ================================================== --> */}

        <section className="about_section section_space_about bg_light">
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">
              <div className="col-12 col-lg-6 col-md-12">
                <div className="about_image about_image_res">
                <Slider {...settings}>
      <div>
      <img src="../assets/img/about_office6.png" alt="office-img"/>
      </div>
      <div>
      <img src="../assets/img/about_office7.png" alt="office-img"/>
      </div>
      <div>
      <img src="../assets/img/about_office8.png" alt="office-img"/>
      </div>
    
      
    </Slider>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="about_content about_content_margin">
                  <h2 className="about_title white_text">
                  About <span className="course_focus_text white_text">Learnovation</span>
                  </h2>
                
                  <p className="about_heading grey_text" style={{ fontFamily: 'Roboto", sans-serif !important'}}>
                  Learnovation is a team of full time Derivative and Discretionary, veteran and young traders, specializing in Index Momentum, Point and Figure, and Indicators based trading and having an expertise in directional and non-directional option strategies. Deploying money across a bouquet of strategies ranging from low frequency to medium frequency, running a combination of systems and discretionary trading, we aim to create Risk adjusted Returns from markets.
                 
                  </p>

                
                  <ul className="blog_info_list unordered_list_block wow fadeInUp" data-wow-delay=".2s">
                    <li>
                      <i className="fal fa-check"></i>
                      <span className='grey_text'>At Learnovation, we provide educational resources, training to individuals and personalized services to help them navigate the financial markets and achieve their trading and investing goals.</span>
                    </li>
                    <li>
                      <i className="fal fa-check"></i>
                      <span className='grey_text'> We do offer a variety of courses that range from beginner-level, covering the very basics of trading and investing, to advanced level for the purpose of mastering the art of trading.</span>
                    </li>
                    <li>
                      <i className="fal fa-check"></i>
                      <span className='grey_text'>Whether you are a beginner or an experienced trader, our team will provide you with the tools and resources you need to succeed in the financial markets. </span>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </section>
       {/*  <!-- About Section - End
        ================================================== --> */}
  </>
  )
}

export default Hero