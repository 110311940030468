import React from 'react'
import { NavLink } from 'react-router-dom'

const Info = () => {
  return (
    <>
      <div className="section_space_lg_contact pt-0">
        <div className="row justify-content-center">
          <div className="col col-lg-4 col-md-12 col-sm-12">
            <div className="contact_info_box" style={{ backgroundImage: `url("../assets/images/shapes/dot_shape_3.png")` }}>
              <div className="inner_wrap tilt contact_height">
                <div className="item_icon">
                  <img src="../assets/images/icons/telephone.png" className='infoimg' alt="Icon Support" />
                </div>
                <div className="item_content">
                  <h3 className="item_title">Our Telephone</h3>
                  <ul className="icon_list unordered_list_block">
                    <li><NavLink to="tel:9145001001">+91 9145001001</NavLink></li>
                                     
                    </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-lg-4 col-md-12 col-sm-12">
            <div className="contact_info_box" style={{ backgroundImage: `url("../assets/images/shapes/dot_shape_3.png")` }}>
              <div className="inner_wrap tilt contact_height">
                <div className="item_icon">
                  <img src="../assets/images/icons/mail.png" className='infoimg' alt="Icon Email" />
                </div>
                <div className="item_content">
                  <h3 className="item_title">Send us mail</h3>
                  <ul className="icon_list unordered_list_block">
                    <li><NavLink to="mailto:connect@learnovationindia.com">connect@learnovationindia.com</NavLink></li>
                    {/* <li><NavLink to="mailto:help@ourmail.com">help@ourmail.com</NavLink></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>

            <div className="col col-lg-4 col-md-12 col-sm-12">
                  <div className="contact_info_box" style={{backgroundImage:`url("../assets/images/shapes/dot_shape_3.png")`}}>
                    <div className="inner_wrap tilt">
                      <div className="item_icon">
                        <img src="../assets/images/icons/location.png" className='infoimg' alt="Icon Support"/>
                      </div>
                      <div className="item_content">
                        <h3 className="item_title">Address</h3>
                        <ul className="icon_list unordered_list_block">
                          {/* <li>Meera Nagar Garden Society , Koregaon Park, Pune, Maharashtra 411011</li> */}
                         <li>10, 11, 12, First Floor, The Hub Building, Lane 6 Corner, North Main Road, Koregaon Park, Pune 411001</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
        </div>
      </div>
    </>

  )
}

export default Info