import React from 'react'
const  history = [
    {
        id:1,
        image:"../assets/img/mission.png",
        type:"Our Vision",
        desc:"We strive to create a supportive and collaborative learning environment that fosters growth and development, and we are dedicated to staying up-to-date with the latest trends and best practices in the industry.Our goal is to help our clients achieve their financial goals and live the life they have always dreamt of. We believe that by providing top-notch education and support, we can make a meaningful impact on the lives of our students and clients and thereby contribute to a more financially literate society.", 
        alt:"Our History"
    },
    {
        id:2,
        image:"../assets/img/vision.png",
        type:"Our Mission",
        desc:"The mission of Learnovation is to empower individuals with the knowledge and skills necessary to make informed trading and investment decisions in the stock market. We provide comprehensive courses and services that cover a wide range of topics, including technical analysis, fundamental analysis, risk management, trading psychology, and many more. The goal is to help individuals develop a deep understanding of the stock market and its workings, so they can confidently navigate the market and achieve their financial goals.", 
        alt:"Our Mission"
    }
]
const Mission = () => {

  return (
   <>
   {/*   <!-- Our Goal Section - Start
        ================================================== --> */}
        <section className="goal_section section_space_lg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-4">
                <div className="section_heading vision_heading mb-lg-0">
                  <h2 className="heading_text mb-0 golden_text">
                  Empowering millions worldwide by unlocking their potential and fostering new skills.
                  </h2>
                 
                </div>
                
              </div>
              {
               history.map((vision,index)=>(
                <div className="col-12 col-lg-4 col-md-6 mt-5" key={vision.id}>
                <div className="about_item">
                  <div className="item_image">
                    <img src={vision.image} alt={vision.alt}  style={{'borderRadius':'20px'}}/>
                  </div>
                  <div className="item_content">
                    <h3 className="item_title mb-4">
                      <span className='course_focus_text white_text'> {vision.type}</span>
                   
                    </h3>
                    <p className="mb-0 grey_text">
                    {vision.desc}
                    </p>
                  </div>
                </div>
              </div>
             
               ))
              }
              
            </div>
            
          </div>
        </section>
       {/*  <!-- Our Goal Section - End
        ================================================== --> */}
   </>
  )
}

export default Mission