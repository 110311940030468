import React, { useState, useRef, useMemo, useEffect } from "react";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import { ColorPicker, notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import ServiceCard from "../../website/Service/ServiceCard";
import { set } from "react-hook-form";
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const AddService = () => {
  const { showLoader, hideLoader } = useGlobalLoader();
	const { showNotificationWithMessage } = useNotification();

  const editor = useRef(null);

  const [blogTitle, setBlogTitle] = useState("");
  const [shortInfo, setShortInfo] = useState("");
  const [blogAuthor, setBlogAuthor] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [blogThumbImage, setBlogThumbImage] = useState("");
  const [blogDetailImage, setBlogDetailImage] = useState("");
  const [error, setError] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#FFAFAF");
  const [textColor, setTextColor] = useState("#000000");
  const [imageURL,setmageURL] = useState("");

  const [textStyles, setTextStyles] = useState({
    position: "absolute",
    width: "60%",
    top: "10%",
    left: "40%",
    color: textColor,
  });

  const [imageStyles, setImageStyles] = useState({
    position: "absolute",
    top: "1%",
    left: "3%",
    width: "50%",
  });

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !blogTitle ||
      !shortInfo ||
      !blogContent ||
      !blogThumbImage ||
      !blogDetailImage
    ) {
      setError(true);
      return false;
    }

    const formData = new FormData();
    formData.append("title", blogTitle);
    formData.append("shortDescrioption", shortInfo);
    formData.append("Details", blogContent);
    formData.append("image", blogThumbImage);
    formData.append("DetailsImage", blogDetailImage);
    formData.append("backgroundColor", backgroundColor);
    formData.append("textColor", textColor);
    formData.append("textStyles", JSON.stringify(textStyles));
    formData.append("imageStyles", JSON.stringify(imageStyles));

    try {
      
      showLoader();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/AddServices`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      const data = await response.data;
      // console.log(data);
      if (response.status === 200) {
        // notification.success({
        //   message: "Service added successfully",
        // });
        // navigate("/ViewServicesList");

        showNotificationWithMessage('Service added successfully', 'success');
        setTimeout(() => {
          navigate('/ViewServicesList');
        }, 1000);
      }
    } catch (error) {
      console.error("Error adding Service:", error);
      // notification.error({
      //   message: "Error adding Service ",
      // });
			showNotificationWithMessage('Error adding Service', 'error');

    }
    finally{
      hideLoader();
    }
  };

  const editorConfig = {
    responsive: true,
    // buttons: buttons,
    // uploader: {
    //     insertImageAsBase64URI: true,
    // },
  };

  useEffect(() => {
    if(blogThumbImage){
        const fileReader = new FileReader();
        fileReader.readAsDataURL(blogThumbImage);
        fileReader.onloadend = () => {
            // console.log(fileReader.result)
            setmageURL(fileReader.result);
        }
    }

  }, [blogThumbImage]);
  return (
    <>
      <div className="content-wrapper">
        <div
          className="container-full"
          style={{ marginTop: 20}}
        >
          <section className="content">
            <div className="row">
            <div className="col-lg-12 mb-3">
                      <h5 className="box-title">Add Service</h5>
                      </div>
              <form style={{ maxWidth: "50%" }} onSubmit={handleSubmit}>
                <div className="col-lg-12 col-12 mx-auto">
                  {/* <!-- box1 --> */}

                  <div className="box">
                 
                    <div className="box-body pt-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Service Title</label>
                            <input
                              type="text"
                              name="blogTitle"
                              className="form-control"
                              placeholder="Service Title"
                              value={blogTitle}
                              onChange={(e) => {
                                setBlogTitle(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <label>Short Description</label>
                            <textarea
                              rows="2"
                              name="shortInfo"
                              className="form-control"
                              placeholder="short Info"
                              value={shortInfo}
                              onChange={(e) => {
                                setShortInfo(e.target.value);
                              }}
                            ></textarea>
                            {/* {error && !blogTitle && (
                                                            <span className="invalid-input">
                                                                Enter short Info
                                                            </span>
                                                        )} */}
                          </div>
                        </div>
                        <div className="col-md-12 mt-2">
                          <div className="row">
                            <div className="col-md-6 mt-2">
                              <div className="form-group">
                                <label>Thumbnail Background Color</label>
                                <ColorPicker
                                  format="hex"
                                  onChange={(e, hex) => setBackgroundColor(hex)}
                                  value={backgroundColor}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-3 mt-2">
                              <div className="form-group">
                                <label>width of content box (%)</label>
                                <input
                                  type="Number"
                                  name="blogTitle"
                                  className="form-control"
                                  placeholder="Service Title"
                                  value={
                                    textStyles.width
                                      ? textStyles.width.split("%")[0]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setTextStyles((prev) => {
                                      return {
                                        ...prev,
                                        width: e.target.value + "%",
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 mt-2">
                              <div className="form-group">
                                <label>Postion from Top</label>
                                <input
                                  type="Number"
                                  name="blogTitle"
                                  className="form-control"
                                  placeholder="Service Title"
                                  value={
                                    textStyles.top
                                      ? textStyles.top.split("%")[0]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setTextStyles((prev) => {
                                      return {
                                        ...prev,
                                        top: e.target.value + "%",
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-3 mt-2">
                              <div className="form-group">
                                <label>Postion from left</label>
                                <input
                                  type="Number"
                                  name="blogTitle"
                                  className="form-control"
                                  placeholder="Service Title"
                                  value={
                                    textStyles.left
                                      ? textStyles.left.split("%")[0]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setTextStyles((prev) => {
                                      return {
                                        ...prev,
                                        left: e.target.value + "%",
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- box1 --> */}

                  {/* // <!-- box2 -->  */}

                  <div className="box">
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label>Servie Thumbnail Image</label>
                            <input
                              type="file"
                              // name="blogThumbImage"
                              accept="image/*"
                              className="form-control"
                              onChange={(e) => {
                                setBlogThumbImage(e.target.files[0]);
                              }}
                            />
                            {/* {error && !image && (
                                                <span className="invalid-input">
                                                    Upload Thumbnail image
                                                </span>
                                            )} */}
                          </div>
                        </div>

                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label>Service Detail Page Image</label>
                            <input
                              type="file"
                              // name="blogThumbImage"
                              accept="image/*"
                              className="form-control"
                              onChange={(e) => {
                                setBlogDetailImage(e.target.files[0]);
                              }}
                            />
                            {/* {error && !image && (
                                                <span className="invalid-input">
                                                    Upload Detail Page Image image
                                                </span>
                                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mt-2">
                          <div className="form-group">
                            <label>width of Image (%)</label>
                            <input
                              type="Number"
                              name="blogTitle"
                              className="form-control"
                              placeholder="Service Title"
                              value={
                                imageStyles.width?
                                imageStyles.width.split("%")[0]
                                : ""
                              }
                              onChange={(e) => {
                                setImageStyles((prev) => {
                                    return {
                                        ...prev,
                                        width: e.target.value + "%",
                                    };
                                    })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 mt-2">
                          <div className="form-group">
                            <label>Postion from Top</label>
                            <input
                              type="Number"
                              name="blogTitle"
                              className="form-control"
                              placeholder="Service Title"
                              value={
                                imageStyles.top
                                  ? imageStyles.top.split("%")[0]
                                  : ""
                              }
                              onChange={(e) => {
                                setImageStyles((prev) => {
                                  return {
                                    ...prev,
                                    top: e.target.value + "%",
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 mt-2">
                          <div className="form-group">
                            <label>Postion from left</label>
                            <input
                              type="Number"
                              name="blogTitle"
                              className="form-control"
                              placeholder="Service Title"
                              value={
                                imageStyles.left
                                  ? imageStyles.left.split("%")[0]
                                  : ""
                              }
                              onChange={(e) => {
                                setImageStyles((prev) => {
                                  return {
                                    ...prev,
                                    left: e.target.value + "%",
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <label>Service Description</label>
                            {useMemo(
                              () => (
                                <JoditEditor
                                  ref={editor}
                                  config={editorConfig}
                                  value={blogContent}
                                  onChange={(newContent) =>
                                    setBlogContent(newContent)
                                  }
                                />
                              ),
                              []
                            )}
                            {/* {error && !duration && <span className='invalid-input'> Enter valid duration</span>} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- action --> */}
                  <div className="box-footer mb-4">
                    <button
                      type="submit"
                      className="btn btn-rounded btn_dark btn-outline btncourse"
                      style={{ padding: 10 }}
                    >
                      <i className="ti-save-alt"></i> Save
                    </button>
                  </div>

                  {/* <!-- action --> */}
                </div>
              </form>
              <div style={{width:"50%"}}>
                <ServiceCard
                  title={blogTitle}
                  backgrondColor={backgroundColor}
                  img={imageURL}
                  shortdescription={
                    shortInfo
                }
                  textStyles={{
                    ...textStyles,
                  }}
                  imageStyles={{
                    ...imageStyles,
                  }}
                />
              </div>
            </div>
          </section>
        </div>

        {/* <!-- form --> */}

        {/* 		
      <!-- /.content --> */}
      </div>
    </>
  );
};

export default AddService;
