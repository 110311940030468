import React, { useState, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";

import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

import useNotification from '../../../useNotification'; // Import the custom hook
import { useEffect } from "react";

const AddBlog = () => {
    const { showLoader, hideLoader } = useGlobalLoader();



    const { showNotificationWithMessage } = useNotification();

    const editor = useRef(null);

    const [blogTitle, setBlogTitle] = useState("");
    // const [shortInfo, setShortInfo] = useState("");
    const [blogAuthor, setBlogAuthor] = useState("");
    const [blogContent, setBlogContent] = useState("");
    const [blogThumbImage, setBlogThumbImage] = useState("")
    const [blogDetailImage, setBlogDetailImage] = useState("")
    const [error, setError] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {

        // showLoader(); // Show the global loader

        e.preventDefault();
        if (!blogTitle || 
            // !shortInfo || 
            !blogAuthor || 
            !blogContent || 
            !blogThumbImage || 
            !blogDetailImage) {
            setError(true)
            return false;
        }

        // setLoading(true);


        const formData = new FormData();
        formData.append("blogTitle", blogTitle);
        // formData.append("shortInfo", shortInfo);
        formData.append("blogAuthor", blogAuthor);
        formData.append("blogContent", blogContent);
        formData.append("blogThumbImage", blogThumbImage);
        formData.append("blogDetailImage", blogDetailImage);

        try {
            showLoader();

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/admin/AddBlog`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            const data = await response.data;
            // console.log(data);
            if (response.status === 200) {
                // notification.success({
                //     message: "Blog added successfully",
                // });
                // navigate("/ViewBlogList");

                showNotificationWithMessage('Blog added successfully', 'success');
                setTimeout(() => {
                    navigate('/ViewBlogList');
                }, 1000);
            }

        } catch (error) {
            console.error("Error adding blog:", error);
            // notification.error({
            //     message: "Error adding blog ",
            // });
            showNotificationWithMessage('Error adding blog', 'error');

        }
        finally {
            // setLoading(false);
            hideLoader(); // Hide the global loader
        }


    }


    // useEffect(()=>{
    //     showLoader()
    // },[])

    const editorConfig = {
        responsive: true,
        // buttons: buttons,
        // uploader: {
        //     insertImageAsBase64URI: true,
        // },
    };
    return (
        <>
            <div className="content-wrapper">
                <div
                    className="container-full"
                    style={{ marginTop: 20}}
                >
                    <section className="content">
                        <div className="row">
                        <div className="col-lg-12 mb-3">
                      <h5 className="box-title">Add Blog</h5>
                      </div>
                            
                                <div className="col-lg-12 col-12 mx-auto">
                                <form
                                onSubmit={handleSubmit}
                            >
                                    {/* <!-- box1 --> */}

                                    <div className="box">
                                      
                                        <div className="box-body pt-5">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Blog Title</label>
                                                        <input
                                                            type="text"
                                                            name="blogTitle"
                                                            className="form-control"
                                                            placeholder="Blog Title"
                                                            value={blogTitle}
                                                            onChange={(e) => {
                                                                setBlogTitle(e.target.value);
                                                            }}
                                                        />
                                                        {/* {error && !blogTitle && (
                                                            <span className="invalid-input">Enter Blog Title</span>
                                                        )} */}
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-12 mt-2">
                                                    <div className="form-group">
                                                        <label>Short Info</label>
                                                        <textarea
                                                            rows="2"
                                                            name="shortInfo"
                                                            className="form-control"
                                                            placeholder="short Info"
                                                            value={shortInfo}
                                                            onChange={(e) => {
                                                                setShortInfo(e.target.value);
                                                            }}
                                                        ></textarea>
                                                      
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- box1 --> */}


                                    {/* // <!-- box2 -->  */}

                                    <div className="box">
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-12 mt-2">
                                                    <div className="form-group">
                                                        <label> Blog Description</label>
                                                        {useMemo(
                                                            () => (
                                                                <JoditEditor
                                                                    ref={editor}
                                                                    config={editorConfig}
                                                                    value={blogContent}
                                                                    onChange={(newContent) =>
                                                                        setBlogContent(newContent)
                                                                    }
                                                                />
                                                            ),
                                                            []
                                                        )}
                                                        {/* {error && !duration && <span className='invalid-input'> Enter valid duration</span>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* box3 */}

                                    <div className="box">
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-6 mt-2">
                                                    <div className="form-group">
                                                        <label>Blog Author Name</label>
                                                        <input
                                                            type="text"
                                                            name="blogAuthor"
                                                            className="form-control"
                                                            placeholder="Blog Author"
                                                            value={blogAuthor}
                                                            onChange={(e) => {
                                                                setBlogAuthor(e.target.value);
                                                            }}
                                                        />
                                                        {/* {error && !blogAuthor && (
                                                            <span className="invalid-input">
                                                                Enter Blog Author Name
                                                            </span>
                                                        )} */}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- box4 --> */}


                                    <div className="box">
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-6 mt-2">
                                                    <div className="form-group">
                                                        <label>Blog Thumbnail Image</label>
                                                        <input
                                                            type="file"
                                                            // name="blogThumbImage"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                setBlogThumbImage(e.target.files[0]);
                                                            }}
                                                        />
                                                        {/* {error && !image && (
                                                <span className="invalid-input">
                                                    Upload Thumbnail image
                                                </span>
                                            )} */}
                                                    </div>
                                                </div>

                                                <div className="col-md-6 mt-2">
                                                    <div className="form-group">
                                                        <label>Blog Detail Page Image</label>
                                                        <input
                                                            type="file"
                                                            // name="blogThumbImage"
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                setBlogDetailImage(e.target.files[0]);
                                                            }}
                                                        />
                                                        {/* {error && !image && (
                                                <span className="invalid-input">
                                                    Upload Detail Page Image image
                                                </span>
                                            )} */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    {/* <!-- action --> */}
                                    <div className="box-footer mb-4">
                                        <button
                                            type="submit"
                                            className="btn btn-rounded  btn_dark btn-outline btncourse"
                                            style={{ padding: 10 }}
                                        >
                                            <i className="ti-save-alt"></i> Save
                                        </button>
                                    </div>

                                    {/* <!-- action --> */}
                                    </form>
                                </div>
                            
                        </div>
                    </section>
                </div>

                {/* <!-- form --> */}

                {/* 		
      <!-- /.content --> */}
            </div >

            {/* Display the loader while loading */}
            {/* {true && (
                <div className="loader-container justify-content-center">
                    <Spin />
                </div>
            )} */}


        </>
    )
}

export default AddBlog