import { Checkbox } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useNotification from '../../useNotification'; // Import the custom hook
// import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import { useGlobalLoader } from "../../Loader/GlobalLoaderContext";


const EditStudent = () => {

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const params = useParams();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [studentInfo, setStudentInfo] = useState({});
  const [error, setError] = useState();

  const getAllCourses = async () => {
    const courses = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/GetCourses`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
      },
      withCredentials: true, // Send cookies with the request
    });
    setCourses(courses.data);
  };

  const getStudentInfo = async () => {
    showLoader();
    const student = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/GetStudents/${params.id}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("jwtoken")}`,
        },
        withCredentials: true, // Send cookies with the request
      }
    );
    setStudentInfo(student.data.studentInfo);
    hideLoader();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      showLoader();
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateStudent/${params.id}`,
        studentInfo,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      if (res.status == 200) {
        showNotificationWithMessage('Student Updated Successfully ', 'success');
        setTimeout(() => {
          navigate('/Students');
        }, 1000);
        // navigate("/Students");
      }
    } catch (error) {
      console.log(error);
    }
    finally{
      hideLoader();
    }
  };

  const handleChange = (name, value) => {
    setStudentInfo({ ...studentInfo, [name]: value });
  };

  const handleCourseAdd = (id, istrue) => {
    if (istrue) {
      setStudentInfo({ ...studentInfo, courses: [...studentInfo.courses, id] });
    }
  };

  const handleCourseRemove = (id, istrue) => {
    if (!istrue) {
      const newCourses = studentInfo.courses.filter((c) => c != id);
      setStudentInfo({ ...studentInfo, courses: newCourses });
    }
  }

  useEffect(() => {
    if (params.id) {
      getStudentInfo();
      getAllCourses();
    }
  }, [params]);

  useEffect(() => {
    console.log(studentInfo);
  }, [studentInfo]);

  return (
    <>
      <div className="content-wrapper">
        <div
          className="container-full"
          style={{ marginTop: 20 }}
        >
          <section className="content">
            <div className="row">
             
                <div className="col-lg-12 col-12 mx-auto">
                <form>
                  {/* <!-- box1 --> */}

                  <div className="box">
                    
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-12 pt-3">
                          <div className="form-group">
                            <label>Student Name</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Student Name"
                              value={studentInfo.name}
                              onChange={(e) => {
                                handleChange(e.target.name, e.target.value);
                                //   setTitle(e.target.value);
                              }}
                            />
                            {/* {error && !studentInfo.name && (
                                <span className="invalid-input">Enter Student Name</span>
                              )} */}
                          </div>
                        </div>

                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              placeholder="Student Email"
                              value={studentInfo.email}
                              onChange={(e) => {
                                handleChange(e.target.name, e.target.value);
                                //   setTitle(e.target.value);
                              }}
                            />
                            {/* {error && !category && (
                                <span className="invalid-input">
                                  Enter Student Email
                                </span>
                              )} */}
                          </div>
                        </div>

                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label>Password</label>
                            <input
                              type="text"
                              name="password"
                              className="form-control"
                              placeholder="Sub Category"
                              value={studentInfo.password}
                              onChange={(e) => {
                                handleChange(e.target.name, e.target.value);
                                //   setTitle(e.target.value);
                              }}
                            />
                            {/* {error && !sub_category && (
                                <span className="invalid-input">
                                  Enter Password
                                </span>
                              )} */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-2">
                          <div className="form-group">
                            <label>Phone No</label>
                            <input
                              type="number"
                              name="phoneNumber"
                              className="form-control"
                              placeholder="Phone no"
                              value={studentInfo.phoneNumber}
                              onChange={(e) => {
                                handleChange(e.target.name, e.target.value);
                                //   setTitle(e.target.value);
                              }}
                            />
                            {/* {error && !duration && (
                                <span className="invalid-input">
                                  Enter Phone No
                                </span>
                              )} */}
                          </div>
                        </div>
                        <div className="col-md-4 mt-2">
                          <div className="form-group">
                            <label>Age</label>
                            <input
                              type="text"
                              name="age"
                              className="form-control"
                              placeholder="Age"
                              value={studentInfo.age}
                              onChange={(e) => {
                                handleChange(e.target.name, e.target.value);
                                //   setTitle(e.target.value);
                              }}
                            />
                            {/* {error && !batches && (
                                <span className="invalid-input">
                                  Enter Age
                                </span>
                              )} */}
                          </div>
                        </div>

                        <div className="col-md-4 mt-2">
                          <div className="form-group">
                            <label>City</label>
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              placeholder="City"
                              value={studentInfo.city}
                              onChange={(e) => {
                                handleChange(e.target.name, e.target.value);
                                //   setTitle(e.target.value);
                              }}
                            />
                            {/* {error && !support && (
                                <span className="invalid-input">
                               
                                  Enter  City
                                </span>
                              )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- box1 -->
  
  
               <!-- box2 --> */}

                  <div className="box">
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label>Courses purchased</label>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.7rem",
                            }}
                          >
                            {courses.map((course) => {
                              if (studentInfo?.courses?.includes(course._id)) {
                                return (
                                  <>
                                    <Checkbox
                                      checked
                                      onChange={(e) =>
                                        handleCourseRemove(
                                          course._id,
                                          e.target.checked
                                        )
                                      }
                                    >
                                      {" "}
                                      {course.title}
                                    </Checkbox>
                                  </>
                                );
                              } else {
                                return (
                                  <>
                                    <Checkbox
                                      onChange={(e) =>
                                        handleCourseAdd(
                                          course._id,
                                          e.target.checked
                                        )
                                      }
                                    >
                                      {" "}
                                      {course.title}
                                    </Checkbox>
                                  </>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- box4 --> */}

                  {/* <!-- action --> */}
                  <div className="box-footer mb-4">
                    <button
                      className="btn btn-rounded btn-primary btn-outline btncourse"
                      style={{ padding: 10 }}
                      onClick={handleUpdate}
                    >
                      <i className="ti-save-alt"></i> Save
                    </button>
                  </div>

                  {/* <!-- action --> */}


                  </form>
                </div>
            
            </div>
          </section>

          {/* <!-- form --> */}

          {/* 		
          <!-- /.content --> */}
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default EditStudent;
