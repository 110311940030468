import React from "react";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { routes } from "../../../../utills/routes";
import { useState } from "react";
import { useEffect } from "react";

const Media = () => {
  const [media, setMedia] = useState([]);

  const getMedia = async () => {
    try {
      let data = await routes.APIS.getStudentMedia();
      data = data.data
      // console.log("DTATAAA",data);
      if (data.length > 0) {
        let newData = [];
        data.map((item) => {
          if (item.mediaType == "image") {
            newData.push(item);
          }
        });
        // console.log(newData);
        setMedia(newData.splice(0,3));
      }
    } catch (error) {}
  };

  useEffect(() => {
    getMedia();
  }, []);

  return (
    <>
      <section className="course_section margin_course">
        <div className="container">
          <div className="section_title  text-center">
            <h2 className="mb-0 grey_text" data-aos="flip-up">
              <span className="d-md-block white_text">
                Most Popular <span className="course_focus_text"> Media</span>
              </span>
            </h2>
          </div>

          {/* media */}

          <Swiper
            // install Swiper modules
            // className="margin_bottom"
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={3}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1,
              },
              639: {
                slidesPerView: 2,
              },
              865: {
                slidesPerView: 4,
              },
              1000: {
                slidesPerView: 3,
              },
              1500: {
                slidesPerView: 3,
              },
              1700: {
                slidesPerView: 3,
              },
            }}
            navigation
            autoplay={true}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log("slide change")}
          >
            {media.length > 0 &&
              media.map((item, index) => {
                return (
                  <SwiperSlide>
                    <NavLink
                      to="/mymedia"
                      className="borderRadius"
                    >
                      <img className="carousel-image" src={item.mediaURL} alt="" />
                    </NavLink>
                  </SwiperSlide>
                );
              })}
   
          </Swiper>

          <div className="btn_wrap d-block text-center margin_bottom">
            <NavLink className="btn btn_primary" to="/mymedia">
              <span>
                <small>Explore All Media</small>
                <small>Explore All Media</small>
              </span>
              <i className="far fa-long-arrow-right ms-1"></i>
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
};

export default Media;
