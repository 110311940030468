import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import { notification } from 'antd';

const Testimonials = () => {
  const [testimonial, setTestimonial] = useState([]);

  useEffect(() => {
    getTestimonial();
  }, []);

  const getTestimonial = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetTestimonial`);
      const data = await response.data;
      setTestimonial(data);
    } catch (error) {
      notification.warning({
        message: 'Something went wrong',
      });
    }
  };

  return (
    <>
      <section className="testimonial_section space-md" data-aos="zoom-in">
        <div className="container">
          <div className="testimonial_carousel_box decoration_wrap">
            {testimonial.length > 0 ? (
              <OwlCarousel items={1} className="testimonial_carousel row" loop autoplay={true} data-slick='{"dots": false}'>
                {testimonial.map((testimonialItem, index) => (
                  <div className="carousel_item col" key={index}>
                    <div className="testimonial_item">
                      <div className="item_image" style={{ backgroundImage: `url("../assets/images/shapes/dot_shape_5.png")` }}>
                        {/* <img src="../assets/img/test3.png" alt="Testimonial" className='borderRadius' /> */}
                        <img src={testimonialItem.testiImageURL} alt="Testimonial" className='borderRadius' />
                      </div>
                      <div className="item_content testimonialbg">
                        <div className="section_heading">
                          <h3 className="heading_text mb-0 white_text">
                            {testimonialItem.testiTitle}
                            {/* Learner
                            <span className="heading_focus_text white_text">Stories</span> */}
                          </h3>
                        </div>

                        <p className='grey_text'>
                          {/* In lernovation learning was excellent with good interaction. Basic to advance level share market Knowledge sharing is good. Recording facility is excellent for revising. Course was practically and informative. */}
                          {testimonialItem.testiInfo}
                        </p>

                        {/* <h4 className="client_name grey_text">Akash Surwase</h4> */}
                        <h4 className="client_name grey_text">{testimonialItem.testiName}</h4>

                        {/* <span className="client_designation grey_text">Student</span> */}
                        <span className="client_designation grey_text">{testimonialItem.testiProfession}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            ) : (
              <h1 className="mt-3 text-info text-center">No Result Found</h1>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
