import React, { useEffect, useState } from "react";
import "../../../admin-assets/css/style.css";
import "../../../admin-assets/css/skin_color.css";
import "../../../admin-assets/css/vendors_css.css";
import axios from "axios";
import Cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import { notification } from "antd";

import useNotification from "../../../useNotification"; // Import the custom hook

const DashboardHome = () => {
  const { showNotificationWithMessage } = useNotification();

  const [course, setCourse] = useState([]); //=================new [
  const [student, setStudent] = useState([]);
  const [enquiry, setEnquiry] = useState([]);
  const navigate = useNavigate();
  // Protected route function

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/protected`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("jwtoken")}`,
            },

            withCredentials: true, //============new
          }
        );
        const data = await response.data;
        // console.log(data);
      } catch (error) {
        navigate("/learnovation_admin");
        // alert("Unauthorized");
      }
    };

    checkToken();
  }, []);

  //get courses
  const getCourses = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetCoursesAtAdminDash`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      setCourse(data);
    } catch (error) {
      // notification.warning({
      // 	message: "Something went wrong",
      // });
      showNotificationWithMessage("Something went wrong", "error");
    }
  };
  useEffect(() => {
    getCourses();
  }, []);

  //get students
  const getStudents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetStudentAtAdminDash`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      setStudent(data);
    } catch (error) {
      // notification.warning({
      // 	message: "Something went wrong",
      // });
      showNotificationWithMessage("Something went wrong", "error");
    }
  };

  useEffect(() => {
    getStudents();
  }, []);

  //get enquiries
  const getEnquiries = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetEnquiriesAtAdminDash`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const data = await response.data;
      setEnquiry(data);
    } catch (error) {
      // notification.warning({
      // 	message: "Something went wrong",
      // });
      showNotificationWithMessage("Something went wrong", "error");
    }
  };

  useEffect(() => {
    getEnquiries();
  }, []);

  function titleCase(str) {
    str = str.toLowerCase().split(" ");
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full" style={{ backgroundColor: "#ebeff2" }}>
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              <div className="col-12">
                <div className="box no-shadow mb-0 bg-transparent">
                  <div className="box-header no-border px-0">
                    <h4 className="box-title">Popular Courses</h4>

                    <ul className="box-controls pull-right d-md-flex d-none">
                      <li>
                        <NavLink
                          to="/CourseList"
                          className="btn btn-primary-light px-10"
                        >
                          View All
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

             


{course.length > 0 ? (
                course.map((course, index) => (
              <div className="col-lg-3 col-md-6 col-12">
                <div className="box pull-up p-3">
                  <div class="d-flex flex-row">
                    <div class="p-2">
					<img
                          src={course.image}
                          alt=""
                          className="align-self-end imgborder img-fluid"
                        />
                    </div>
                    <div class="py-2 px-2 dtitle align-items-center">{titleCase(course.title)}</div>
                  </div>
                </div>
              </div>
			  ))
              ) : (
                <h1>Loading.....</h1>
              )}

              {/* <div className="col-lg-3 col-md-6 col-12">
								<div className="box pull-up">
									<div className="box-body d-flex align-items-center">
										<img src="../../assets/images/course/4.jpg" alt="" className="align-self-end h-80 w-80" />
										<div className="d-flex flex-column flex-grow-1">
											<h5 className="box-title dtitle font-size-16 mb-2">Learno Proficient </h5>
										</div>
									</div>
								</div>
							</div> */}

              {/* <div className="col-lg-3 col-md-6 col-12">
								<div className="box pull-up">
									<div className="box-body d-flex align-items-center">
										<img src="../../assets/images/course/5.jpg" alt="" className="align-self-end h-80 w-80" />
										<div className="d-flex flex-column flex-grow-1">
											<h5 className="box-title dtitle font-size-16 mb-2">Learno Absolute</h5>
										</div>
									</div>
								</div>
							</div> */}
            </div>

            {/* student section  */}

            <div className="row fx-element-overlay">
              <div className="col-12">
                <div className="box no-shadow mb-0 bg-transparent">
                  <div className="box-header no-border px-0">
                    <h4 className="box-title">Students List</h4>
                    <ul className="box-controls pull-right d-md-flex d-none">
                      <li>
                        <NavLink
                          to="/Students"
                          className="btn btn-primary-light px-10"
                        >
                          View All
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div class="box">
                  {/* <div class="box-body">
										
									</div> */}
                  <div class="table-responsive">
                    <table
                      id="example"
                      className="table table-hover display nowrap margin-top-10 w-p100"
                    >
                      <thead className="tablehead">
                        <tr>
                          <th>S No.</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {student.length > 0 ? (
                          student.map((student, index) => (
                            <tr key={student._id}>
                              <td>{index + 1}</td>
                              <td>{student.name}</td>
                              <td>{student.email}</td>
                              <td>{student.phoneNumber}</td>
                              <td>
                                {new Date(student.date).toLocaleDateString(
                                  "en-GB"
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h1>Loading.....</h1>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Enquiry section */}

            <div className="row fx-element-overlay">
              <div className="col-12">
                <div className="box no-shadow mb-0 bg-transparent">
                  <div className="box-header no-border px-0">
                    <h4 className="box-title">Course Enquiries</h4>

                    <ul className="box-controls pull-right d-md-flex d-none">
                      <li>
                        <NavLink
                          to="/Enquiries"
                          className="btn btn-primary-light px-10"
                        >
                          View All
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div class="box">
                  {/* <div class="box-body">
										
									</div> */}
                  <div class="table-responsive">
                    <table
                      id="example"
                      class="table table-hover display nowrap margin-top-10 w-p100"
                    >
                      <thead className="tablehead">
                        <tr>
                          <th>S No.</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {enquiry.length > 0 ? (
                          enquiry.map((enquiry, index) => (
                            <tr key={enquiry._id}>
                              <td>{index + 1}</td>
                              <td>{enquiry.studentname}</td>
                              <td>{enquiry.email}</td>
                              <td>{enquiry.phone}</td>
                              <td>
                                {new Date(enquiry.createdAt).toLocaleDateString(
                                  "en-GB"
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h1>Loading.....</h1>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  );
};

export default DashboardHome;
