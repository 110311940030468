import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './ForgetPass.module.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import useNotification from '../../../useNotification'; // Import the custom hook
import axios from 'axios';
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const ForgetPassword = () => {
  const { showNotificationWithMessage } = useNotification();
  const { showLoader, hideLoader } = useGlobalLoader();

  return (
    <>
      <main className="page_content">
        <section className="page_banner decoration_wrap">
          <div className="container text-center">
            {/* <h1 className="page_heading forgot_heading mb-2 white_text">Forgot Password</h1> */}
          </div>
        </section>

        <div className="register_section section_space_lg pt-0">

          <div className="container">
            <div className="row justify-content-center">
              <div className='col-lg-12'>

              </div>
              <div className="col col-lg-5">
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = 'Please enter your email address';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                      errors.email = 'Invalid email address';
                    }
                    return errors;
                  }}

                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      showLoader();
                      const response = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/reset-password`,
                        {
                          email: values.email,
                        },
                        {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          withCredentials: true,
                        }
                      );

                      if (response.status === 200) {
                        showNotificationWithMessage(
                          'Password Reset link sent successfully, Please check your Mail',
                          'success'
                        );
                      }
                      // } else {
                      //   showNotificationWithMessage('User Does Not Exist', 'error');

                      // }
                    } catch (error) {
                      if(error?.response?.status === 400){
                        showNotificationWithMessage(
                          "User doesn't exist",
                          'error'
                        );
                      }
                      else{
                        showNotificationWithMessage(error.response.data?error.response.data:'Something went wrong', 'error');
                      }
                    }
                    finally {
                      hideLoader(); // Hide the global loader
                    }
                    setSubmitting(false);

                  }}

                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="register_form  forgot_form  pb_50">
                        <h6 className="login_heading mt-3 mb-4">Forgot Password</h6>
                        <div className="form_item">
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email"
                          />
                          <ErrorMessage name="email" className="text-danger" component="div" />
                        </div>

                        <button
                          type="submit"
                          className="btn btn_primary"
                          disabled={isSubmitting}
                        >
                          <span>
                            <small>Reset Password</small>
                            <small>Reset Password</small>
                          </span>
                          <i className="fal fa-paper-plane ml-2"></i>
                        </button>

                        <p className="mb-0 reg_btn text-center">
                          don't have an account?{' '}
                          <NavLink to="/signup" className="regcolor">
                            Register Here
                          </NavLink>
                        </p>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ForgetPassword;
