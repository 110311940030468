import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { getAdminInfo, routes } from "../../../utills/routes";
import axios from 'axios';
import Cookies from 'js-cookie';
import useNotification from '../../../useNotification'; // Import the custom hook

import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const AdminProfile = () => {
	const { showNotificationWithMessage } = useNotification();
  const { showLoader, hideLoader } = useGlobalLoader();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassward] = useState();
  const [id, setId] = useState();

  const getAdminInfo = async () => {
    try {
      showLoader();
      const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/GetAdminInfo`,

        {
          headers: {

            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      )


      const result = res.data.adminInfo[0];
      // console.log(result);

      setEmail(result.email);
      setName(result.name);
      setPassward(result.password);
      setId(result._id);


    } catch (err) {
      console.log(err);
    }
    finally {
      hideLoader();
    }
  }
  useEffect(() => {
    getAdminInfo();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      showLoader();
      // console.log(id);
      let response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/admin/UpdateAdminInfo/${id}`,
        { email, name, password },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

     if(response.status === 200){
      showNotificationWithMessage('Profile Updated successfully', 'success');

     }

    } catch (err) {
      // console.log(err);
			showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader();
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div
          className="container-full"
          style={{ marginTop: 80}}
        >
          <section className="content">
            <div className="row">
              
                <div className="col-lg-12 col-12 mx-auto">
                <form
                onSubmit={handleSubmit}
              >
                  {/* <!-- box1 --> */}

                  <div className="box">
                    {/* <div className="box-header with-border">
                      <h4 className="box-title">My Profile</h4>
                    </div> */}
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label>Email</label>
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              placeholder="Email/UserName"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />

                          </div>
                        </div>
                        <div className="col-md-6 mt-2">
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Name"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />

                          </div>
                        </div>

                        <div className="col-md-12 mt-2">
                          <div className="form-group">
                            <label>Passward</label>
                            <input
                              type="text"
                              name="password"
                              className="form-control"
                              placeholder="Passward"
                              value={password}
                              onChange={(e) => {
                                setPassward(e.target.value);
                              }}
                            />

                          </div>
                        </div>




                      </div>
                    </div>
                  </div>
                  {/* <!-- box1 --> */}



                




                  {/* <!-- action --> */}
                  <div className="box-footer mb-4">
                    <button
                      type="submit"
                      className="btn btn-rounded  btn_dark btn-outline btncourse"
                      style={{ padding: 10 }}
                    >
                      <i className="ti-save-alt"></i> Update
                    </button>
                  </div>

                  {/* <!-- action --> */}
                  </form>
                </div>
              
            </div>
          </section>
        </div>

        {/* <!-- form --> */}

        {/* 		
      <!-- /.content --> */}
      </div >


    </>
  )
}

export default AdminProfile