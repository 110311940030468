import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

const Banner = (props) => {

  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const auth = localStorage.getItem("JWT_TOKEN");
    setAuth(auth);
  }, [localStorage.getItem("JWT_TOKEN")]);

  return (
    <>

      {/*   <!-- Page Banner - Start
        ================================================== --> */}
      <section className="banner_section banner_style_4 herosection">
        <div
          className="decoration_wrap aboutbanner"
          style={{
            backgroundImage: `url(${props.img})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col col-lg-7">
                <div className="banner_content">
                  <h1
                    className="banner_title herosec wow fadeInUp"
                    data-wow-delay=".1s"
                  >
                    {props.heading}
                  </h1>
                  {/* <h6 className="text-white wow fadeInUp" data-wow-delay=".1s">
                    Home <i className="far fa-angle-right"></i> {props.heading}
                  </h6> */}
                  <h6 className="text-white wow fadeInUp" data-wow-delay=".1s">
                    
                     <NavLink className="banneranchor" to={auth ? "/home" : "/"}>Home &nbsp;</NavLink>
                     <i className="far fa-angle-right"></i> {props.heading}

                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Page Banner - End
        ================================================== --> */}
    </>
  )
}

export default Banner