import React, { useState } from "react";
import axios from "axios";
import useNotification from '../../../useNotification'; // Import the custom hook

import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const PhysicalAssesmentform = ({ title, setShow }) => {
    const { showLoader, hideLoader } = useGlobalLoader();
    const [courseTitle, setCourseTitle] = useState(title);
    const [studentName, setStudentName] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [error, setError] = useState("");

    const { showNotificationWithMessage } = useNotification();

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhone = (phone) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phone);
    };

    const isValidName = (name) => {
        const nameRegex = /^[a-zA-Z\s]*$/;
        return nameRegex.test(name);
    };

    const validateForm = () => {
        if (!studentName || !email || !phone || !city) {
            showNotificationWithMessage('All Fields Are Required', 'error');
            return false;
        }
        if (!isValidEmail(email)) {
            showNotificationWithMessage('Please enter a valid email address', 'error');
            return false;
        }
        if (!isValidPhone(phone)) {
            showNotificationWithMessage('Please enter a valid phone number', 'error');
            return false;
        }

        if (!isValidName(studentName)) {
            showNotificationWithMessage('Please enter a valid name', 'error');
            return false;
        }
        setError("");
        return true;
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        try {
            showLoader();
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/web/SendBookAssessmentMail`,
                {
                    title: courseTitle,
                    studentname: studentName,
                    email,
                    phone,
                    city,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.status === 200) {
                showNotificationWithMessage('We will contact you soon', 'success');
                setTimeout(() => {
                    setShow(false);
                }, 3000);
            }
            setShow(false);
        } catch (error) {
            console.error(error);
        }
        finally {
            hideLoader();
        }
    };

    return (
        <>
            <form>
                <div className="form_item">
                    <p className="golden_text modeltext">{title}</p>
                </div>

                <div className="form_item">
                    <input
                        type="text"
                        name="studentName"
                        placeholder="Name"
                        value={studentName}
                        onChange={(e) => setStudentName(e.target.value)}
                        required={true}
                    />
                </div>
                <div className="form_item">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="form_item">
                    <input
                        type="number"
                        name="phone"
                        placeholder="Phone No"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                <div className="form_item">
                    <input
                        type="text"
                        name="city"
                        placeholder="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>

                <button
                    type="submit"
                    className="btn btn_primary"
                    onClick={sendEmail}
                >
                    <span>
                        <small>Submit</small>
                        <small>Submit</small>
                    </span>
                    <i className="fal fa-paper-plane ml-2"></i>
                </button>
            </form >
        </>
    )
}

export default PhysicalAssesmentform
