import React from 'react'
import { NavLink } from 'react-router-dom'

const Notice = () => {
  return (
    <>
     <section className="promembership_section decoration_wrap mb-5">
          <div className="container">
            <div className="row align-items-center">
             
              <div className="col-12 col-lg-9">
                <h2 className="title_text">
                Discover new skills, techniques for a wide range of creative topics
                </h2>
              </div>
              <div className="col col-lg-3">
                <NavLink className="btn btn_primary" to="/courses">

                  <span>
                    <small>Explore All Courses</small>
                    <small>Explore All Courses</small>
                  </span>
                  <i className="far fa-long-arrow-right ms-1"></i>
                </NavLink>
              </div>
            </div>
          </div>
         
        </section>
    </>
  )
}

export default Notice