import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AppstoreOutlined } from '@ant-design/icons';
import { Divider, Menu } from 'antd';
import styles from './UIManagement.module.css';

const { SubMenu } = Menu;

function getItem(label, key, icon, onClick, children) {
  return {
    key,
    icon,
    onClick,
    children,
    label,
  };
}

const items = [
  getItem(<div ><i className="fa fa-envelope-o icolor iconpl" aria-hidden="true"></i><span className='pl-3 fontcolor'>Enquiries</span></div>, 'header', null, null, [

    getItem('Course Enquiry', 'ViewEnquiries', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/Enquiries'),
    getItem('Contact Enquiry', 'ContactEnquiries', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/ContactEnquires'),
    getItem('Survey Enquiry', 'SurveyEnquiries', <i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>, '/serveyEnquires'),
  ]),
];

const EnquiryManagement = () => {
  const navigate = useNavigate();

  return (
    <>
      <Menu
        style={{ width: "100%" ,padding:0}}
        defaultSelectedKeys={['header']}
        defaultOpenKeys={['enquiries']}
        mode="inline"
        className={styles.header}
      >
        {items.map((item) => {
          if (item.children) {
            return (
              <SubMenu key={item.key} icon={item.icon} style={{padding:0}} title={item.label} className={styles.submenu}>
                {item.children.map((child) => (
                  <Menu.Item key={child.key} icon={child.icon} onClick={() => navigate(child.onClick)}>
                    {child.label}
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={item.key} icon={item.icon} onClick={() => navigate(item.onClick)}>
                {item.label}
              </Menu.Item>
            );
          }
        })}
      </Menu>
    </>
  );
};

export default EnquiryManagement;
