import React, { useEffect, useState } from 'react'
import axios from "axios";
import Cookies from "js-cookie";
import { notification } from "antd";
import { useParams, useNavigate } from "react-router";
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const UpdateMedia = () => {

    const { showLoader, hideLoader } = useGlobalLoader();
	const { showNotificationWithMessage } = useNotification();

    // const [media, setMedia] = useState([])
    const [mediaType, setMediaType] = useState();

    const navigate = useNavigate();

    const [Mediacourses, setMediaCourses] = useState([]);

    const [mediaTitle, setMediaTitle] = useState("");
    const [mediaImage, setMediaImage] = useState("");
    const [mediaSelectCourse, setMediaSelectCourse] = useState("");
    const [mediaURL, setMediaURL] = useState("");
    const [mediaDocument, setMediaDocument] = useState();
    const [mediaVideo, setMediaVideo] = useState();

    useEffect(() => {
        getMedia();
    }, []);

    const params = useParams();


    const getMedia = async () => {
        try {
            showLoader();
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/admin/GetEditMedia/${params.id}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },

                    withCredentials: true, // Send cookies with the request
                }
            );
            const data = await response.data;

            // setMedia(data);
            // setMediaCourses(data.Mediacourses);
            setMediaTitle(data.mediaTitle);
            setMediaURL(data.mediaURL);
            setMediaSelectCourse(data.mediaSelectCourse);
            setMediaType(data.mediaType);



            if (!response.status === 200) {
                const error = new Error(response.error);
                throw error;
            }
        } catch (error) {
            console.error("Error fetching media:", error);

        }
        finally {
            hideLoader();
        }
    };

    useEffect(() => {
        getMediaCourses();
    }, []);

    const getMediaCourses = async () => {
        try {
            showLoader();
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/admin/GetMediaCourses`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },

                    withCredentials: true, // Send cookies with the request
                }
            );
            const result = response.data;
            // console.log(result);
            setMediaCourses(result);
        } catch (error) {
            console.error("Error fetching Courses:", error);
        }
        finally {
            hideLoader();
        }
    };

    const updateMedias = async (e) => {
        e.preventDefault();
        // console.log(mediaSelectCourse)
        const formData = new FormData();
        formData.append("mediaTitle", mediaTitle);
        formData.append("mediaSelectCourse", mediaSelectCourse);
        formData.append("mediaURL", mediaURL);

        if (mediaType === "image") {
            formData.append("mediaImage", mediaImage);
        }
        else if (mediaType === "document") {
            formData.append("mediaDocument", mediaDocument);
        }
        else if (mediaType === "video") {
            formData.append("mediaVideo", mediaVideo);
        } else {
            alert("something went wrong")
        }



        try {
            showLoader();
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateMedia/${params.id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );


            // notification.success({
            //     message: "media Updated successfully",
            // });
          
            // { mediaType === "image" ? navigate("/MediaImage") : mediaType === "video" ? navigate("/MediaVideo") : navigate("/MediaDocs") }
            
            showNotificationWithMessage('media Updated successfully', 'success');
			setTimeout(() => {
                { mediaType === "image" ? navigate("/MediaImage") : mediaType === "video" ? navigate("/MediaVideo") : navigate("/MediaDocs") }
			}, 1000);


        } catch (error) {
            console.error(error);
			showNotificationWithMessage('Something went wrong', 'error');

        }
        finally {
            hideLoader();
        }

    }
    return (
        <>
            <div className="content-wrapper">

                <div className="container-full" style={{ marginTop: 40 }}>

                    <section className="content">
                        <div className="row">
                        <div className="col-lg-12 mb-3">
                      <h5 className="box-title"> <h4 className="box-title">
                                            {mediaType === "image" ? "Update Image" : mediaType === "video" ? "Update Video" : "Update Document"}
                                            {/* Add Images */}
                                        </h4></h5>
                      </div>
                            {/* <form>/ */}
                            <div className="col-lg-12 col-12 mx-auto">
                                {/* <!-- box1 --> */}

                                <div className="box pt-4">
                                    {/* <div className="box-header with-border">
                                        <h4 className="box-title">
                                            {mediaType === "image" ? "Update Image" : mediaType === "video" ? "Update Video" : "Update Document"}
                                       
                                        </h4>
                            
                                    </div> */}
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Title</label>
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        className="form-control"
                                                        placeholder="title"
                                                        value={mediaTitle}
                                                        onChange={(e) => setMediaTitle(e.target.value)}
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Select Course</label>

                                                    <select
                                                        value={mediaSelectCourse}
                                                        onChange={(e) =>
                                                            setMediaSelectCourse(e.target.value)
                                                        }
                                                        className="form-control"
                                                    >
                                                        <option selected value="">
                                                            All
                                                        </option>
                                                        {Mediacourses.map((media) => (
                                                            <option key={media.title} value={media.title}>
                                                                {media.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                          
                                                <div className="col-md-12">

                                                    <div className="form-group">
                                                        <label>
                                                            {mediaType === "image" ? "Image" : mediaType === "video" ? "Video" : "Document (.pdf only)"}
                                                            {/* Media Image */}
                                                        </label>
                                                        <input
                                                            type="file"
                                                            accept={mediaType === "image" ?
                                                                "image/*" : mediaType === "video" ? "video/*" : ".pdf"}
                                                            name="mediaImage    "
                                                            className="form-control"
                                                            placeholder="Title"
                                                            onChange={(e) => {
                                                                {
                                                                    mediaType === "image" ?
                                                                        setMediaImage(e.target.files[0])
                                                                        :
                                                                        mediaType === "video" ?
                                                                            setMediaVideo(e.target.files[0])
                                                                            :
                                                                            setMediaDocument(e.target.files[0])
                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                           


                                            



                                        </div>
                                    </div>
                                </div>
                                {/* <!-- box1 -->
    
                      
    
    
                    
    
    
                      {/* <!-- action --> */}
                                <div className="box-footer mb-4">
                                    <button
                                        type="submit"
                                        className="btn btn-rounded  btn_dark btn-outline btncourse"
                                        style={{ padding: 10 }}
                                        onClick={updateMedias}
                                    >
                                        <i className="ti-save-alt"></i> Update
                                    </button>
                                </div>

                                {/* <!-- action --> */}
                            </div>
                            {/* </form> */}
                        </div>
                    </section>

                    {/* <!-- form --> */}

                    {/* 		
            <!-- /.content --> */}
                </div>
            </div>
            {/* <!-- /.content-wrapper --> */}
        </>
    );
};



export default UpdateMedia