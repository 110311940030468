import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Assuming you're using React Router
import Banner from "../../components/website/Banner";
import axios from "axios";
import { useState } from "react";
import useNotification from '../../useNotification';
import { Image } from 'antd';

const Gallery = () => {
    const location = useLocation();

    useEffect(() => {
        const section = document.getElementById("mySection");
        if (section) {
            section.scrollIntoView({ behavior: "auto", block: "start" });
        }
    }, [location]);
    const { showNotificationWithMessage } = useNotification();
    const [bannerImage, setBannerImage] = useState([]);
    const [gallery, setGallery] = useState([]);

    useEffect(() => {
        getBannerImage();
    }, []);

    const getBannerImage = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/Gallery`);
            const result = response.data[0];
            setBannerImage(result);
        } catch (error) {
            console.error(error);
        }
    };

    const getGalleryweb = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/getGalleryWebsite`);
            const data = await response.data.data;
            // console.log(data)
            setGallery(data);
        } catch {
            showNotificationWithMessage('Something went wrong', 'error');
        }
    }

    useEffect(() => {
        getGalleryweb();
    }, []);


    return (
        <>
            <main className="page_content">
                <section id="mySection">

                    <Banner heading='Gallery' img={bannerImage?.bannerURL} />
                    <div className="container p-5">
                        <div className="row">
                            {gallery.length > 0 ? (
                                gallery.map((image) => (
                                    <div
                                        className="col-12 col-lg-4 col-md-6 mt-5 col-sm-12 blogpage"
                                        key={gallery.id}
                                    >
                                        <Image
                                            width={300}
                                            height={300}
                                            style={{
                                                objectFit: "cover",
                                                borderRadius: '25px',
                                                border: '1px solid #585858',
                                                boxShadow: 'rgb(255 255 255 / 25%) 0px 2px 5px -1px, rgb(255 255 255 / 26%) 0px 1px 3px -1px'

                                            }}
                                            src={image.galleryImageURL}
                                        />
                                    </div>
                                ))
                            ) : (
                                <h1 className="mt-3 text-info text-center">
                                    No Result Found
                                </h1>
                            )}

                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Gallery;
