// import React from 'react'

// import Media from './Media'

// const MediaDocs = () => {
//     return (
//         <>
//             <Media mediaType={"document"} />

//         </>
//     )
// }

// export default MediaDocs


import { notification } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import AddMedia from './AddMediaImage';
import useNotification from '../../../useNotification'; // Import the custom hook
import Swal from 'sweetalert2';
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";


const MediaDocs = () => {

    const { showLoader, hideLoader } = useGlobalLoader();
    const [media, setMedia] = useState([])
    const [mediaType, setMediaType] = useState("document");

    const { showNotificationWithMessage } = useNotification();

    // const [ setMediaType] = useState();



    useEffect(() => {
        getMedia();
    }, [mediaType]);

    // console.log(mediaType);

    const getMedia = async () => {
        try {
            showLoader();
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/admin/GetMediaByType/${mediaType}`,
                {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },

                    withCredentials: true, // Send cookies with the request
                }
            );
            const data = await response.data;
            setMedia(data);
            if (!response.status === 200) {
                const error = new Error(response.error);
                throw error;
            }
        } catch (error) {
            console.error("Error fetching media:", error);

        }
        finally {
            hideLoader();
        }
    };

    //delete media
    const deleteMedia = async (id) => {
        try {
            // Show the confirmation popup
            const confirmed = await Swal.fire({
                title: 'Are you sure?',
                text: 'Are you sure you want to delete this Media?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
            });

            if (!confirmed.isConfirmed) {
                return; // If user cancels, do nothing
            }

            showLoader();
            const response = await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteMedia/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('jwtoken')}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );

            if (response.data) {
                getMedia();
                showNotificationWithMessage('Media is deleted', 'success');
            }
        } catch (error) {
            showNotificationWithMessage('Something went wrong', 'error');
        }
        finally {
            hideLoader();
        }
    };



    return (
        <>
            <div className="content-wrapper">
                <div className="container-full">
                    {/* <!-- Content Header (Page header) --> */}
                    <div className="content-header" style={{ marginTop: 30 }}>
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h3 className="page-title">Document List</h3>
                            </div>
                            <div className="mr-end">
                                <NavLink
                                    className="waves-effect waves-light btn-block btn btn_dark addcourse"
                                    to="/AddMediaDocs"

                                >
                                    <i className="fa fa-plus mr-15"></i>Add New Document
                                </NavLink>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Main content --> */}
                    <section className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="box" style={{ marginTop: 50 }}>
                                    <div className="box-body">
                                        <div className="table-responsive">
                                            <table
                                                id="productorder"
                                                className="table table-hover no-wrap product-order"
                                                data-page-size="10"
                                            >
                                                {/* <thead> */}
                                                <thead>
                                                    <tr>
                                                        <th>S No.</th>
                                                        <th>Media Title</th>
                                                        <th>Category</th>
                                                        <th>Document</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {media.length > 0 ? (
                                                        media.map((media, index) => (
                                                            <tr key={media._id}>
                                                                <td >{index + 1}</td>
                                                                <td>{media.mediaTitle}</td>
                                                                <td>{media.mediaSelectCourse ? media.mediaSelectCourse : "All"}</td>


                                                                <td>
                                                                    {media.mediaType}
                                                                </td>

                                                                <td>
                                                                    <NavLink
                                                                        to={"/UpdateMedia/" + media._id}
                                                                        className="btn btn-circle btn-info btn-xs mr-10"
                                                                        data-toggle="tooltip"
                                                                        data-original-title="Edit"
                                                                    >
                                                                        <i class="ti-marker-alt"></i>
                                                                    </NavLink>

                                                                    <NavLink
                                                                        to="javascript:void(0)"
                                                                        className="btn btn-circle btn-danger btn-xs"
                                                                        title=""
                                                                        data-toggle="tooltip"
                                                                        data-original-title="Delete"
                                                                        onClick={() => {
                                                                            deleteMedia(media._id);
                                                                        }}
                                                                    >
                                                                        <i class="ti-trash"></i>
                                                                    </NavLink>



                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <h1>Loading.....</h1>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- /.content --> */}
                </div>
            </div>
        </>
    )
}

export default MediaDocs
