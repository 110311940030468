import React from 'react'

const Content = (props) => {
  const { HTML } = props
  return (
    <>
      <span style={{color:"#ffff"}} dangerouslySetInnerHTML={{ __html: HTML }} >
      </span>
    </>
  )
}

export default Content