import React, { useEffect, useState } from "react";
import { routes } from "../utills/routes";
import { Link } from "react-router-dom";

export default function Sitemap() {
    function titleCase(str) {
        return str
            .toLowerCase()
            .split(" ")
            .map(function (word) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ");
    }
  const [urls, seturls] = useState([]);
  useEffect(() => {
    async function fetchRoutes() {
      const routes4 = await routes.APIS.getAllRoutes();
      let servicesRoutes = routes4.filter((route) =>
        route.includes("service/")
      );
        let coursesRoutes = routes4.filter((route) => route.includes("course/"));
        let blogRoutes = routes4.filter((route) => route.includes("blog/"));

        let newArr = routes4.filter(
            (route) =>
                !route.includes("service/") &&
                !route.includes("course/") &&
                !route.includes("blog/")
            );
            newArr.splice(2, 0, ...servicesRoutes);
            let coursesIndex = newArr.indexOf("courses");
            newArr.splice(coursesIndex+1, 0, ...coursesRoutes);
            let blogIndex = newArr.indexOf("blog");
            newArr.splice(blogIndex+1, 0, ...blogRoutes);


      seturls(newArr);
    }
    fetchRoutes();
  }, []);

  return (
    <div style={{ margin: "3rem", marginTop: "10rem" }}>
      <h1 style={{ color: "white",cursor:"pointer" }}>Sitemap</h1>
      <ul>
        {urls.map((url) => {
          return (
            <li>
              {url === "" ? (
                <>
                  <Link style={{ color: "white",cursor:"pointer",fontSize:'23px' }} to={`/`}>
                    Home
                  </Link>
                  <br />
                </>
              ) : url.includes("blog/") ? (
                <>
                  <Link style={{ color: "white",cursor:"pointer",marginLeft:'20px' }} to={`/${url}`}>
                    - {titleCase(url.split("/")[1].replace(/-/g, " "))}
                  </Link>
                  <br />
                </>
              ) : url.includes("service/") ? (
                <>
                  <Link style={{ color: "white",cursor:"pointer",marginLeft:'20px' }} to={`/${url}`}>
                   - {titleCase(url.split("/")[1].replace(/-/g, " "))}
                  </Link>
                  <br />
                </>
              ) : url.includes("course/") ? (
                <>
                  <Link style={{ color: "white",cursor:"pointer" ,marginLeft:'20px'}} to={`/${url}`}>
                    - {titleCase(url.split("/")[1].replace(/-/g, " "))}
                  </Link>
                  <br />
                </>
              ) : (
                <>
                  <Link style={{ color: "white",cursor:"pointer",fontSize:'23px' }} to={`/${url}`}>
                    {titleCase(url)}
                  </Link>
                  <br />
                </>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
