import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useNotification from '../../../useNotification'; // Import the custom hook


const Servicedemo = () => {

  const [serviceImg, setServiceImg] = useState([]);
  const { showNotificationWithMessage } = useNotification();


  const getServiceImg = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/web/GetServiceImage`);
      const data = await response.data[0];
      setServiceImg(data);
    } catch (error) {
      showNotificationWithMessage('Something went wrong', 'error');

    }
  };


  useEffect(() => {
    getServiceImg();
  }, []);

  return (
    <>
      <main className="page_content">
        <section className="course_details_section homemargin">
          <div className="details_content decoration_wrap">
            <div className="container">
              <div className="row align-items-center justify-content-lg-between hero_section">
                <div className="col-12 col-lg-6">
                  <div className="hero_content">
                    {/* <h1 className="cd-headline clip is-full-width">
                      <span className="d-block mb-2 white_text">
                        Service you deserve
                      </span>
                      <span className="cd-words-wrapper">
                        <b className="is-visible golden_text service_text">Learnovation Mentoring<br></br> Programme</b>

                        <b className="is-hidden grey_text service_text">
                          Financial Products
                        </b>
                        <b className="is-hidden grey_text service_text">Online Trader’s Desk</b>
                      </span>
                    </h1> */}
                    <h2 className="grey_text servicetext">
                      {/* We invite you to join us for an upcoming intro class. Our
                      experienced instructors and staff are here to help you
                      build skills and confidence as you progress in your
                      financial education. Let's scroll down for more info. 👇 */}

                      {/* Elevate experience of your vision turning into reality with our top-notch <span ></span> */}


                      {serviceImg.short_desc}

                    </h2>
                  
                    <h2 className="golden_text servicemb servicetext">"TAILOR-MADE SERVICES"</h2>
                  </div>
                </div>
                <div className="col-12 offset-md-1 col-lg-5">
                  <img
                    src={serviceImg.servicePageURL}
                    className="serviceradius"
                  ></img>
                </div>
              </div>
            </div>

            {/* <div
              className="deco_item deco_img_1"
              data-parallax='{"y" : -200, "smoothness": 6}'
            >
              <img
                src="assets/images/shapes/line_shape.png"
                alt="Line Shape Image"
              />
            </div> */}
            {/* <div class="deco_item deco_img_2" data-parallax='{"y" : 200, "smoothness": 6}'>
      <img src="assets/images/shapes/dot_shape_2.png" alt="Line Shape Image"/>
    </div> */}
          </div>
        </section>
      </main>
    </>
  );
};

export default Servicedemo;
