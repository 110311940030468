import React, { useEffect } from "react";
import Profile from "../../components/website/StudentDashboard/Profile/Profile";
import { useLocation } from "react-router-dom"; // Assuming you're using React Router

const MyProfile = () => {
  const location = useLocation();

  useEffect(() => {
    const section = document.getElementById("mySection");
    if (section) {
      section.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, [location]);

  return (
    <main className="page_content">
      <section id="mySection">
        <Profile />
      </section>
    </main>
  );
};

export default MyProfile;
