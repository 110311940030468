import { notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageCroper from "../../ImageCroper/ImageCroper";
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const UpdateBanner = () => {
  const { showLoader, hideLoader } = useGlobalLoader();
	const { showNotificationWithMessage } = useNotification();

  //get banner by id
  const [bannerPage, setBannerPage] = useState();
  const [bannerImage, setBannerImage] = useState();
  const [cropingImage, setCropingImage] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(4.8);
  const [cropPosterImage, setPosterImageToCrop] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageURL, setImageURL] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  const getBannerImage = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetEditBanner/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      const result = response.data;
      setBannerPage(result.bannerPage);
      setBannerImage(result.bannerURL);
    } catch (error) {
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getBannerImage();
  }, []);

  //update banner
  const updateBanner = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("bannerPage", bannerPage);
    formData.append("bannerImage", bannerImage);

    try {
      showLoader();
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateBanner/${params.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      // console.log(response.data);
      // notification.success({
      //   message: "Banner Image Updated Successfully",
      // });
      // navigate("/ViewBanner");

      showNotificationWithMessage('Banner Image Updated Successfully', 'success');
      setTimeout(() => {
        navigate('/ViewBanner');
      }, 1000);
    } catch (error) {
      // notification.warning({
      //   message: "something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');

    }
    finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (bannerImage?.size) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(bannerImage);
      fileReader.onloadend = () => {
        setImageURL(fileReader.result);
      };
    }
  }, [bannerImage])


  return (
    <>
      <div className="content-wrapper">
        <div className="container-full" style={{ marginTop: 20 }}>
          <section className="content">
            <div className="row">
            <div className="col-lg-12 mb-3">
                      <h5 className="box-title">Update Banner Image</h5>
                      </div>
              {/* <form>/ */}
              <div className="col-lg-12 col-12 mx-auto">
                {/* <!-- box1 --> */}

                <div className="box">

                  <div className="box-body pt-4">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Select Page</label>
                          <select
                            className="form-control"
                            value={bannerPage}
                            onChange={(e) => setBannerPage(e.target.value)}
                          >
                            <option>Home</option>
                            <option></option>
                            <option>About</option>
                            <option>Course</option>
                            <option>Blog</option>
                            <option>Contact</option>
                            <option>MyMedia</option>
                            <option>MyCourses</option>
                            <option>Blogdetail</option>
                            <option>Servicedetail</option>
                            <option>CourseDetail</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Banner Image
                            <span className="text-danger ml-1">
                              (Size - 1920 x 300)*
                            </span>
                          </label>
                          <div className="row">
                          <div className="col-lg-6 mt-3">
                          <input
                            type="file"
                            className="form-control"
                            // value={bannerImage}
                            accept="image/*"
                            onChange={(e) => {
                              // setCropingImage(e);
                              setBannerImage(e.target.files[0]);
                              // setShowModal(true);
                            }}
                          />
                          </div>
                          <div className="col-lg-6 mt-3">
                          <img src={imageURL ? imageURL : bannerImage} alt="" width={160} />
                          </div>
                          </div>
                        
                        
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- box1 -->
                    
                      {/* <!-- action --> */}
                <div className="box-footer mb-4">
                  <button
                    type="submit"
                    className="btn btn-rounded btn_dark btn-outline btncourse"
                    onClick={updateBanner}
                    style={{ padding: 10 }}
                  >
                    <i className="ti-save-alt"></i> Update
                  </button>
                </div>

                {/* <!-- action --> */}
              </div>
              {/* </form> */}
            </div>
          </section>
          {/* <ImageCroper
            aspectRatio={aspectRatio}
            imagetoCrop={cropingImage}
            showModal={showModal}
            setImage={setBannerImage}
            setShowModal={setShowModal}
          /> */}
          {/* <!-- form --> */}

          {/* 		
            <!-- /.content --> */}
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default UpdateBanner;
