import React, { useEffect, useReducer, useState } from "react";
import Slider from "../../components/website/Home/Slider";
import Service from "../../components/website/Home/Service";
import Membership from "../../components/website/Home/Membership";
import Courses from "../../components/website/Home/Courses";
import Counter from "../../components/website/Home/Counter";
import AboutCompany from "../../components/website/Home/AboutCompany";

import { useLocation } from "react-router-dom"; // Assuming you're using React Router
import { Modal } from "antd";
import axios from "axios";

const HomeWeb = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popup, setPopup] = useState(null);

  useEffect(() => {
    const section = document.getElementById("mySection");
    if (section) {
      section.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, [location]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // getPopupWebsite
  useEffect(() => {
    const GetPopupWebsite = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/getPopupWebsite`);
        const data = response.data;
        if (data && data.length > 0) {
          setPopup(data[0]); // Set the first popup from the response
          showModal(); // Open the modal
        }
      } catch (error) {
        console.error("Error fetching popup:", error);
      }
    };
    GetPopupWebsite();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleCancel()
    }, 5000);
  }, []);

  return (
    <>
      {/*   <!-- Main Body - Start
      ================================================== --> */}
      <main className="page_content">
        <section id="mySection">
          {popup && (
            <Modal
              open={isModalOpen && popup}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              {popup && <img src={popup.popupURL} alt="Popup" />}
            </Modal>
          )}
          {/* <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <img src="/assets/img/event/event.jpg" />
          </Modal> */}
          <Slider />
          <Service />
          <Courses marginBottom="90px" />
          <Counter />
          <AboutCompany />
          <Membership />
        </section>
      </main>
      {/*   <!-- Main Body - End
      ================================================== --> */}
    </>
  );
};

export default HomeWeb;
