import React, { useEffect } from 'react'
import Banner from '../../components/website/Banner'
import Blogpage from '../../components/website/Blogs/Blogpage'

import { useLocation } from 'react-router-dom'; // Assuming you're using React Router
import axios from 'axios';
import { useState } from 'react';

const Blog = () => {

  const location = useLocation();
  useEffect(() => {
    const section = document.getElementById('mySection');
    if (section) {
      section.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [location]);

  const [bannerImage, setBannerImage] = useState([]);
  useEffect(() => {
    getBannerImage();
  }, []);

  const getBannerImage = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/Blog`);

      const result = response.data[0];
      setBannerImage(result);
      // console.log(result);

    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <main className="page_content">
        <section id="mySection">
          <Banner heading='Blog' img={bannerImage.bannerURL} />
          <Blogpage />
        </section >

      </main>

    </>
  )
}

export default Blog