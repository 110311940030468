import { notification } from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";


const UpdateServiceImg = () => {

    const { showLoader, hideLoader } = useGlobalLoader();

    const { showNotificationWithMessage } = useNotification();

    const [serviceImage, setServiceImage] = useState(null);
    const [short_desc, setShort_desc] = useState("");

    const params = useParams();
    const navigate = useNavigate();

    const getServiceImg = async () => {
        try {
            showLoader();

            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/admin/GetEditServiceImage/${params.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            const result = response.data;
            setServiceImage(result.servicePageURL);
            setShort_desc(result.short_desc);
        } catch (error) {
            // notification.warning({
            //     message: "something went wrong",
            // });
            showNotificationWithMessage('Something went wrong', 'error');

        }
        finally {
            hideLoader();
        }
    };

    useEffect(() => {
        getServiceImg();
    }, []);

    //update service image
    const updateServiceImg = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("serviceImage", serviceImage);
        formData.append("short_desc", short_desc);
        try {
            showLoader();

            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/admin/UpdateServiceImage/${params.id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                    },
                    withCredentials: true, // Send cookies with the request
                }
            );
            // notification.success({
            //     message: "Service Image Updated Successfully",
            // });
            // navigate("/ViewServiceImg");
            showNotificationWithMessage('Service Image &content Updated Successfully', 'success');
            setTimeout(() => {
                navigate('/ViewServiceImg');
            }, 1000);

        } catch (error) {
            // notification.warning({
            //     message: "something went wrong",
            // });
            showNotificationWithMessage('Something went wrong', 'error');

        }
        finally {
            hideLoader();
        }
    }


    return (
        <>
            <div className="content-wrapper">
                <div className="container-full" style={{ marginTop: 20 }}>
                    <section className="content">
                        <div className="row">
                            <div className="col-lg-12 mb-3">
                                <h5 className="box-title">Update Service Page Content & Image</h5>
                            </div>
                            {/* <form>/ */}
                            <div className="col-lg-12 col-12 mx-auto">
                                {/* <!-- box1 --> */}

                                <div className="box">

                                    <div className="box-body pt-4">
                                        <div className="row">

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>
                                                        short Description
                                                    </label>
                                                    <textarea
                                                        rows="2"
                                                        name="short_desc"
                                                        className="form-control"
                                                        placeholder="short description"
                                                        value={short_desc}
                                                        onChange={(e) => {
                                                            setShort_desc(e.target.value);
                                                        }}
                                                    ></textarea>
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        Service Page Image
                                                        <span className="text-danger ml-1">
                                                            {/* (Size - 1920 x 400)* */}
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        name="serviceImage"
                                                        onChange={(e) => {
                                                            setServiceImage(e.target.files[0]);
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <img src={serviceImage} alt="" width={80} />
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                {/* <!-- box1 -->
        
          {/* <!-- action --> */}
                                <div className="box-footer mb-4">
                                    <button
                                        type="submit"
                                        className="btn btn-rounded btn_dark btn-outline btncourse"
                                        onClick={updateServiceImg}
                                        style={{ padding: 10 }}
                                    >
                                        <i className="ti-save-alt"></i> Save
                                    </button>
                                </div>

                                {/* <!-- action --> */}
                            </div>
                            {/* </form> */}
                        </div>
                    </section>

                    {/* <!-- form --> */}

                    {/* 		
<!-- /.content --> */}
                </div>
            </div>
            {/* <!-- /.content-wrapper --> */}
        </>
    )
}

export default UpdateServiceImg