import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const ViewServiceImg = () => {

  const { showLoader, hideLoader } = useGlobalLoader();

    const [serviceImg, setServiceImg] = useState([]);

    useEffect(() => {
        getServiceImg();
    }, []);

    const getServiceImg = async () => {
        try {
      showLoader();

            const response = await axios
                .get(`${process.env.REACT_APP_BACKEND_URL}/admin/GetServiceImage`,
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                        },

                        withCredentials: true, // Send cookies with the request
                    }
                );
            const data = await response.data;
            setServiceImg(data);
          

            if (!response.status === 200) {
                const error = new Error(response.error);
                throw error;
            }

        } catch (error) {
            console.error("Error fetching Service Image:", error);
        }
        finally {
            hideLoader();
        }
    }



    return (
        <>
            <div className="content-wrapper">
                <div className="container-full">
                    {/* <!-- Content Header (Page header) --> */}
                    <div className="content-header" style={{ marginTop: 30 }}>
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h3 className="page-title">Media List</h3>
                            </div>
                            {/* <div className="mr-end">
                                <NavLink
                                    className="waves-effect waves-light btn-block btn btn-primary addcourse"
                                    to="/AddServiceImg"
                                >
                                    <i className="fa fa-plus mr-15"></i>Add Service Image
                                </NavLink>
                            </div> */}
                        </div>
                    </div>

                    {/* <!-- Main content --> */}
                    <section className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="box" style={{ marginTop: 50 }}>
                                    <div className="box-body">
                                        <div className="table-responsive">
                                            <table
                                                id="productorder"
                                                className="table table-hover no-wrap product-order"
                                                data-page-size="10"
                                            >
                                                {/* <thead> */}
                                                <thead>
                                                    <tr>
                                                        <th>S No.</th>
                                                        <th>Service Image</th>
                                                        <th>Short Desc</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {serviceImg.length > 0 ? (
                                                        serviceImg.map((serviceImg, index) => (
                                                            <tr key={serviceImg._id}>
                                                                <td>{index + 1}</td>

                                                                <td>
                                                                    <img
                                                                        src={serviceImg.servicePageURL}
                                                                        alt=""
                                                                        width={80}
                                                                    />
                                                                </td>
                                                                <td>{serviceImg.short_desc}</td>

                                                                <td>
                                                                    <NavLink
                                                                        to={"/UpdateServiceImg/" + serviceImg._id}
                                                                        className="btn btn-circle btn-info btn-xs mr-10"
                                                                        data-toggle="tooltip"
                                                                        data-original-title="Edit"
                                                                    >
                                                                        <i class="ti-marker-alt"></i>
                                                                    </NavLink>

                                                                    {/* <NavLink
                                                                        to="javascript:void(0)"
                                                                        className="btn btn-circle btn-danger btn-xs"
                                                                        title=""
                                                                        data-toggle="tooltip"
                                                                        data-original-title="Delete"
                                                                        onClick={() => {
                                                                            deleteBanner(bannerimg._id);
                                                                        }}
                                                                    >
                                                                        <i class="ti-trash"></i>
                                                                    </NavLink> */}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <h1>Loading.....</h1>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- /.content --> */}
                </div>
            </div>
        </>
    )
}

export default ViewServiceImg