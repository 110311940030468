import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { notification } from "antd";
import { useNavigate } from "react-router";
import ImageCroper from "../../ImageCroper/ImageCroper";
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const AddBanner = () => {
  const { showLoader, hideLoader } = useGlobalLoader();
	const { showNotificationWithMessage } = useNotification();

  const navigate = useNavigate();
  const [bannerPage, setBannerPage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [cropingImage, setCropingImage] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(4.8);
  const [cropPosterImage, setPosterImageToCrop] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const addBanner = async () => {
    if (!bannerPage || !bannerImage) {
      // notification.error({
      //   message: "All fields are required",
      // });
      showNotificationWithMessage('All fields are required', 'error');

      return;
    }
    const formData = new FormData();
    formData.append("bannerPage", bannerPage);
    formData.append("bannerImage", bannerImage);

    try {
      
      showLoader();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/AddBanner`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      // console.warn(response.data);

      if (response.status === 200) {
        // notification.success({
        //   message: "Banner added successfully",
        // });
        // navigate("/ViewBanner");

        showNotificationWithMessage('Banner added successfully', 'success');
        setTimeout(() => {
          navigate('/ViewBanner');
        }, 1000);
      }
    } catch (error) {
      console.error("Error adding media:", error);
      // notification.error({
      //   message:
      //     "Error adding banner or Please check Page Name if already exist",
      // });
      showNotificationWithMessage('Error adding banner or Please check Page Name if already exist', 'error');
    }
    finally {
      hideLoader();
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="container-full" style={{ marginTop: 20 }}>
          <section className="content">
            <div className="row">
            <div className="col-lg-12 mb-3">
                      <h5 className="box-title">Add Banner Image</h5>
                      </div>
              {/* <form>/ */}
              <div className="col-lg-12 col-12 mx-auto">
                {/* <!-- box1 --> */}

                <div className="box">
                 
                  <div className="box-body pt-4">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Select Page</label>
                          <select
                            className="form-control"
                            value={bannerPage}
                            onChange={(e) => setBannerPage(e.target.value)}
                          >
                            <option selected value="">
                              --Select Page--
                            </option>
                             <option>Home</option>
                            <option>StudentDashboard</option>
                            <option>About</option>
                            <option>Course</option>
                            <option>Blog</option>
                            <option>Contact</option>
                            <option>MyMedia</option>
                            <option>MyCourses</option>
                            <option>Blogdetail</option>
                            <option>Servicedetail</option>
                            <option>CourseDetail</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Banner Image
                            <span className="text-danger ml-1">
                              (Size - 1920 x 300)*
                            </span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            accept="image/*"
                            name="image"
                            onChange={(e) => {
                              setCropingImage(e);
                              setShowModal(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- box1 -->
                
                  {/* <!-- action --> */}
                <div className="box-footer mb-4">
                  <button
                    type="submit"
                    className="btn btn-rounded btn_dark btn-outline btncourse"
                    onClick={addBanner}
                    style={{ padding: 10 }}
                  >
                    <i className="ti-save-alt"></i> Save
                  </button>
                </div>

                {/* <!-- action --> */}
              </div>
              {/* </form> */}
            </div>
          </section>

          {/* <!-- form --> */}

          {/* 		
		<!-- /.content --> */}
        </div>
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default AddBanner;
