import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <>
     <footer className="main-footer text-center">
	  &copy; 2023 <NavLink to="https://learnovationindia.com/">Learnovation</NavLink>. All Rights Reserved.
     </footer>
    </>
  )
}

export default Footer