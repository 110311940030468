import React, { useEffect } from "react";
import Course from "../../Home/Courses";
import Notice from "../Notice/Notice";
import Media from "./Media";
import UpcomingCourses from "./UpcomingCourses";
import Subscription from "./Subscription";
import StudentBanner from "../StudentBanner";
import Banner from "../../Banner";
import { useLocation, useNavigate } from "react-router-dom"; // Assuming you're using React Router
import axios from "axios";
import { useState } from "react";

import { NavLink } from 'react-router-dom'


const Home = () => {

  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const auth = localStorage.getItem("JWT_TOKEN");
    setAuth(auth);
  }, [localStorage.getItem("JWT_TOKEN")]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const section = document.getElementById("mySection");
    if (section) {
      section.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, [location]);

  //protected route
  // useEffect(() => {
  //   const checkToken = async () => {
  //     try {
  //       const response = await axios.get('http://localhost:5000/protected', {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("JWT_TOKEN")}`,
  //         },

  //         withCredentials: true    //============new 

  //       });
  //       const data = await response.data;
  //       console.log(data);

  //     } catch (error) {
  //       navigate("/")
  //       // alert("Unauthorized");
  //     }
  //   };

  //   checkToken();
  // }, []);

  //protected route end


  const [bannerImage, setBannerImage] = useState([]);
  useEffect(() => {
    getBannerImage();
  }, []);

  const getBannerImage = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/StudentDashboard`);

      const result = response.data[0];
      setBannerImage(result);
      // console.log(result);

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <main className="page_content">
        <section id="mySection">
          {/* <Banner heading="Welcome !" img={bannerImage.bannerURL} />        */}

          <section className="banner_section banner_style_4 herosection">
            <div
              className="decoration_wrap aboutbanner"
              style={{
                backgroundImage: `url(${bannerImage.bannerURL})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col col-lg-7">
                    <div className="banner_content">
                      <h1
                        className="banner_title herosec wow fadeInUp"
                        data-wow-delay=".1s"
                      >
                        Welcome !
                      </h1>
                      {/* <h6 className="text-white wow fadeInUp" data-wow-delay=".1s">
                        Home <i className="far fa-angle-right"></i>
                         &nbsp;{blogTitle}
                      </h6> */}
                      <h6 className="text-white wow fadeInUp" data-wow-delay=".1s">

                        {/* <NavLink className="banneranchor" to={auth ? "/Home" : "/"} >Home &nbsp;</NavLink> */}
                       

                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="deco_item deco_img_2 wow fadeInUp2"
                data-wow-delay=".6s"
              >
                {/* <img
              className="layer"
              src="../assets/images/shapes/dot_shape_7.png"
              data-depth="0.2"
              alt="Shape"
            /> */}
              </div>
              <div
                className="deco_item deco_img_6 wow bounceInUp"
                data-wow-delay=".6s"
              >
                {/* <img
              className="layer"
              src="../assets/images/shapes/shape_15.png"
              data-depth="0.2"
              alt="Shape"
            /> */}
              </div>
            </div>
          </section>

          {/* <UpcomingCourses /> */}
          <Course />
          <Notice />
          <Media />
          <Subscription />
        </section>
      </main>
    </>
  );
};

export default Home;
