import { notification } from 'antd'
import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import styles from './style.module.css'

const Rightsidecontent = () => {
  const { showLoader, hideLoader } = useGlobalLoader();

  const { showNotificationWithMessage } = useNotification();


  const [data, setData] = useState({
    name: "",
    email: "",
    message: ""
  })
  const navigate = useNavigate()


  const [path, setPath] = useState("");

  const handleSubmit = async (e) => {

    if (!validateForm()) {
      return
    }
      

    try {
      showLoader();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/web/SendContactEnquiry`,
        {
          studentName: data.name,
          email: data.email,
          message: data.message,
          pageName: path,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // console.log(response);
      showNotificationWithMessage('We will contact you soon', 'success');

      // notification.success({
      //   message: "We will contact you soon",
      // });
      setData({
        name: "",
        email: "",
        message: "",
      });
      // navigate("/Contact");
    } catch (error) {
      console.error(error);
    }
    finally {
      hideLoader(); // Hide the global loader
    }
  };

  const [blog, setBlog] = useState([]);
  const params = useParams();

  const getBlogweb = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/web/getPopularBlogWebsite/${params.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.data;
      setBlog(data);
    } catch (error) {
      showNotificationWithMessage('Something went wrong', 'error');

      // notification.warning({
      //   message: "something went wrong",
      // });
    }
  };

  useEffect(() => {
    // console.log("BLOG CHANGED???>><><<")
    getBlogweb();
  }, [params]);

  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: ""
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate name
    if (!data.name.trim()) {
      showNotificationWithMessage('All Fields Are Required', 'error');
      isValid = false;
      //   newErrors.name = "Name is required";

    }

    // Validate email
    if (!data.email.trim()) {
      showNotificationWithMessage('Email is required', 'error');

      // newErrors.email = "Email is required";
      isValid = false;

    } else if (!isValidEmail(data.email)) {
      showNotificationWithMessage('Invalid email address', 'error');

      isValid = false;
      // newErrors.email = "Invalid email address";
    } else {
      // newErrors.email = "";
      showNotificationWithMessage('Email is required', 'error');

    }

    // Validate message
    if (!data.message.trim()) {
      // newErrors.message = "Message is required";
      showNotificationWithMessage('All Fields Are Required', 'error');
      isValid = false;

    }

    setErrors(newErrors);
    return isValid;
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  return (
    <>
      {/* Enquiry form start  */}

      <div className="col-12 col-lg-3">
        <aside className="sidebar">
          <div className="widget form_item enquiryform mb-5">
            <h3 className="widget_title">Enquiry Form</h3>
            {/* <form> */}
            <div className="row mb-2">
              <div className="col-12 col-md-12 col-sm-12 mt-3">
                <div className="form_item mb-0">
                  <input
                    value={data.name}
                    onChange={(e) => {
                      setData((prev) => {
                        return { ...prev, [e.target.name]: e.target.value };
                      });
                    }}
                    type="text"
                    name="name"
                    placeholder="Full Name"
                  />
                </div>
              </div>

              <div className="col-12 col-md-12 col-sm-12 mt-4">
                <div className="form_item mb-0">
                  <input
                    value={data.email}
                    onChange={(e) => {
                      setData((prev) => {
                        return { ...prev, [e.target.name]: e.target.value };
                      });
                    }}
                    type="email"
                    name="email"
                    placeholder="Email Address"
                  />
                </div>
              </div>

              <div className="col-12 mt-4">
                <div className="form_item">
                  <textarea
                    value={data.message}
                    onChange={(e) => {
                      setData((prev) => {
                        return { ...prev, [e.target.name]: e.target.value };
                      });
                    }}
                    name="message"
                    placeholder="Your message..."
                  ></textarea>
                </div>
                <NavLink onClick={handleSubmit} className="btn btn_primary">
                  <span>
                    <small>Send Message</small>
                    <small>Send Message</small>
                  </span>
                  <i className="fal fa-paper-plane ml-2"></i>
                </NavLink>
              </div>
            </div>
            {/* </form> */}
          </div>
          {/* Enquiry form end  */}

          <div className="widget recent_post postmargin">
            <h3 className="widget_title">Popular Posts</h3>
            <ul className="unordered_list_block">
              {blog.length > 0 ? (
                blog.map((blog) => (
                  <li key={blog.id}>
                    <NavLink
                      className="blog_item_small"
                      to={"/blog/" + blog.url}
                    >
                      <span className="item_image">
                        <img src={blog.blogThumbImageURL} alt="Blog Image" />
                      </span>
                      <span className="item_content">
                        <strong className={`item_title d-block ${styles.textTruncate}`}>
                          {/* there are a few easy changes we can make */}
                          {blog.blogTitle}
                        </strong>
                        {/* <small className="post_date d-block">9 Aug, 2022</small> */}
                        <small className="post_date d-block">{blog.date.split('T')[0]}</small>
                      </span>
                    </NavLink>
                  </li>
                ))
              ) : (
                <h1 className="mt-3 text-info text-center">No Result Found</h1>
              )}


            </ul>
          </div>

        </aside>
      </div>
    </>
  );
};
export default Rightsidecontent;
