import React from "react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getStudentInfo, routes } from "../../../../utills/routes";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { notification } from "antd";
import Localstorage from "../../../../utills/storage/Localstorage";

import useNotification from '../../../../useNotification'; // Import the custom hook

import { useGlobalLoader } from "../../../../Loader/GlobalLoaderContext";
import { set } from "react-hook-form";


const Profile = () => {

  const { showNotificationWithMessage } = useNotification();
  const { showLoader, hideLoader } = useGlobalLoader();
  const navigate = useNavigate();
  // const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    age: "",
    city: "",
  });

  const validateForm = () => {
    const newErrors = {
      name: "",
      email: "",
      password: "",
      phoneNumber: "",
      age: "",
      city: "",
    };
    let isValid = true;

    if (!name) {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (!email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!isValidEmail(email)) {
      newErrors.email = "Invalid Email Address";
      isValid = false;
    } else {
      newErrors.email = "";
    }

    if (!password) {
      newErrors.password = "Password is required";
      isValid = false;
    } else if (password.length < 8) {
      newErrors.password = "Password must be at least 8 characters";
      isValid = false;
    } else {
      newErrors.password = "";
    }


    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
      isValid = false;
    }
    if (!age) {
      newErrors.age = "Age is required";
      isValid = false;
    }
    if (!city) {
      newErrors.city = "City is required";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  }

  const isValidEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  useEffect(() => {
    const UserId = localStorage.getItem("userId");
    // console.log(UserId);
  });

  const getStudentInfoFunc = async (id) => {
    try {
      showLoader();
      let res = await routes.APIS.getStudentInfo(id);
      // setData(res.data.studentInfo);
      const result = res.studentInfo;
      // console.log(result);
      setName(result.name);
      setEmail(result.email);
      setPassword(result.password);
      setPhoneNumber(result.phoneNumber);
      setAge(result.age);
      setCity(result.city);
    } catch (err) {
      console.log(err);
      showNotificationWithMessage('Something went wrong', 'error');

      // notification.warning({
      //   message: "something went wrong",
      // });
    }
    finally {
      hideLoader(); // Hide the global loader
    }

  };

  const editProfile = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      showLoader();
      let obj = {
        name,
        email,
        password,
        phoneNumber,
        age,
        city,
      }
      let response = await routes.APIS.editStudentProfile(obj);
      console.log(response)
      showNotificationWithMessage('Profile Updated successfully', 'success');

      // notification.success({
      //   message: "Profile Updated successfully",
      // });
    } catch (err) {
      showNotificationWithMessage('Something went wrong', 'error');

      // notification.warning({
      //   message: "something went wrong",
      // });
    } finally {
      hideLoader(); // Hide the global loader
    }
  };

  useEffect(() => {
    let userId = Localstorage.USER_ID.get();
    if (userId) {
      // console.log("From Navbar",userId)
      getStudentInfoFunc(userId);
    }
  }, [Localstorage.USER_ID.get()]);

  return (
    <>
      <section className="page_banner decoration_wrap">
        <div className="container text-center">
          <div className="widget sidebar_post_admin text-center">
            {/* <div class="admin_image">
              <img src="assets/images/meta/thumbnail_img_1.png" alt="Post Admin Image" />
            </div> */}
            <h3 className="admin_name"></h3>
            <h1 className="page_heading mb-2">
              <span class="d-lg-block white_text">Edit Profile</span>
            </h1>
          </div>
        </div>
      </section>

      <section className="membership_section profile_margin pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-8">
              {/* <form action="#"> */}
              <div className="register_form pb_40">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="form_item m-0 profileinput">
                      <label htmlFor="input_firstname" className="input_title">
                        Full Name
                      </label>
                      <input
                        id="input_firstname"
                        type="text"
                        name="firstname"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setErrors({ ...errors, name: "" });
                        }}
                      ></input>
                      {errors.name && (
                        <div className="text-danger">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 profileinput">
                    <div className="form_item m-0">
                      <label htmlFor="input_birthdate" className="input_title">
                        Age
                      </label>
                      <input
                        id="input_birthdate"
                        type="text"
                        name="Age"
                        value={age}
                        onChange={(e) => {
                          setAge(e.target.value);
                          setErrors({ ...errors, age: "" });
                        }}
                      />
                      {errors.age && (
                        <div className="text-danger">{errors.age}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <div className="form_item m-0">
                      <label htmlFor="input_lastname" className="input_title">
                        City
                      </label>
                      <input
                        id="input_lastname"
                        type="text"
                        name="city"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                          setErrors({ ...errors, city: "" });
                        }}
                      />
                      {errors.city && (
                        <div className="text-danger">{errors.city}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mt-4">
                    <div className="form_item m-0">
                      <label htmlFor="input_phone" className="input_title">
                        Phone Number
                      </label>
                      <input
                        id="input_phone"
                        type="tel"
                        name="phone"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                          setErrors({ ...errors, phoneNumber: "" });

                        }}
                      />
                      {errors.phoneNumber && (
                        <div className="text-danger">{errors.phoneNumber}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-6 col-md-6 mt-4">
                    <div className="form_item m-0">
                      <label htmlFor="input_email" className="input_title">
                        Email Address
                      </label>
                      <input
                        id="input_email"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setErrors({ ...errors, email: "" });
                        }}
                      />
                      {errors.email && (
                        <div className="text-danger">{errors.email}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-6 col-md-6 mt-4">
                    <div className="form_item m-0">
                      <label htmlFor="input_email" className="input_title">
                        Password
                      </label>
                      <input
                        id="input_email"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setErrors({ ...errors, password: "" });
                        }}
                      />
                      {errors.password && (
                        <div className="text-danger">{errors.password}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="text-center mt-5">
                  <button
                    type="submit"
                    onClick={editProfile}
                    className="btn btn_primary"
                  >
                    <span>
                      <small>Update</small>
                      <small>Update</small>
                    </span>
                    <i className="fal fa-paper-plane ms-2"></i>
                  </button>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
