import { notification } from 'antd';
import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import useNotification from '../../../useNotification'; // Import the custom hook
import styles from './style.module.css'

const Blogpage = () => {

	const { showNotificationWithMessage } = useNotification();
  const [blog, setBlog] = useState([]);

  const getBlogweb = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/getBlogWebsite`);
      const data = await response.data;
      setBlog(data);

    } catch {
			showNotificationWithMessage('Something went wrong', 'error');
    }
  }

  useEffect(() => {
    getBlogweb();
  }, []);



  return (
    <>
      <section className="blog_section blog_section_padding">
        <div className="container">
          <div className="row row15">
            {blog.length > 0 ? (
              blog.map((blog) => (
                <div
                  className="col-12 col-lg-4 col-md-6 mt-5 col-sm-12 blogpage"
                  key={blog.id}
                >
                  <NavLink 
                  to={"/blog/" + blog.url}
                  >
                  <div className="blog_item">
                    
                      <img src={blog.blogThumbImageURL} alt="Blog" />
                   
                    <div className={`item_content ${styles.content}`}>
                      <ul className={`post_meta unordered_list`}>
                        <li className={`${styles.textTruncateOneLine}`}>
                          {blog.blogAuthor}
                        </li>
                      </ul>
                      <h3 className={`item_title ${styles.textTruncate}`}>
                        {blog.blogTitle}
                      </h3>
                      {/* <p className="mb-0">{blog.shortInfo}</p> */}
                      <NavLink className="btn btn_primary mt-4" to={"/blog/" + blog.url}>
                        <span>
                          <small>Read More</small>
                          <small>Read More</small>
                        </span>
                        <i className="far fa-long-arrow-right ml-2"></i>
                      </NavLink>
                    </div>
                  </div>
                  </NavLink>
                </div>
              ))
              ) : (
                <h1 className="mt-3 text-info text-center">
                  No Result Found
                </h1>
              )}
          </div>
        </div>
      </section>

{/* dynamic */}
{/* <section className="blog_section section_space_sm">
          <div className="container-fluid">       
            <div className="row row15 justify-content-center">
              {
                page.map((blogpage,index)=>(
                  <div className="col col-lg-3 col-sm-12" key={blogpage.id}>
                  <div className="blog_item">
                    <a className="item_image" href="#">
                      <img src={blogpage.image} alt="Blog"/>
                    </a>
                    <div className="item_content">
                      <ul className="post_meta unordered_list">
                        <li><a href="#">{blogpage.name}</a></li>
                        <li>{blogpage.date}</li>
                       
                      </ul>
                      <h3 className="item_title">
                        <a href="#">
                        {blogpage.title}
                        </a>
                      </h3>
                      <p className="mb-0">
                      {blogpage.description}
                      </p>
                      <a className="btn btn_primary mt-4" href="/Blogdetail">
                    <span>
                      <small>Read More</small>
                      <small>Read More</small>
                    </span>
                    <i className="far fa-long-arrow-right ms-1"></i>
                    </a>
                    </div>
                  </div>
                </div>
                ))
              }
            </div>
          </div>
        </section> */}
    </>
  );
};

export default Blogpage;
