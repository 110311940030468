import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import Cookies from "js-cookie";
import { ErrorMessage, Formik } from "formik";
import { routes } from "../../../utills/routes";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";
import useNotification from '../../../useNotification'; // Import the custom hook


const Signupform = () => {
  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");

  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(300);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      if (otpSent) {
        setOtpSent(false);
        setOtp("");
      }
    }
    return () => clearInterval(interval);
  }, [otpSent, timer]);

  const handleSendOTP = async (values) => {
    // console.log(values)
    try {
      setLoading(true);
      setError(null);
      const otpResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/send-otp`, { phoneNumber: values.phoneNumber, email: values.email });
      if (otpResponse.status === 200) {
        showNotificationWithMessage('OTP sent successfully on your mobile number', 'success');
        // setTimeout(() => {
        //   setOtpSent(true);
        // }, 1000);
        setOtpSent(true);
        setTimer(300);
      } else {
        showNotificationWithMessage("Failed to send OTP. Please try again.", 'error');
      }
    } catch (error) {
      console.error(error);
      // setError("Failed to send OTP. Please try again.");
      // showNotificationWithMessage('Failed to send OTP. Please try again.', 'error');
      showNotificationWithMessage(error.response.data.message ? error.response.data.message : error.response.data.error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (values) => {
    // console.log(values)

    try {
      setLoading(true);
      setError(null);
      const otpVerifyResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/verify-Otp`, { phoneNumber: values.phoneNumber, otp: otp });

      // console.log("otpVerifyResponse", otpVerifyResponse)

      if (otpVerifyResponse.status === 200) {
        const registerResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/studentregister`,
          {
            ...values
          },
          {
            headers: {
              "Content-Type": "application/json"

            }, withCredentials: true
          }
        );
        console.log("Registration successful:", registerResponse.data);
        if (registerResponse.status == 201) {
          showNotificationWithMessage('Account created successfully, Login Now', 'success');
          setTimeout(() => {
            navigate('/login');
          }, 1000);
        } else {
          // showNotificationWithMessage(registerResponse.data.message, 'error');
          showNotificationWithMessage(registerResponse.data.message || 'Failed to register', 'error');
        }

      } else {
        // showNotificationWithMessage(otpVerifyResponse.data.message, 'error');
        showNotificationWithMessage(otpVerifyResponse.data.message || 'Failed to verify OTP', 'error');
      }
    } catch (error) {
      console.error(error);
      setError("Failed to verify OTP. Please try again.");
      // showNotificationWithMessage("Failed to verify OTP. Please try again.", 'error');
      showNotificationWithMessage(error.response.data.message ? error.response.data.message : error.response.data.error, 'error');
    } finally {
      setLoading(false);
    }
  };


  // const handleRegister = async (e) => {
  //   e.preventDefault();

  //   if (!name || !email || !password || !phoneNumber || !age || !city) {
  //     showNotificationWithMessage('Please fill all fields', 'error');
  //     return
  //   }
  //   try {
  //     showLoader();
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BACKEND_URL}/studentregister`,
  //       {
  //         name, email, password, phoneNumber, age, city
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json"
  //         }, withCredentials: true
  //       }
  //     );
  //     if (response.status == 200) {
  //       showNotificationWithMessage('Account created successfully', 'success');
  //     }
  //   } catch (error) {
  //     console.log(error.response.data.error)
  //     showNotificationWithMessage(error.response.data.message ? error.response.data.message : error.response.data.error, 'error');

  //     console.error(error);
  //   }
  //   finally {
  //     hideLoader();
  //   }
  // };
  const isValidAge = (age) => {
    const ageRegex = /^[0-9]{2}$/;
    return ageRegex.test(age);
  };
  return (
    <>
      <div className="register_section section_space_lg pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-6">
              <Formik initialValues={{
                name: '',
                email: '',
                password: '',
                phoneNumber: '',
                age: '',
                city: ''
              }}
                validate={values => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = 'Please enter valid name';
                  } else if (
                    !/^[A-Za-z]+/.test(values.name)
                  ) {
                    errors.name = 'Invalid name';
                  }
                  if (!values.email) {
                    errors.email = 'Please enter valid email';
                  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    errors.email = 'Invalid email address';
                  } if (!values.password) {
                    errors.password = 'Please enter valid password';
                  } else if (values.password.length < 8) {
                    errors.password = 'Password must be atleast 8 characters';
                  } if (!values.phoneNumber) {
                    errors.phoneNumber = 'Please enter Phone Number';
                  } else if (values.phoneNumber.toString().length != 10) {
                    errors.phoneNumber = 'Please enter valid Phone Number';
                  } if (!values.age) {
                    errors.age = 'Please enter age';
                  } if (!isValidAge(values.age)) {
                    errors.age = 'Please enter valid age';
                  }
                  if (!values.city) {
                    errors.city = 'Please enter valid city';
                  }

                  // console.log(errors)
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  // console.log("submit ", values)
                  try {
                    const response = await axios.post(
                      `${process.env.REACT_APP_BACKEND_URL}/studentregister`,
                      {
                        ...values
                      },
                      {
                        headers: {
                          "Content-Type": "application/json"

                        }, withCredentials: true
                      }
                    );
                    if (response.status == 201) {
                      showNotificationWithMessage('Account created successfully, Login Now', 'success');
                      setTimeout(() => {
                        navigate('/login');
                      }, 1000);
                    }

                    setTimeout(() => {
                      navigate('/login');
                    }, 1000);
                  } catch (error) {
                    console.log(error.response.data.error)

                    showNotificationWithMessage(error.response.data.message ? error.response.data.message : error.response.data.error, 'error');

                    console.error(error);
                  }
                  
                  // try {
                  //   await handleSendOTP(values);
                  // } catch (error) {
                  //   console.error(error);
                  // }
                }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <div className="register_form signup_login_form">
                    <div className="item_icon text-center">
                      <img src="../assets/img/user.png" className='infoimg' alt="Icon Email" />
                      <h6 className="login_heading mt-3 mb-4">Let's Get Started</h6>
                    </div>
                    <div className="row mt-1">
                      <div className="col-12 col-md-6 mt-2">
                        <div className="form_item">
                          <input
                            type="text"
                            name="name"
                            // onChange={(e) => setName(e.target.value)}
                            onChange={handleChange}
                            placeholder="Full Name"
                          />
                          <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                      </div>

                      <div className="col-12  col-md-6 mt-2">
                        <div className="form_item">
                          <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            placeholder="Email"
                          />
                          <ErrorMessage name="email" component="div" className="text-danger" />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <div className="form_item">
                          <input
                            type="password"
                            name="password"
                            onChange={handleChange}
                            placeholder="Create Password"
                          />
                          <ErrorMessage name="password" component="div" className="text-danger" />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <div className="form_item">
                          <input
                            type="text"
                            name="phoneNumber"
                            onChange={handleChange}
                            placeholder="Phone Number"
                          />
                          <ErrorMessage name="phoneNumber" component="div" className="text-danger" />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <div className="form_item">
                          <input
                            type="number"
                            name="age"
                            onChange={handleChange}
                            placeholder="Age"
                          />
                          <ErrorMessage name="age" component="div" className="text-danger" />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 mt-2">
                        <div className="form_item">
                          <input
                            type="text"
                            name="city"
                            onChange={handleChange}
                            placeholder="City"
                          />
                          <ErrorMessage name="city" component="div" className="text-danger" />
                        </div>
                      </div>

                      <div className="col-12 col-md-12 mt-2">
                        <div className="d-flex justify-content-center">
                          <button type="submit" onClick={handleSubmit} className="btn btn_primary mb-5">
                            <span>
                              <small>Signup</small>
                              <small>Signup</small>
                            </span>
                            <i className="fal fa-paper-plane ml-2"></i>
                          </button>
                        </div>
                      </div>

                      {/* <div className="col-12 col-md-12 mt-2 mb-5">
                        <div className="d-flex justify-content-center ">
                          {otpSent ? (
                            <>
                              <div className="row align-items-center mt-2">
                                <div className="col-12 col-md-6">
                                  <div className="form_item">
                                    <input type="tel"
                                      name="otp"
                                      placeholder="Enter OTP"
                                      value={otp}
                                      onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length <= 6) {
                                          setOtp(e.target.value);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-md-6">
                                  <div className="form_item">
                                    <div>Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? "0" : ""}{timer % 60}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-12 mt-2">
                                  <div className="d-flex justify-content-center">
                                    <button className="btn btn_primary mb-5" type="button"
                                      onClick={() => handleVerifyOtp(values)}
                                    >
                                      <span>
                                        <small>Verify OTP</small>
                                        <small>Verify OTP</small>
                                      </span>
                                      <i className="fal fa-paper-plane ml-2"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <button className="btn btn_primary mb-5" type="submit"
                                onClick={() => handleSendOTP(values)} disabled={loading || otpSent}>
                                <span>
                                  <small>Send OTP</small>
                                  <small>Send OTP</small>
                                </span>
                                <i className="fal fa-paper-plane ml-2"></i>
                              </button>
                            </>
                          )}
                        </div>
                      </div> */}
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};

export default Signupform;
