import React, { useEffect } from "react";
import About_Banner from "../../components/website/About/About_Banner";
import Hero from "../../components/website/About/Hero";
import Mission from "../../components/website/About/Mission";
// import Usp from "../../components/website/About/Usp";
import Testimonials from "../../components/website/About/Testimonials";
import { useLocation } from "react-router-dom"; // Assuming you're using React Router
import Banner from "../../components/website/Banner";
import Counter from "../../components/website/Home/Counter";
import { get } from "react-hook-form";
import axios from "axios";
import { useState } from "react";

const About = () => {
  const location = useLocation();

  useEffect(() => {
    const section = document.getElementById("mySection");
    if (section) {
      section.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, [location]);

  const [bannerImage, setBannerImage] = useState([]);
  useEffect(() => {
    getBannerImage();
  }, []);

  const getBannerImage = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/About`);

      const result = response.data[0];
      setBannerImage(result);
      // console.log(result);

    } catch (error) {
      console.error(error);
    }
  };




  return (
    <>
      <main className="page_content">
        <section id="mySection">

        <Banner heading='About Us' img={bannerImage.bannerURL} />

          <Hero />
          <Counter/>
          {/* <Usp /> */}
          <Mission />
          <Testimonials />
        </section>
      </main>
    </>
  );
};

export default About;
