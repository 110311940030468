import React, { useEffect } from 'react'

import Banner from '../../components/website/Banner'
import Leftsidecontent from "../../components/website/Blogdetail/Leftsidecontent"
import Rightsidecontent from '../../components/website/Blogdetail/Rightsidecontent'
import { NavLink, useParams } from 'react-router-dom'

import { useLocation } from 'react-router-dom'; // Assuming you're using React Router
import axios from 'axios';
import { useState } from 'react';


const Blogdetail = () => {

  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const auth = localStorage.getItem("JWT_TOKEN");
    setAuth(auth);
  }, [localStorage.getItem("JWT_TOKEN")]);

  const location = useLocation();

  useEffect(() => {
    const section = document.getElementById('mySection');
    if (section) {
      section.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [location]);

  const [bannerImage, setBannerImage] = useState([]);
  useEffect(() => {
    getBannerImage();
  }, []);

  const getBannerImage = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/Blogdetail`);

      const result = response.data[0];
      setBannerImage(result);
      // console.log(result);

    } catch (error) {
      console.error(error);
    }
  };

  const [blogTitle, setBlogTitle] = useState("");
  const params = useParams();


  useEffect(() => {
    getBlogDetail();
  }, [params]);

  const getBlogDetail = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/web/GetBlogById/${params.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = response.data;
      setBlogTitle(result.blogTitle);
    } catch (error) {
      // notification.warning({
      //   message: "something went wrong",
      // });
      // showNotificationWithMessage('Something went wrong', 'error');
      console.log('Something went wrong');

    }
  };



  return (
    <>
      <main className="page_content">
        <section id="mySection">
          {/* <Banner heading='Blog' img={bannerImage.bannerURL} /> */}

          <section className="banner_section banner_style_4 herosection">
            <div
              className="decoration_wrap aboutbanner"
              style={{
                backgroundImage: `url(${bannerImage.bannerURL})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col col-lg-7">
                    <div className="banner_content">
                      <h1
                        className="banner_title herosec wow fadeInUp"
                        data-wow-delay=".1s"
                      >
                        Blog
                      </h1>
                      {/* <h6 className="text-white wow fadeInUp" data-wow-delay=".1s">
                        Home <i className="far fa-angle-right"></i>
                         &nbsp;{blogTitle}
                      </h6> */}
                      <h6 className="text-white wow fadeInUp" data-wow-delay=".1s">

                        <NavLink className="banneranchor" to={auth ? "/home" : "/"} >Home &nbsp;</NavLink>
                        <i className="far fa-angle-right"></i>  &nbsp;{blogTitle}
                        
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="deco_item deco_img_2 wow fadeInUp2"
                data-wow-delay=".6s"
              >
                {/* <img
              className="layer"
              src="../assets/images/shapes/dot_shape_7.png"
              data-depth="0.2"
              alt="Shape"
            /> */}
              </div>
              <div
                className="deco_item deco_img_6 wow bounceInUp"
                data-wow-delay=".6s"
              >
                {/* <img
              className="layer"
              src="../assets/images/shapes/shape_15.png"
              data-depth="0.2"
              alt="Shape"
            /> */}
              </div>
            </div>
          </section>

          <section className="details_section blog_details_section section_space_md pt-0">
            <div className="container">
              <div className="row justify-content-lg-between rowone">
                <Leftsidecontent />
                <Rightsidecontent />
              </div>
            </div>
          </section>
        </section >

      </main>
    </>
  )
}

export default Blogdetail