import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Input, Space, Table, notification } from "antd";
import Highlighter from "react-highlight-words";

import useNotification from "../../../useNotification"; // Import the custom hook
import Swal from "sweetalert2";
import { SearchOutlined } from "@ant-design/icons";

import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";



const Students = () => {
  const { showLoader, hideLoader } = useGlobalLoader();

  const { showNotificationWithMessage } = useNotification();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const [students, setStudents] = useState([]);
  const [columns, setColumns] = useState([
    // "S No.",
    // "Name",
    // "Email",
    // "Date",
    // "Actions",
    { title: "Sr no", dataIndex: "index", key: "index" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      sorted: true,
      sorter: (a, b) => a.Date - b.Date,
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      //   render: () => <a>Delete</a>,
    },
  ]);
  const [data, setData] = useState([]);
  const navigator = useNavigate();
  useEffect(() => {
    getStudents();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  //delete student
  const deleteStudent = async (id) => {
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this record?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (!confirmed.isConfirmed) {
        return; // If user cancels, do nothing
      }

      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/deletestudent/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        getStudents();
        showNotificationWithMessage("Record is deleted", "success");
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      hideLoader();
    }
  };

  const editStudent = async (id) => {
    navigator(`/admin/editstudent/${id}`);
  };

  const getStudents = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/GetStudents`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );
      const result = response.data;
      let data = result.map((item, index) => {
        // console.log("item", item._id);

        return {
          index: index + 1,
          Actions: (
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-circle btn-sm btn-primary"
                onClick={() => editStudent(item._id)}
              >
                <i className="fa fa-edit"></i>
              </button>
              <button
                type="button"
                className="btn btn-circle btn-info btn-xs mr-10"
                onClick={() => deleteStudent(item._id)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          ),
          name: item.name,
          email: item.email,
          Date: item.createdAt,
          action: (
            <div className="btn-group">
              <button
                type="button"
                className="btn-circle btn-info btn-xs mr-10"
                onClick={() => editStudent(item._id)}
              >
                <i className="ti-marker-alt"></i>
              </button>
              <button
                type="button"
                className="btn-circle btn-danger btn-xs mr-10"
                onClick={() => deleteStudent(item._id)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </div>
          ),
        };
      });

      // console.log("data", data);
      setData(data);
      setStudents(result);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      hideLoader();
    }
  };

    //delete student
    // const deleteStudent = async (id) => {
    //   try {
    //     // Show the confirmation popup
    //     const confirmed = await Swal.fire({
    //       title: "Are you sure?",
    //       text: "Are you sure you want to delete this record?",
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonColor: "#3085d6",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: "Yes, delete it!",
    //     });

    //     if (!confirmed.isConfirmed) {
    //       return; // If user cancels, do nothing
    //     }

    //     const response = await axios.delete(
    //       `${process.env.REACT_APP_BACKEND_URL}/admin/deletestudent/${id}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${Cookies.get("jwtoken")}`,
    //         },
    //         withCredentials: true, // Send cookies with the request
    //       }
    //     );
    //     };

    //     if (response.data) {
    //       getStudents();
    //       showNotificationWithMessage("Record is deleted", "success");
    //     }
    //   };catch (error) {

    //     console.log(error);
    //   }
    //   finally {
    //     hideLoader();
    //   }
    // };

    // const editStudent = async (id) => {
    //   navigator(`/admin/editstudent/${id}`);
    //   console.log("id", id);
    // };


  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              <div className="col-lg-12 mb-2 mt-1">
                {/* <h5 className="box-title">Student List</h5> */}
                <h3 className="page-title">Student List</h3>
              </div>
              <div className="col-12">
                <div className="box" style={{ marginTop: 10 }}>
                  <div className="box-body">
                    <div className="table-responsive">
                      <Table
                        columns={columns}
                        // expandable={{
                        //   expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.description}</p>,
                        //   rowExpandable: (record) => record.name !== 'Not Expandable',
                        // }}
                        dataSource={data}
                        pagination={{ pageSize: 10 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  );
};

export default Students;
