import React, { useEffect } from "react";
// import { useHistory } from 'react-router-dom';
import styles from "./thankyou.module.css";
import { useNavigate } from "react-router-dom";
const Failed = () => {
  // const history = useHistory();
  const router = useNavigate();
  //  useEffect(() => {
  //   const timer = setTimeout(() => {
  //     history.push('/');
  //   }, 2000);
  //   return () => clearTimeout(timer);
  // }, [history]);
    
  return (
    <>
      <main class="page_content">
        <section
          class="banner_section banner_style_3"
          style={{
            backgroundImage: `url(../assets/img/thankyou.png)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            Height: "500",
          }}
        >
          <div class="container">
            <div class="row align-items-center justify-content-center">
              <div class="col col-lg-7">
                <div class="banner_content text-center thankyou-content">
                  <h1 class="banner_title thankyoutitle">Oops !</h1>
                  <p className="thankyoup text-center">
                    Payment failed please try again.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Failed;
