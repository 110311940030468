import React, { useEffect } from "react";

// import BannerSearch from '../../components/website/Courses/BannerSearch'
import Course from "../../components/website/Courses/Course";

import { useLocation } from "react-router-dom"; // Assuming you're using React Router

const Courses = () => {
  const location = useLocation();

  useEffect(() => {
    const section = document.getElementById("mySection");
    if (section) {
      section.scrollIntoView({ behavior: "auto", block: "start" });
    }
  }, [location]);

  return (
    <>
      <main className="page_content">
        <section id="mySection">
          {/* <BannerSearch/> */}
          <Course />
        </section>
      </main>
    </>
  );
};

export default Courses;
