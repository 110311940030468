import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './PassReset.module.css';
import { notification } from 'antd';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const PasswordReset = () => {
  const { showNotificationWithMessage } = useNotification();
  const { showLoader, hideLoader } = useGlobalLoader();
  const navigate = useNavigate();
  const { userId, resetToken } = useParams(); // Extract userId and resetToken from URL parameters

  return (
    <>
      <main className="page_content">
        <section className="page_banner decoration_wrap">
          <div className="container text-center">
            {/* <h1 className="page_heading mb-2 white_text">Reset Password</h1> */}
            {/* <h1 className="page_heading login_heading mb-2 white_text">Reset Your Password Here</h1> */}
          </div>
        </section>

        <div className="register_section section_space_lg pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col col-lg-5">
                <Formik
                  initialValues={{
                    password: '',
                    conPassword: '',
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (values.password.length < 8) {
                      errors.password = 'Password must be at least 8 characters long';
                    }
                    if (values.password !== values.conPassword) {
                      errors.conPassword = 'Password and Confirm Password should be the same';
                    }
                    return errors;
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      showLoader();
                      const response = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/ResetPasswordWithToken`,
                        {
                          password: values.password,
                          userId: userId,
                          token: resetToken,
                        },
                        {
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          withCredentials: true,
                        }
                      );
                      if (response.status === 200) {
                       
                        showNotificationWithMessage(
                          'Password Reset Successfully', 'success'
                        );
                        setTimeout(() => {
                          navigate('/login')
                        }, 1000);
                          
                      }
                    } catch (error) {
                      if(error?.response?.status===400){
                        showNotificationWithMessage(error?.response?.data, 'error');
                      }else{
                        showNotificationWithMessage('Something went wrong', 'error');
                      }

                    }
                    finally {
                      hideLoader(); 
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="register_form forgot_form signup_login_form pb_50">
                      <h6 class="login_heading mt-3 mb-4">Reset Password</h6>

                        <div className="form_item">
                          <label htmlFor="password">New Password:</label>
                          <Field
                            type="password"
                            id="password"
                            name="password"
                            required
                            placeholder="Enter new Password"
                          />
                          <ErrorMessage name="password" component="div" className="text-danger" />
                        </div>

                        <div className="form_item">
                          <label htmlFor="conPassword">Confirm Password:</label>
                          <Field
                            type="password"
                            id="conPassword"
                            name="conPassword"
                            required
                            placeholder="Confirm Password"
                          />
                          <ErrorMessage name="conPassword" component="div" className="text-danger" />
                        </div>

                        <button type="submit" className="btn btn_primary" disabled={isSubmitting}>
                          <span>
                            <small>Change Password</small>
                            <small>Change Password</small>
                          </span>
                          <i className="fal fa-paper-plane ml-2"></i>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PasswordReset;
