import React from 'react'
import { NavLink } from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const AboutCompany = () => {
  var settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <>


      <section className="about_section margin_bottom30 res_70 bg_light">
        <div className="container">
          <div className="row align-items-center justify-content-lg-between">
            <div className="col-12 col-lg-6">
              <div className="about_image">
                <Slider {...settings}>
                  <div>
                    <img src="../assets/img/about_office6.png" alt="office-img" />
                  </div>
                  <div>
                    <img src="../assets/img/about_office7.png" alt="office-img" />
                  </div>
                  <div>
                    <img src="../assets/img/about_office8.png" alt="office-img" />
                  </div>
                  {/* <div>
                    <img src="../assets/img/office4.png" alt="office-img" />
                  </div>
                  <div>
                    <img src="../assets/img/office5.png" alt="office-img" />
                  </div> */}

                </Slider>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="about_content">
                <h4 className='grey_text about_learnovation'>About Learnovation</h4>
                <h2 className='white_text' data-aos="flip-down">
                Learn with us, Earn with us, Grow with us
                </h2>
                <p className="grey_text" >
                  Learnovation is a team of full time Derivative and Discretionary veteran and young traders, specializing in Index Momentum, Point and Figure, and Indicators based trading and having an expertise in non-directional option strategies.

                </p>
                <div className='aboutcenter'>
                  <NavLink className="btn btn_primary mt-3" to="/about"><span><small>Read More</small><small>Read More</small></span><i className="far fa-long-arrow-right ml-2"></i></NavLink>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutCompany