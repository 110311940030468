import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import useNotification from "../../useNotification";
import Content from '../../components/website/Coursedetail/Content';
import DOMPurify from "isomorphic-dompurify";
import Banner from "../../components/website/Banner";

const PrivacyPolicy = () => {
    const location = useLocation();
    const { showNotificationWithMessage } = useNotification();

    useEffect(() => {
        const section = document.getElementById("mySection");
        if (section) {
            section.scrollIntoView({ behavior: "auto", block: "start" });
        }
    }, [location]);

    const [bannerImage, setBannerImage] = useState([]);
    const [privacyPolicy, setPrivacyPolicy] = useState('');

    const getPrivacyPolicy = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/web/getPrivacyPolicyWebsite`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const data = response.data;
            console.log(data)
            const privacyPolicies = data.map(item => item.privacyPolicy_content);
            setPrivacyPolicy(privacyPolicies.join('\n\n'));

        } catch (error) {
            showNotificationWithMessage("Something went wrong", "error");
        }
    };

    useEffect(() => {
        getPrivacyPolicy()
    }, [])

    useEffect(() => {
        getBannerImage();
    }, []);

    const getBannerImage = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/PrivacyPolicy`);
            const result = response.data[0];
            setBannerImage(result);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <main className="page_content">
                <section id="mySection">

                    <Banner heading='Privacy & Policy' img={bannerImage?.bannerURL} />
                    <div className="container p-5">
                        <div className="row">
                            {privacyPolicy && (
                                <Content
                                    className="courses_content"
                                    style={{ color: 'white !important', }}
                                    HTML={DOMPurify.sanitize(privacyPolicy)}
                                />
                            )}

                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default PrivacyPolicy;
