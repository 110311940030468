import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { Modal } from "antd";
import styles from "./documents.module.css";

import { Document, Page, pdfjs } from "react-pdf";

import { Viewer } from "@react-pdf-viewer/core"; // install this library
// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// Worker
import { Worker } from "@react-pdf-viewer/core";

const DocumentComponent = ({Document}) => {
  const [mediaDocuments, setMediaDocuments] = useState([]);
  const [documentURL, setDocumentURL] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if(Document){
      setMediaDocuments(Document)
    }
  }, [Document]);

  return (
    <>
      <section className="category_section course_margin">
        <div className="container">
          <div className="section_heading text-center">
            <h2 className="heading_text mb-0 grey_text">
              The popular online
              <span className="heading_focus_text  grey_text">freelancing</span> courses
              <span className="d-block  grey_text">for self-employment future</span>
            </h2>
          </div>
          <Swiper
            // className="margintop_60"
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            // spaceBetween={1}
            style={{ padding: "3rem 3rem" }}
            slidesPerView={3}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1,
              },
              639: {
                slidesPerView: 2,
              },
              865: {
                slidesPerView: 4,
              },
              1000: {
                slidesPerView: 4,
              },
              1500: {
                slidesPerView: 4,
              },
              1700: {
                slidesPerView: 4,
              },
            }}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }} // Enable autoplay with 3 seconds delay
            // scrollbar={{ draggable: true }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log("slide change")}
          >
            {mediaDocuments.length > 0 ? (
              mediaDocuments.map((mediaDocument, index) => {
                if (mediaDocument.mediaType === "document")
                  return (
                    <SwiperSlide key={index}>
                      <div
                        onClick={() => {
                          setIsModalOpen(true);
                          setDocumentURL(mediaDocument.mediaURL);
                        }}
                        className={styles.document}
                      >
                        <div className={styles.image}>
                          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                            <Viewer fileUrl={mediaDocument.mediaURL} />
                          </Worker>
                        </div>
                        <div className={styles.Title}>
                          {mediaDocument.mediaTitle}
                        </div>
                      </div>
                      {/* </NavLink> */}
                    </SwiperSlide>
                  );
              })
            ) : (
              <h1>Loading.....</h1>
            )}
          </Swiper>


          <Modal
            width={850}
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
          >
            <div className={styles.customModalBody}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                <Viewer fileUrl={documentURL} />
              </Worker>
            </div>
          </Modal>
        </div>
      </section>
    </>
  );
};

export default DocumentComponent;
