import React, { useEffect } from 'react'

import Allcourses from '../../components/website/Coursedetail/Allcourses'

import { useLocation } from 'react-router-dom'; // Assuming you're using React Router


const Coursedetail = () => {
   const location = useLocation();

   useEffect(() => {
      const section = document.getElementById('mySection');
      if (section) {
         section.scrollIntoView({ behavior: 'auto', block: 'start' });
      }
   }, [location]);

   return (
      <>
         <main className="page_content">
            <section id="mySection">
               <Allcourses />
            </section>
         </main>
      </>
   )
}

export default Coursedetail