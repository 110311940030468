import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from './styles.module.css'

const category = [
  {
    id: 1,
    image: "../assets/img/financial.png",
    title: "Learnovation Mentoring Programme",
    link: "/service/64c0b56a8bae79e08567ce9f",
  },
  {
    id: 2,
    image: "../assets/img/increase.png",
    title: "Financial Products",
    link: "/service/64c0b6dd8bae79e08567d817",
  },
  {
    id: 3,
    image: "../assets/img/stock.png",
    title: "Online Trader’s Desk",
    link: "/service/64c0b8d48bae79e08567e06d",
  },
  // {
  //   id: 4,
  //   image: "../assets/img/asset.png",
  //   title: "Mentoring",
  //   link: "/Services",
  // },
];
const Service = ({ margin }) => {
  const [serviceData, setData] = useState([]);

  const getServices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/web/GetServices`
      );
      const data = await response.data.splice(0, 3);
      let newData = data.map((ele, index) => {
        return {
          key: index,
          id: ele._id,
          image: category[index].image,
          title: ele.title,
          link: `/service/${ele.url}`,
        };
      });
      setData(newData);
    } catch (error) {
      console.error("Error fetching service:", error);
    }
  };

  useEffect(() => {
    getServices();
  }, []);
  return (
    <>
      <section className="category_section section_space_md resposive_space black_bg">
        <div className="container">
          <div
            style={margin ? { marginTop: margin } : {}}
            className="section_title text-center margin_top70"
          >
            <h2 className="mb-0">
              <span className="d-md-block white_text">
                <span className="course_focus_text">Services</span> We Provide
              </span>
            </h2>
          </div>

          <div className="row">
            {serviceData.map((cat, index) => (
              <div
                className="col-12 col-lg-4 col-md-6"
                // data-aos="fade-up"
                key={cat.id}
              >
                <div
                  className="category_item"
                  style={{
                    backgroundImage: `url("../assets/images/shapes/dot_shape1.png")`,
                  }}
                >
                  <NavLink className={styles.serviceCard} to={cat.link}>
                    <span className="item_icon">
                      <img src={cat.image} alt="Icon Test Tube" />
                    </span>
                    <strong className={`item_title ${styles.textTruncate}` }>{cat.title}</strong>
                  </NavLink>
                </div>
              </div>
            ))}

            <div className="col-12 col-lg-12 col-md-6 text-center mt-5">
              <NavLink className="btn btn_primary mt-3" to="/services">
                <span>
                  <small>View All Services</small>
                  <small>View All Services</small>
                </span>
                <i class="far fa-long-arrow-right ml-2"></i>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
