import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import styles from "./styles.module.css";
import { Button, Input, Space, Table, Checkbox, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import useNotification from "../../../useNotification"; // Import the custom hook
import Swal from "sweetalert2";
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const ServeyEnquires = () => {

  const { showLoader, hideLoader } = useGlobalLoader();

  const [Enquiries, setEnquiries] = useState([]);
  const [enquiryId, setEnquiryId] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const searchInput = useRef(null);
  const [data, setData] = useState([]);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const [columns, setColumns] = useState([
    { title: "Sr no", dataIndex: "index", key: "index" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "City", dataIndex: "city", key: "city" },
    { title: "Reference From", dataIndex: "referance", key: "referance" },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const { showNotificationWithMessage } = useNotification();

  const getEnquiries = async () => {
    try {
      showLoader();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getContactEnquires`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true,
        }
      );
      const result = response.data;
      // console.log(result);
      let finalResult = [];
      let index = 0;
      result.map((enquiry) => {
        if (enquiry.isServeyEnquiry === true) {
          finalResult.push({
            index: index + 1,
            name: enquiry.studentName,
            courseName: enquiry.title,
            email: enquiry.email,
            phone: enquiry.phone ? enquiry.phone : 'Null',
            city: enquiry.city ? enquiry.city : 'Null',
            key: enquiry._id,
            referance: enquiry.message ? enquiry.message : 'Null',
            page: enquiry.pageName.split('/')[1],
            action: (
              <Space size="middle">
                <Button type="primary" className="btn-circle btn-danger btn-xs" onClick={() => deleteEnquiry(enquiry._id)}>
                  <i className="fa fa-trash"></i>
                </Button>
              </Space>
            ),
          })
          index++;
        }
      });
      // console.log(finalResult);
      setData(finalResult);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
    finally {
      hideLoader();
    }
  };

  //delete enquiry
  const deleteEnquiry = async (id) => {
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this record?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
      if (!confirmed) {
        return; // If user cancels, do nothing
      }

      showLoader();
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/DeleteContactEnquiry/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        getEnquiries();
        // notification.warning({
        //   message: "Record is deleted",
        // });
        showNotificationWithMessage('Record is deleted', 'success');
      }
    } catch (error) {
      // notification.warning({
      //   message: "Something went wrong",
      // });
      showNotificationWithMessage('Something went wrong', 'error');
    }
    finally {
      hideLoader();
    }
  };
  const onChange = (e) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, e.target.value]);
    }
    if (e.target.checked === false) {
      setCheckedList(checkedList.filter((id) => id !== e.target.value));
    }
  };

  const handleDeleteSelected = async () => {
    if (checkedList.length === 0) {
      showNotificationWithMessage("Please select atleast one record", "error");
      return;
    }
    try {
      // Show the confirmation popup
      const confirmed = await Swal.fire({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this record?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (!confirmed) {
        return; // If user cancels, do nothing
      }

      showLoader();
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/deleteMultipleContactEnquiry`,
        checkedList,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("jwtoken")}`,
          },
          withCredentials: true, // Send cookies with the request
        }
      );

      if (response.data) {
        getEnquiries();
        setCheckedList([])
        // notification.warning({
        //   message: "Record is deleted",
        // });
        showNotificationWithMessage('Record is deleted', 'success');
      }
    } catch (error) {
      // notification.warning({
      //   message: "Something went wrong",
      // })
      showNotificationWithMessage('Something went wrong', 'error');
    }
    finally {
      hideLoader();
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedList(selectedRowKeys);
    },
  };

  useEffect(() => {
    getEnquiries();
  }, []);
  return (
    <>
      <div className="content-wrapper">
        <div className="container-full">
          {/* <!-- Main content --> */}
          <section className="content">
            <div className="row">
              <div className="col-lg-6">
              <h3 className="page-title pt-4">Survey Enquiry</h3>
              </div>
              <div className="col-12">
                <div
                  onClick={handleDeleteSelected}
                  className={styles.deleteButton}
                >
                  <i class="ti-trash" style={{ marginRight: "10px" }}></i>
                  Delete Selected
                </div>
                <div className="box" style={{ marginTop: 50 }}>
                  <div className="box-body">
                    <div className="table-responsive">
                      <Table
                        rowSelection={{ type: "checkbox", ...rowSelection }}
                        columns={columns}
                        dataSource={data}
                        pagination={{ pageSize: 7 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- /.content --> */}
        </div>
      </div>
    </>
  );
};

export default ServeyEnquires;
