import React, { useEffect } from 'react'

import Banner from '../../components/website/Banner'
import ServiceContent from '../../components/website/Servicedetail/ServiceContent'
import ServiceTab from '../../components/website/Servicedetail/ServiceTab'
import ServiceForm from '../../components/website/Servicedetail/ServiceForm'

import { useLocation, useParams } from 'react-router-dom'; // Assuming you're using React Router
import axios from 'axios'
import { useState } from 'react'

const Servicedetail = () => {

  const location = useLocation();
  const params = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    const section = document.getElementById('mySection');
    if (section) {
      section.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [location]);

  const [bannerImage, setBannerImage] = useState([]);

  const getServiceData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/web/GetServiceById/${params.id}`,
      );
      const data = await response.data;
      setData(data);
      console.log(data)
    } catch (error) {
      console.error("Error fetching Services:", error);
    }
  }


  const getBannerImage = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/Servicedetail`);

      const result = response.data[0];
      setBannerImage(result);
      // console.log(result);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBannerImage();
    if (params.id) {
      getServiceData();
    }
  }, [params.id]);

  return (
    <>
      <main className="page_content">
        <section id="mySection">
          <Banner heading={data.title} img={bannerImage.bannerURL} />
          <ServiceContent title={data.shortDescrioption} content={data.Details} DetailsImage
            ={data.DetailsImage
            } />
          {/* <ServiceTab /> */}
          {/* <ServiceForm /> */}
        </section>

      </main>
    </>
  )
}

export default Servicedetail