import React from 'react'
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

    const navigate = useNavigate();

    const handleBackHome = () => {
        navigate('/');
    };

    return (
        <>
            <main className="page_content">
                <section className='pt-5'>
                    <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, the page you visited does not exist."
                        extra={<Button type="primary" onClick={handleBackHome}>Back To Home</Button>}
                    />
                </section>
            </main>
        </>
    )
}

export default PageNotFound

// import React from 'react';
// import { Button, Result } from 'antd';
// import { useNavigate } from 'react-router-dom';

// const PageNotFound = () => {
//     const navigate = useNavigate();

//     const handleBackHome = () => {
//         navigate('/');
//     };

//     return (
//         <div style={{ background: 'black', minHeight: '100vh' }}>
//             <main className="page_content">
//                 <section className='pt-5'>
//                     <Result
//                         status="404"
//                         style={{ color: 'white' }}
//                         title={<span style={{ color: 'white' }}>404</span>}
//                         subTitle={<span style={{ color: 'white' }}>Sorry, the page you visited does not exist.</span>}
//                         extra={<Button type="primary" onClick={handleBackHome}>Back Home</Button>}
//                     />
//                 </section>
//             </main>
//         </div>
//     );
// };

// export default PageNotFound;
