import { notification } from 'antd';
import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom'
import DOMPurify from "isomorphic-dompurify";
// import Content from "./Content";
import Content from '../Coursedetail/Content';

import useNotification from '../../../useNotification'; // Import the custom hook




const Leftsidecontent = () => {
	const { showNotificationWithMessage } = useNotification();

  //get blog detail by title
  const [blogTitle, setBlogTitle] = useState("");
  const [blogAuthor, setBlogAuthor] = useState("");
  // const [shortInfo, setShortInfo] = useState("");
  const [blogThumbImageURL, setBlogThumbImageURL] = useState("");
  const [blogDetailImageURL, setBlogDetailImageURL] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [date, setDate] = useState("");

  const params = useParams();

  useEffect(() => {
    getBlogDetail();
  }, [params]);

  const getBlogDetail = async () => {
    try {
      // console.log(params);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/web/GetBlogById/${params.id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
 
      const result = response.data;
      setBlogTitle(result.blogTitle);
      setBlogAuthor(result.blogAuthor);
      // setShortInfo(result.shortInfo);
      setBlogThumbImageURL(result.blogThumbImageURL);
      setBlogDetailImageURL(result.blogDetailImageURL);
      setBlogContent(result.blogContent);
      setDate(result.date);

    } catch (error) {
      // notification.warning({
      //   message: "something went wrong",
      // });
			showNotificationWithMessage('Something went wrong', 'error');

    }
  };


  return (
    <>
      <div className="col-12 col-lg-8">
        <div className="details_image">
          <img src={blogDetailImageURL}  alt="Blog Image" className='blogimg' />
        </div>
        <div className="details_content">
          <ul className="post_meta unordered_list">
            <li>{blogAuthor}</li>
            <li>{date.split('T')[0]}</li>
          </ul>
          {/* <h3 className="details_item_title">
            {shortInfo}
          </h3> */}
          <p>
                <Content HTML={DOMPurify.sanitize(blogContent)} />
           {/* {blogContent} */}
          </p>
         

         
         
        </div>
      </div>
      
    </>
  )
}

export default Leftsidecontent