import React, { useState } from 'react'
import CoursesCardComponent from '../../Courses/CoursesCardComponent'
import { routes } from '../../../../utills/routes'
import { useEffect } from 'react'
import Localstorage from '../../../../utills/storage/Localstorage'

const Subscription = () => {
  const [myCourses, setMyCourses] = useState([])


  const fetchUserCourses = async () => {
    try {
      if (Localstorage.USER_ID.get()) {
        const response = await routes.APIS.getStudentSubScription(
          Localstorage.USER_ID.get()
        );

        const currentDate = new Date();
        const activeCourses = [];

        response.forEach((element) => {
          const startDate = new Date(element.startDate);
          const endDate = new Date(element.endDate);
          if (currentDate >= startDate && currentDate <= endDate && element.status == 'active') {
            activeCourses.push(element.course);
          }
        });
        setMyCourses(activeCourses);
      }
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    fetchUserCourses()
  }, [])

  if (myCourses.length > 0) {
    return (
      <>
        <section className="course_section margin_bottom">
          <div className="container">
            <div className="section_heading">
              <div className="row align-items-center">
                <div className="col col-lg-12 text-center">
                  <h2 className="heading_text mb-4 white_text" >
                    My<span className="heading_focus_text  white_text">Courses</span>
                  </h2>
                </div>

              </div>
            </div>

            <div className="row">


              {/* <div className="col-12 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
                    <div className="course_item">
                      <div className="item_image">
                        <ul className="badge_group unordered_list">
                          <li><a className="badge badge_warning" href="">New</a></li>
                        </ul>
                        <a className="image_wrap" href="">
                          <img src="../assets/images/course/1.jpg" alt=""/>
                        </a>
                      </div>
                      <div className="item_content">
                        <a className="course_instructor btn_unfill" href="">Learnovation</a>
                        <h3 className="item_title">
                          <a href="">
                          Learno Elementary
                          </a>
                        </h3>
                        <ul className="course_meta unordered_list">
                          <li><a href=""><i className="far fa-bars me-1"></i>&nbsp;16 hrs</a></li>
                          <li><a href=""><i className="fal fa-inr me-1"></i>15000</a></li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
              {
                myCourses.map((course) => {
                  // console.log(course)
                  return (
                    <CoursesCardComponent id={course?._id} category={course?.category} title={course?.Name} img={course?.image} duration={course?.batches} batches={course?.duration} url={course?.url} />
                  )
                })
              }
              {/* <CoursesCardComponent category={"Learnovation"} title={"Learno Elementary"} img={"../assets/images/course/1.jpg"} duration={"16 hrs"}/> 
                  <CoursesCardComponent category={"Learnovation"} title={"The Japanese way"} img={"../assets/images/course/2.jpg"} duration={"32 hrs"}/> 
                  <CoursesCardComponent category={"Learnovation"} title={"PAT"} img={"../assets/images/course/5.jpg"} duration={"32 hrs"}/> 
                  <CoursesCardComponent category={"Learnovation"} title={"Noiseless"} img={"../assets/images/course/6.jpg"} duration={"32 hrs"}/>  */}

              {/* <div className="col-12 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
                    <div className="course_item">
                      <div className="item_image">
                        <ul className="badge_group unordered_list">
                          <li><a className="badge badge_warning" href="">New</a></li>
                        </ul>
                        <a className="image_wrap" href="">
                          <img src="../assets/images/course/2.jpg" alt=""/>
                        </a>
                      </div>
                      <div className="item_content">
                        <a className="course_instructor btn_unfill" href="">Learnovation</a>
                        <h3 className="item_title">
                        
                          <a href="">
                          The Japanese way
                          </a>
                        </h3>
                      
                        <ul className="course_meta unordered_list">
                          <li><a href=""><i className="far fa-bars me-1"></i>&nbsp;32 hrs.</a></li>
                          <li><a href=""><i className="fal fa-inr me-1"></i>15000</a></li>
                        </ul>
                      </div>
                    </div>
                  </div> */}

              {/* <div className="col-12 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
                    <div className="course_item">
                      <div className="item_image">
                        <ul className="badge_group unordered_list">
                          <li><a className="badge badge_primary" href="">New</a></li>
                        </ul>
                        <a className="image_wrap" href="">
                          <img src="../assets/images/course/5.jpg" alt=""/>
                        </a>
                      </div>
                      <div className="item_content">
                        <a className="course_instructor btn_unfill" href="">Learnovation</a>
                        <h3 className="item_title">
                          <a href="">
                           PAT

                          </a>
                        </h3>
                        <ul className="course_meta unordered_list">
                        <li><a href=""><i className="far fa-bars me-1"></i>&nbsp;32 hrs.</a></li>
                          <li><a href=""><i className="fal fa-inr me-1"></i>15000</a></li>
                        </ul>
                      </div>
                    </div>
                  </div> */}

              {/* <div className="col-12 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
                    <div className="course_item">
                      <div className="item_image">
                        <ul className="badge_group unordered_list">
                          <li><a className="badge badge_danger" href="">New</a></li>
                        </ul>
                        <a className="image_wrap" href="">
                          <img src="../assets/images/course/6.jpg" alt=""/>
                        </a>
                      </div>
                      <div className="item_content">
                        <a className="course_instructor btn_unfill" href="">Learnovation</a>
                        <h3 className="item_title">
                          <a href="">
                          Noiseless
                          </a>
                        </h3>
                        <ul className="course_meta unordered_list">
                        <li><a href=""><i className="far fa-bars me-1"></i>&nbsp;32 hrs.</a></li>
                          <li><a href=""><i className="fal fa-inr me-1"></i>15000</a></li>
                        </ul>
                      </div>
                    </div>
                  </div> */}

            </div>
          </div>
        </section>

      </>
    )
  }
  else {
    return <section className="course_section margin_bottom">
      <div className="container">
        <div className="section_heading">
          <div className="row align-items-center">
            <div className="col-12 col-lg-3 col-md-12 col-sm-12 text-center">
              <h2 className="heading_text mb-4 white_text">
                My<span className="heading_focus_text  white_text">Courses</span>
              </h2>

            </div>


          </div>
          <div className="row justify-content-center">
            <div className='col-lg-12 text-center'>
              <h3 className='golden_text' style={{ paddingTop: '20px' }}>No Courses Found</h3>
            </div>

          </div>
        </div>

      </div>
    </section>

  }
}

export default Subscription