import { apiGet, apiPost, apiPut } from "./Api";
import Localstorage from "./storage/Localstorage";

const baseURL = `${process.env.REACT_APP_BACKEND_URL}`

export const studentLoginURL  = baseURL+"/studentlogin";

export const getStudentInfo=(id) =>  baseURL+`/getstudentinfo/${id}`;
export const editStudentProfile = baseURL+`/editstudentprofile/${localStorage.getItem('userId')}`;


export const routes = {
    BASE_PATH: `${process.env.REACT_APP_BACKEND_URL}`,
    APIS:{
        studentLogin:async(data)=>{
            return await apiPost("/studentlogin",routes.BASE_PATH,data)
        },
        getStudentInfo:async(id)=>{
            return await apiGet(`/getstudentinfo/${id}`,routes.BASE_PATH)
        },
        editStudentProfile:async(data)=>{
            return await apiPost(`/editstudentprofile/${Localstorage.USER_ID.get()}`,routes.BASE_PATH,data)
        },
        getStudentMedia:async()=>{
            return await apiGet(`/getStudentMedia/${Localstorage.USER_ID.get()}`,routes.BASE_PATH)
        },
        searchCourse:async(data)=>{
            console.log(data)
            return await apiGet(`/web/SearchCoursesWebsite/search?search=${data}`,routes.BASE_PATH)
        },
        getCoursesByCategory:async(category)=>{
            return apiGet(`/web/GetCoursesWebsite/${category}`,routes.BASE_PATH)
        },
        getStudentCourses:async()=>{
            return apiGet(`/web/getStudentSubscription/${Localstorage.USER_ID.get()}`,routes.BASE_PATH)
        },
        getMediaByCourseId:async(id)=>{
            return apiGet(`/getMediaByCourseId/${id}/${Localstorage.USER_ID.get()}`,routes.BASE_PATH)
        }, 
        editStudentProfile:async(data)=>{
            return await apiPut(`/editstudentprofile/${Localstorage.USER_ID.get()}`,routes.BASE_PATH,data)
        },
        makePayemnt:async(data)=>{
            return await apiPost('/initiate-payment',routes.BASE_PATH,data)
        },
        getCourse:async(filters)=>{
            return await apiGet(`/courses/${filters}`,routes.BASE_PATH)
        },
        getStudentSubScription:async(userId)=>{
            return await apiGet(`/getStudentSubscription/${userId}`,routes.BASE_PATH)
        },
        createCheckoutSession:async(data)=>{
            return await apiPost('/initiate_payment',routes.BASE_PATH,data)
        },
        searchMedia:async(data)=>{
            return await apiGet(`/searchMedia?userId=${Localstorage.USER_ID.get()}&searchTerm=${data}`,routes.BASE_PATH)
        },
        getCategories:async(data)=>{
            return await apiGet(`/web/GetCategoriesWebsite`,routes.BASE_PATH)
        },
        getCoursesByCategories:async(data)=>{
            return await apiGet(`/web/GetCoursesWebsite/${data}`,routes.BASE_PATH)
        },
        getCourses:async(querry)=>{
            return await apiGet(`/web/courses?${querry}`,routes.BASE_PATH)
        },
        getAllRoutes:async()=>{
            return await apiGet(`/web/getAllRoutes`,routes.BASE_PATH)
        }
    }
}

