// import { useForm } from "react-hook-form";
import React, { useState, useRef, useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import Cookies from "js-cookie";
import ImageCroper from "../../ImageCroper/ImageCroper";
import { ErrorMessage, Formik } from "formik";
// import "jodit/build/jodit.min.css";
// import { useForm } from "react-hook-form";
// import parse from "html-react-parser";

import useNotification from '../../../useNotification'; // Import the custom hook
import { useGlobalLoader } from "../../../Loader/GlobalLoaderContext";

const AddCourse = () => {

  const { showLoader, hideLoader } = useGlobalLoader();
  const { showNotificationWithMessage } = useNotification();

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [support, setSupport] = useState("");
  const [category, setCategory] = useState("");
  const [sub_category, setSubCategory] = useState("");
  const [short_desc, setShortDesc] = useState("");
  const [duration, setDuration] = useState("");
  const [batches, setBatches] = useState("");
  const [price, setPrice] = useState("");
  const [discount_price, setDiscountPrice] = useState("");
  const [tag, setTag] = useState("");
  // const [date, setDate] = useState('');
  const [meta_title, setMetaTitle] = useState("");
  const [meta_keyword, setMetaKeyword] = useState("");
  const [meta_desc, setMetaDesc] = useState("");
  const [image, setImage] = useState(null);
  const [posterImage, setPosterImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [cropingImage, setCropingImage] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(1.9);
  const [cropPosterImage, setPosterImageToCrop] = useState(false);

  const [error, setError] = useState();
  const navigate = useNavigate();

  const imageHandler = (image, posterImage) => {
    setCropingImage(image);
    setPosterImageToCrop(posterImage);
    setShowModal(true);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (
  //     !title ||
  //     !support ||
  //     !category ||
  //     !sub_category ||
  //     !short_desc ||
  //     !duration ||
  //     !batches ||
  //     !price ||
  //     !discount_price ||
  //     !tag ||
  //     !meta_title ||
  //     !meta_keyword ||
  //     !meta_desc ||
  //     !image ||
  //     !posterImage
  //   ) {
  //     setError(true);
  //     return false;
  //   }

  //   // const userId = JSON.parse(localStorage.getItem("admin"))._id;
  //   // console.warn(userId);

  //   const formData = new FormData();

  //   formData.append("title", title.toLowerCase());
  //   formData.append("support", support);
  //   formData.append("category", category);
  //   formData.append("sub_category", sub_category);
  //   formData.append("short_desc", short_desc);
  //   formData.append("duration", duration);
  //   formData.append("batches", batches);
  //   formData.append("price", price);
  //   formData.append("discount_price", discount_price);
  //   formData.append("tag", tag);
  //   formData.append("meta_title", meta_title);
  //   formData.append("meta_keyword", meta_keyword);
  //   formData.append("meta_desc", meta_desc);
  //   formData.append("course_content", content);
  //   // formData.append("image", image);
  //   formData.append("image", image);
  //   formData.append("posterImage", posterImage);

  //   try {
  //     const response = axios.post(
  //       "http://localhost:5000/admin/AddCourses",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${Cookies.get("jwtoken")}`,
  //         },
  //         withCredentials: true, // Send cookies with the request
  //       }
  //     );
  //     console.warn(response.data);
  //     alert("Successfully added");
  //     navigate("/CourseList");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const buttons = ["image"];
  const editorConfig = useMemo(() => {
    return {
      readonly: false,
      placeholder: "Start typings...",
      responsive: true,
    };
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div
          className="container-full"

          style={{ marginTop: 10}}

        >
          <section className="content">
            <div className="row">
 
                      <div className="col-lg-12 mb-3">
                      <h5 className="box-title">Add Course</h5>
                      </div>
              {/* <form onSubmit={handleSubmit}> */}

              <Formik
                initialValues={{
                  title: "",
                  support: "",
                  category: "",
                  sub_category: "",
                  short_desc: "",
                  duration: "",
                  batches: "",
                  price: "",
                  discount_price: "",
                  tag: "",
                  meta_title: "",
                  meta_keyword: "",
                  meta_desc: "",
                  course_content: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.title) {
                    errors.title = "Please enter a title";
                  }

                  if (!values.support) {
                    errors.support = "Please enter a support";
                  }
                  if (!values.category) {
                    errors.category = "Please enter a category";
                  }
                  if (!values.sub_category) {
                    errors.sub_category = "Please enter a sub category";
                  }
                  // if (!values.short_desc) {
                  //   errors.short_desc = "Please enter a short description";
                  // }
                  if (!values.duration) {
                    errors.duration = "Please enter a duration";
                  }
                  if (!values.batches) {
                    errors.batches = "Please enter a batches";
                  }

                  // if (!values.price) {
                  //   errors.price = "Please enter a price";
                  // }
                  // if (!values.discount_price) {
                  //   errors.discount_price = "Please enter a discount price";
                  // }
                  // if (!values.short_desc) {
                  //   errors.short_desc = "Please enter a short description";
                  // }
                  // if (!values.tag) {
                  //   errors.tag = "Please enter a tag";
                  // }
                  // if (!values.meta_title) {
                  //   errors.meta_title = "Please enter a meta title";
                  // }
                  // if (!values.meta_keyword) {
                  //   errors.meta_keyword = "Please enter a meta keyword";
                  // }
                  // if (!values.meta_desc) {
                  //   errors.meta_desc = "Please enter a meta description";
                  // }
                  // if (!content) {
                  //   errors.course_content = "Please enter a course content";
                  // }
                  // if (!content) {
                  //   errors.course_content = "Please enter a course content";
                  // }

                  if (image == "" || image == null || image == undefined) {
                    errors.image = "Please add a image";
                  }
                  if (
                    posterImage == "" ||
                    posterImage == null ||
                    posterImage == undefined
                  ) {
                    errors.posterImage = "Please enter a poster image";
                  }

                  console.log(errors);
                  return errors;
                }}
                onSubmit={async (values) => {
                  // console.log(values)
                  const formData = new FormData();

                  formData.append("title", values.title.toLowerCase());
                  formData.append("support", values.support);
                  formData.append("category", values.category);
                  formData.append("sub_category", values.sub_category);
                  formData.append("short_desc", values.short_desc);
                  formData.append("duration", values.duration);
                  formData.append("batches", values.batches);
                  formData.append("price", values.price);
                  formData.append("discount_price", values.discount_price);
                  formData.append("tag", values.tag);
                  formData.append("meta_title", values.meta_title);
                  formData.append("meta_keyword", values.meta_keyword);
                  formData.append("meta_desc", values.meta_desc);
                  formData.append("course_content", content);
                  // formData.append("image", image);
                  formData.append("image", image);
                  formData.append("posterImage", posterImage);

                  try {

                    showLoader();

                    const response = await axios.post(
                      `${process.env.REACT_APP_BACKEND_URL}/admin/AddCourses`,
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                          Authorization: `Bearer ${Cookies.get("jwtoken")}`,
                        },
                        withCredentials: true, // Send cookies with the request
                      }
                    );
                    console.warn(response.data);
                    // alert("Successfully added");
                    // navigate("/CourseList");
                    showNotificationWithMessage('Course added successfully', 'success');
                    setTimeout(() => {
                      navigate('/CourseList');
                    }, 1000);
                  } catch (error) {
                    console.error(error);
                    showNotificationWithMessage('Something went wrong', 'error');

                  }
                  finally {
                    hideLoader(); // Hide the global loader
                  }
                }}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                }) => (
                  <div className="col-lg-12 col-12 mx-auto addcoursemargin">
                    {/* <!-- box1 --> */}

                    <div className="box">
                      
                      <div className="box-body pt-4">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Title</label>
                              <input
                                type="text"
                                name="title"
                                className="form-control"
                                placeholder="Title"
                                value={values.title}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="title"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label>Category</label>
                              <input
                                type="text"
                                name="category"
                                className="form-control"
                                placeholder="Category"
                                value={values.category}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="category"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label>Sub Category</label>
                              <input
                                type="text"
                                name="sub_category"
                                className="form-control"
                                placeholder="Sub Category"
                                value={values.sub_category}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="sub_category"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="form-group">
                              <label>Short Description</label>
                              <textarea
                                rows="2"
                                name="short_desc"
                                className="form-control"
                                placeholder="Short Description"
                                value={values.short_desc}
                                onChange={handleChange}
                              ></textarea>
                              {error && !short_desc && (
                                <span className="invalid-input">
                                  Enter short Description
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 mt-2">
                            <div className="form-group">
                              <label>Duration</label>
                              <input
                                type="text"
                                name="duration"
                                className="form-control"
                                placeholder="Duration"
                                value={values.duration}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="duration"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="col-md-4 mt-2">
                            <div className="form-group">
                              <label>length</label>
                              <input
                                type="text"
                                name="batches"
                                className="form-control"
                                placeholder="length"
                                value={values.batches}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="batches"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-4 mt-2">
                            <div className="form-group">
                              <label>support</label>
                              <input
                                type="text"
                                name="support"
                                className="form-control"
                                placeholder="Support"
                                value={values.support}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="support"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- box1 -->


			 <!-- box2 --> */}

                    <div className="box">
                      <div className="box-body">
                        <div className="row">
                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label> Price</label>
                              <input
                                type="text"
                                name="price"
                                className="form-control"
                                placeholder="Price"
                                value={values.price}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="price"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label>Discount Price</label>
                              <input
                                type="text"
                                name="discount_price"
                                className="form-control"
                                placeholder="Discount Price"
                                value={values.discount_price}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="discount_price"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label>Tags</label>
                              <input
                                type="text"
                                name="tag"
                                className="form-control"
                                placeholder="Tags"
                                value={values.tag}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="tag"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label>Thumb Image</label>
                              <input
                                type="file"
                                name="image"
                                className="form-control"
                                accept="image/*"
                                onChange={(e) => {
                                  // imageHandler(e, false);
                                  setImage(e.target.files[0]);
                                }}
                              />
                              {errors.image && (
                                <span className="text-danger">
                                  {errors.image}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label>Poster Image</label>
                              <input
                                type="file"
                                name="posterImage"
                                className="form-control"
                                accept="image/*"
                                onChange={(e) => {
                                  setPosterImage(e.target.files[0]);
                                  // imageHandler(e, true);
                                }}
                              />
                              {errors.posterImage && (
                                <span className="text-danger">
                                  {errors.posterImage}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* box3 */}

                    <div className="box">
                      <div className="box-body">
                        <div className="row">
                          <div className="col-md-12 mt-2">
                            <div className="form-group">
                              <label>Description</label>
                              <JoditEditor
                                ref={editor}
                                value={content}
                                config={editorConfig}
                                onChange={(newContent) =>
                                  setContent(newContent)
                                }
                              />
                              {/* {error && !duration && <span className='invalid-input'> Enter valid duration</span>} */}
                              <ErrorMessage
                                className="text-danger"
                                name="course_content"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* box3 */}

                    <div className="box">
                      <div className="box-body">
                        <div className="row">
                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label>Meta Title</label>
                              <input
                                type="text"
                                name="meta_title"
                                className="form-control"
                                placeholder="Meta Title"
                                value={values.meta_title}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="meta_title"
                                component="div"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 mt-2">
                            <div className="form-group">
                              <label>Meta Keywords</label>
                              <input
                                type="text"
                                name="meta_keyword"
                                className="form-control"
                                placeholder="Keywords"
                                value={values.meta_keyword}
                                onChange={handleChange}
                              />
                              <ErrorMessage
                                className="text-danger"
                                name="meta_keyword"
                                component="div"
                              />
                            </div>
                          </div>

                          <div className="col-md-12 mt-2">
                            <div className="form-group">
                              <label>Meta Description</label>
                              <textarea
                                rows="2"
                                name="meta_desc"
                                className="form-control"
                                placeholder="Meta Description"
                                value={values.meta_desc}
                                onChange={handleChange}
                              ></textarea>
                              <ErrorMessage
                                className="text-danger"
                                name="meta_desc"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- box4 --> */}

                    {/* <!-- action --> */}
                    <div className="box-footer mb-4">
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        // onSubmit={handleSubmit}
                        className="btn btn-rounded btn_dark btn-outline btncourse"
                        style={{ padding: 10 }}
                      >
                        <i className="ti-save-alt"></i> Save
                      </button>
                    </div>

                    {/* <!-- action --> */}
                  </div>
                )}
              </Formik>
              {/* </form> */}
            </div>
          </section>

          {/* <!-- form --> */}

          {/* 		
		<!-- /.content --> */}
        </div>

        {/* <ImageCroper
          aspectRatio={aspectRatio}
          imagetoCrop={cropingImage}
          showModal={showModal}
          setImage={cropPosterImage ? setPosterImage : setImage}
          setShowModal={setShowModal}
        /> */}
      </div>
      {/* <!-- /.content-wrapper --> */}
    </>
  );
};

export default AddCourse;
