import React, { useState } from 'react';
import axios from 'axios';
import { routes } from '../../../utills/routes';

function PaymentComponent() {
    const [loading, setLoading] = useState(false);

    // const handlePayment = async () => {
    //     setLoading(true);
    //     try {
    //         const response = await axios.post('http://localhost:5000/create-checkout-session');
    //         const { actionUrl, encRequest, accessCode } = response.data;

    //         // Create a form dynamically to submit parameters to CCAvenue
    //         const form = document.createElement('form');
    //         form.action = actionUrl;
    //         form.method = 'post';

    //         // Add the encrypted request parameter
    //         const encRequestInput = document.createElement('input');
    //         encRequestInput.type = 'hidden';
    //         encRequestInput.name = 'encRequest';
    //         encRequestInput.value = encRequest;
    //         form.appendChild(encRequestInput);

    //         // Add the access code parameter
    //         const accessCodeInput = document.createElement('input');
    //         accessCodeInput.type = 'hidden';
    //         accessCodeInput.name = 'access_code';
    //         accessCodeInput.value = accessCode;
    //         form.appendChild(accessCodeInput);

    //         // Add the form to the body and submit it
    //         document.body.appendChild(form);
    //         form.submit();

    //     } catch (error) {
    //         console.error("Error initiating payment:", error);
    //         setLoading(false);
    //     }
    // };

	const handlepayment = async() =>{
		// axios.post(`http://localhost:5000/create-checkout-session`,{
		// 	userId:"Om123"
		// }).then((res)=>{
		// 	if(res.data.url){
		// 		window.location.href = res.data.url
		// 	}
		// })
        let res =await routes.APIS.createCheckoutSession()
        // console.log(res)
	}

    return (
        <div>
            {/* Render your product details and other UI components */}
            <button style={{color:'white'}} onClick={handlepayment} disabled={loading}>
                {loading ? "Processing..." : "Pay with CCAvenue"}
            </button>
        </div>
    );
}

export default PaymentComponent;
