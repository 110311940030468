import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'; // Assuming you're using React Router
import MediaBanner from './Media/MediaBanner';
import Banner from '../Banner';
import axios from 'axios';
import { useState } from 'react';
import Subscription from './Dashboard/Subscription';


const MyCourses = () => {

  const location = useLocation();


  useEffect(() => {
    const section = document.getElementById('mySection');
    if (section) {
      section.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [location]);

  const [bannerImage, setBannerImage] = useState([]);
  useEffect(() => {
    getBannerImage();
  }, []);

  const getBannerImage = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/web/GetBannerWebsite/MyCourses`);

      const result = response.data[0];
      setBannerImage(result);
      // console.log(result);

    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <main className="page_content">
        <section id="mySection">
          <Banner heading='My Courses' img={bannerImage.bannerURL} />
          <div style={{ margin: '40px' }}>
            <Subscription />
          </div>

        </section>

      </main>
    </>
  )

}

export default MyCourses