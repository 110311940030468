import React from "react";
import ReactDOM from 'react-dom'
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import AddMedia from "../Media/AddMediaImage";
import useScript from "../../hookes/useScripts";
import UIManagement from "./UIManagement";
import EnquiryManagement from "./EnquiryManagement";
import MediaManagement from "./MediaManagement";



const Sidebar = () => {
  // const { loaded, error } = useScript('../../admin-assests/js/template.js');

  // if (!loaded) return null;
  // if (error) return <p>Error loading script</p>;

  return (
    <>
      <aside className="main-sidebar">
        {/* <!-- sidebar--> */}
        <section className="sidebar position-relative">
          <div className="multinav">
            <div className="multinav-scroll ps ps--active-y" style={{ "height": "100%" }}>
              {/* <!-- sidebar menu--> */}
              <ul className="sidebar-menu" data-widget="tree">
                <li className="header">Dashboard & Apps</li>
                <li>
                  <NavLink to="/DashboardHome">
                    <i class="fa fa-home" aria-hidden="true"></i>
                    {/* <i className="icon-Layout-4-blocks">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i> */}
                    <span>Dashboard</span>
                  </NavLink>
                </li>



                <li className="header">Modules</li>
                <li>
                  <NavLink to="/CourseList">
                    {/* <i className="icon-Write">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i> */}
                    <i class="fa fa-book" aria-hidden="true"></i>
                    <span>Courses</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/Students">
                    {/* <i className="icon-File">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i> */}
                    <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                    <span>Students</span>
                  </NavLink>
                </li>


                <li className="header">Media Management</li>
                {/* <li className="treeview">
                  <NavLink href="#">
                    <i class="fa fa-picture-o" aria-hidden="true"></i>
                    <span>Media</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-right pull-right"></i>
                    </span>
                  </NavLink>
                  <ul className="treeview-menu">
                    <li><NavLink to="/MediaImage"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Images</NavLink></li>
                    <li><NavLink to="/MediaVideo"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Video</NavLink></li>
                    <li><NavLink to="/MediaDocs"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Document</NavLink></li>
                  </ul>
                </li> */}


                {/* <li className="treeview">
                  <div className="d-flex flex-row">
   */}
  <MediaManagement />
{/*  
</div>
                 
                </li> */}
               
               

                {/* enquiry */}
                <li className="header">Enquiry Management</li>
                {/* <li className="treeview">
                  <NavLink href="#">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                    <span>Enquiries</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-right pull-right"></i>
                    </span>
                  </NavLink>
                  <ul className="treeview-menu">
                    <li><NavLink to="/Enquiries"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Course Enquiry</NavLink></li>
                    <li><NavLink to="/ContactEnquires"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Contact Enquiry</NavLink></li>
                    <li><NavLink to="/serveyEnquires"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Survey Enquiry</NavLink></li>
                  </ul>
                </li> */}

{/* <li className="treeview">
                  <div className="d-flex flex-row">
  <div className="pt-3 treehover"><i className="fa fa-envelope-o icolor" aria-hidden="true"></i></div>
  <div className="pt-1 treehover"> <EnquiryManagement /></div>
 
</div>
                 
                </li> */}
                <EnquiryManagement />

               



                <li className="header">UI Management</li>
                {/* <li className="treeview">
                  <div className="d-flex flex-row">
  <div className="pt-3 treehover"><i className="fa fa-globe icolor" aria-hidden="true"></i></div>
  <div className="pt-1 treehover"> <UIManagement /></div>
 
</div>
                 
                </li> */}
                {/* <li className="treeview">
                  <NavLink href="#">
                    <i class="fa fa-globe" aria-hidden="true"></i>
                    <span>UI Management</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-right pull-right"></i>
                    </span>
                  </NavLink>
                  <ul className="treeview-menu">
                    <li><NavLink to="/ViewBanner"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Banner</NavLink></li>
                    <li><NavLink to="/ViewBlogList"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Blog</NavLink></li>
                    <li><NavLink to="/ViewTestimonial"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Testimonial</NavLink></li>
                    <li><NavLink to="/ViewServicesList"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Services</NavLink></li>

                    <li><NavLink to="/ViewServiceImg"><i className="icon-Commit"><span className="path1"></span><span className="path2"></span></i>Service Image</NavLink></li>
                  </ul>
                </li> */}


<UIManagement />

              </ul>
            </div>
          </div>
        </section>
      </aside>
    </>
  );
};

export default Sidebar;
